import React, {useMemo} from 'react';
import './severity-rule.css'

export default function SeverityRule({rule}) {
    const pulseTxt = useMemo(() => {
        switch (rule.covers_pulse) {
            case 1:
                return 'first'
            case null:
                return 'any'
            default:
                return 'subsequent'
        }
    }, [rule.covers_pulse])

    return (<div className='lightning-severity-rule'>
        <div className='from-to-wrapper'>
            <label className='from'>{rule.peak_current_from}</label>
            <p>-</p>
            <label className='to'>{rule.peak_current_to > 0 ? '+' : ''}{rule.peak_current_to}</label>
        </div>
        <label>Pulse:</label>
        <label> {pulseTxt}</label>
    </div>)
}
