import React, {useCallback, useMemo, useState} from "react";
import './wpl-input.css'
import clear from '../../assets/img/clear-24px.svg'

export default function WplInput(
    {
        title,
        type,
        options,
        placeholder,
        onChanged,
        value,
        style,
        className,
        onlySelectableOptions,
        multiSelect,
        error,
        disabled,
        clearable,
        openUp=false,
        inputRef,
        ...rest
    }) {
    const [focused, setFocused] = useState(false);
    const [searching, setSearching] = useState('')

    const proxyOnChanged = useCallback((newValue, clicked) => {
        if (multiSelect) {
            if (!clicked) return;
            if (value.indexOf(newValue) === -1) {
                onChanged([...value, newValue])
            } else {
                onChanged(value.filter(v => v !== newValue))
            }
        } else {
            if (onlySelectableOptions) {
                if (clicked) {
                    onChanged(newValue)
                    setSearching('')
                } else {
                    setSearching(newValue)
                    onChanged('')
                }
            } else {
                onChanged(newValue)
            }
        }
    }, [onChanged, onlySelectableOptions, multiSelect, value]);

    const filteredOptions = useMemo(() => {
        if (!options) return []
        return options
            .filter(o => o !== null)
            .filter((o, idx) => o === '' || options.indexOf(o) === idx)
            .filter(o => !onlySelectableOptions || o.toLowerCase().indexOf(searching.toLowerCase()) >= 0)
    }, [options, onlySelectableOptions, searching]);


    const displayValue = useMemo(() => {
        if (type === 'checkbox') {
            return ''
        }
        if (onlySelectableOptions) {
            if (!searching && value) {
                return value
            }
            if (value !== searching) {
                return searching
            }
            return value
        }
        return value
    }, [type, onlySelectableOptions, value, searching]);

    return <div className={`wpl-input ${className ? className : ''} ${type}`}
                onFocus={_ => setFocused(true)}
                onBlur={_ => setFocused(false)}
                style={style}>
        <div className='input-wrapper'>
            <label className='title-label'>{title}{error && <label className='error'> - {error}</label>}</label>
            <div className='input-clear-icon-wrapper'>
                <input type={type}
                       className={(onlySelectableOptions || multiSelect) ? 'non-selectable' : ''}
                       value={displayValue}
                       checked={type === 'checkbox' ? value : null}
                       placeholder={placeholder}
                       disabled={disabled}
                       ref={inputRef}
                       onKeyDown={e => {
                           if (e.key === 'Enter') {
                               if (onlySelectableOptions && filteredOptions.length === 1) {
                                   proxyOnChanged(filteredOptions[0], true)
                               }
                           }
                       }}
                       onChange={e => {
                           proxyOnChanged((type === 'checkbox' ? e.target.checked : e.target.value), false)
                       }}
                       {...rest}
                />
                {clearable &&
                    <img onClick={_ => proxyOnChanged('', true)} className='clear-icon' src={clear} alt='clear'/>}
            </div>

        </div>
        {options && focused && <div className={`option-wrapper ${openUp ? 'open-up' : ''}`}>
            {filteredOptions
                .map(o => <li key={o} onMouseDown={e => proxyOnChanged(o, true)} className='drop-option'>{o}</li>)}
        </div>}
        {multiSelect && value && <div className={`selected-wrapper`}>
            {value
                .filter((o, idx) => value.indexOf(o) === idx)
                .map(o => <span className='selected-item' key={o} onClick={() => proxyOnChanged(o, true)}>{o}</span>)}
        </div>}
    </div>
}
