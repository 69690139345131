import {http} from "../api";

const easyUnpack = ({data}) => data;
export const UserApi = {
    authenticate: {
        f: function authenticate(username, password) {
            let url = '/user/authenticate';
            return http.post(url, {username, password}).then(easyUnpack)
        }
    },
    create_invitation: {
        f: function create_invitation(user_name, email, platform, windfarm_ids, lassie_access) {
            let url = '/user/admin/create-invitation';
            return http.post(url, {user_name, email, platform, windfarm_ids, lassie_access}).then(easyUnpack)
        }
    },
    accept_invitation: {
        f: function accept_invitation(token, password) {
            let url = '/user/accept-invitation';
            return http.post(url, {token, password}).then(easyUnpack)
        }
    },
    check_invitation_token_validity: {
        f: function check_invitation_token_validity(token) {
            let url = '/user/check-invitation-token';
            return http.post(url, {token}).then(easyUnpack)
        }
    },
    accept_pw_reset: {
        f: function accept_pw_reset(token, password) {
            let url = '/user/reset-pw';
            return http.post(url, {token, password}).then(easyUnpack)
        }
    },
    create_request_pw_reset: {
        f: function create_request_pw_reset(reset_pw_user_id) {
            let url = '/user/request-pw-reset';
            return http.post(url, {reset_pw_user_id}).then(easyUnpack)
        }
    },
    list_users: {
        f: function list_users({with_windfarms = null}) {
            let url = '/user/list';
            return http.get(url, {params: {with_windfarms}}).then(easyUnpack)
        }
    },
    list_invitations: {
        f: function list_invitations() {
            let url = '/user/invitations';
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    add_windfarm_to_user: {
        f: function add_windfarm_to_user(target_user_id, windfarm_id) {
            let url = '/user/$target_user_id/windfarms/$windfarm_id';
            let parameterNames = ["$target_user_id", "$windfarm_id"];
            [target_user_id, windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.put(url, {}).then(easyUnpack)
        }
    },
    remove_windfarm_from_user: {
        f: function remove_windfarm_from_user(target_user_id, windfarm_id) {
            let url = '/user/$target_user_id/windfarms/$windfarm_id';
            let parameterNames = ["$target_user_id", "$windfarm_id"];
            [target_user_id, windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.delete(url, {data: {}}).then(easyUnpack)
        }
    },
    list_user_windfarms: {
        f: function list_user_windfarms(target_user_id) {
            let url = '/user/$target_user_id/windfarms';
            let parameterNames = ["$target_user_id"];
            [target_user_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    update_user_scope: {
        f: function update_user_scope(target_user_id, {scope = null}) {
            let url = '/user/$target_user_id/scope';
            let parameterNames = ["$target_user_id"];
            [target_user_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.patch(url, {scope}).then(easyUnpack)
        }
    },
};
