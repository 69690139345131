import React, {useState, useEffect, useMemo} from 'react'
import PieHooks from "./PieHooks";
import SeverityList from "../severity-list/SeverityList";
import './defect-pie.css'
import ShowLessMore from "../show-lessmore/ShowLessMore";
import Card from "../card/Card";
import DefectListPopup from "../defect-list-popup/DefectListPopup";

export default function ({title, defects}) {
    const [showMore, setShowMore] = useState(false);
    const [severityCounts, setSeverityCounts] = useState({});
    useEffect(() => {
        const scs = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0};

        defects.forEach(d => {
            scs[d.severity] += 1
        });
        setSeverityCounts(scs)
    }, [defects])

    const data = useMemo(() => Object.keys(severityCounts).map(d => {
        return {
            text: d,
            value: severityCounts[d]
        }
    }), [severityCounts]);

    return (<Card className='pie-chart'>
        <DefectListPopup isOpen={showMore} onClose={() => setShowMore(false)} title={(<div className='title-group'>
            <div>
                <h2>Defects marked as: {title}</h2>
            </div>
        </div>)} defects={defects} />

        <h1>{title}</h1>
        <p>{`${defects.length} defects ${title.toLowerCase()}`}</p>
        <PieHooks data={data} innerRadius={40} outerRadius={60} colors={[
            '#7cbb42',
            '#ffcc07',
            '#f88f1f',
            '#e22e0b',
            '#ae0006'
        ]} />
        <SeverityList small={true} severityCounts={severityCounts} defects={defects} subDescription={title} />
        <ShowLessMore showMore={showMore} setShowMore={setShowMore} />
    </Card>)
}
