import React, {useEffect, useState} from 'react';
import {http} from "../../../api";
import './site-map.css';

export default function Sitemap() {
    const [siteMap, setSiteMap] = useState(null);

    useEffect(() => {
        // fetch site map
        http.get('/site-map')
            .then(response => {
                setSiteMap(response.data);
            })
    }, []);

    function recursiveRenderDict(dict) {
        return Object.entries(dict).map(([key, value]) => {
            if (typeof value === 'object') {
                return <div className='recur'>
                    <h3>{key}</h3>
                    {recursiveRenderDict(value)}
                </div>
            }

            return <p>{key}: {value}</p>
        })
    }

    if (!siteMap) {
        return <div>Loading...</div>
    }

    return (
        <div className='site-map'>
            <h1>Site Map</h1>
            {recursiveRenderDict(siteMap.complete)}
            <pre>{JSON.stringify(siteMap, null, 2)}</pre>
        </div>
    )
}
