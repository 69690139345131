import {http} from "../api";

const easyUnpack = ({data}) => data;
export const LassieRegionApi = {
    create_region: {
        f: function create_region(windfarm_id, name, data_source, points) {
            let url = '/lassie/region/windfarm/$windfarm_id';
            let parameterNames = ["$windfarm_id"];
            [windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {name, data_source, points}).then(easyUnpack)
        }
    },
    list_regions: {
        f: function list_regions({region_ids = null, limit_history_days = null}) {
            let url = '/lassie/region';
            return http.get(url, {params: {region_ids, limit_history_days}}).then(easyUnpack)
        }
    },
    list_regional_queries: {
        f: function list_regional_queries(region_id) {
            let url = '/lassie/region/$region_id/queries';
            let parameterNames = ["$region_id"];
            [region_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    list_regional_turbines: {
        f: function list_regional_turbines(region_id) {
            let url = '/lassie/region/$region_id/turbines';
            let parameterNames = ["$region_id"];
            [region_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    get_region_by_windfarm_id: {
        f: function get_region_by_windfarm_id(windfarm_id) {
            let url = '/lassie/region/windfarm/$windfarm_id';
            let parameterNames = ["$windfarm_id"];
            [windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
};
