import {http} from "../api";

const easyUnpack = ({data}) => data;
export const LightningReportApi = {
    historic_trigger: {
        f: function historic_trigger(windfarm_id, from_timestamp, to_timestamp) {
            let url = '/lightning-report/historic-triggers';
            return http.get(url, {params: {windfarm_id, from_timestamp, to_timestamp}}).then(easyUnpack)
        },
    },
};
