import React from 'react'
import './wpl-button.css'

export default function WplButton({
                                      className,
                                      value,
                                      onClick,
                                      hidden = false,
                                      disabled = false,
                                      loading = false,
                                      big = false,
                                      red = false,
                                  }) {
    return <input
        hidden={hidden}
        type='button'
        className={`wpl-button 
        ${className} 
        ${disabled ? 'disabled' : ''} 
        ${loading && 'loading'}  
        ${big ? 'big' : ''} 
        ${red ? 'red' : ''}`}
        disabled={disabled}
        value={value}
        onClick={loading ? () => {
        } : onClick}/>
}
