import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {lassieDashboardAPI} from "../../../api";
import {
    doFetchScenarios, listLightningWindfarms, selectLightningWindfarms,
    selectScenarios
} from "../../../flow/lightningFlow";
import './lightning-report.css'
import WplInput from "../../../components/wpl-input/WplInput";
import {toPrettyDateStr, toStandardWplTimestamp} from "../../../prettyDate";
import OlMap from "../../../components/ol-map/OlMap";
import InfoCards from "../../../components/info-card/InfoCard";
import {getIconForTurbine} from "../../../components/ol-map/icons";
import WplButton from "../../../components/wpl-button/WplButton";
import ShowLessMore from "../../../components/show-lessmore/ShowLessMore";
import TurbineLightningRuler from "../../../components/turbine-lightning-ruler/TurbineLightningRuler";

export default function LightningReport() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedWindfarm, setSelectedWindfarm] = useState(null);
    const [notifications, setNotifications] = useState(null);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState({
        from: '',
        to: '',
    });
    const [historicActivity, setHistoricActivity] = useState(null);
    const [historicUserAction, setHistoricUserAction] = useState(null);
    const [historicTriggers, setHistoricTriggers] = useState(null);

    const [openScenarios, setOpenScenarios] = useState({});

    const scenarios = useSelector(selectScenarios);
    const windfarms = useSelector(selectLightningWindfarms);

    let {windfarm_id} = useParams();
    if (windfarm_id) {
        windfarm_id = parseInt(windfarm_id);
    }
    useEffect(() => {
        if (!windfarm_id || !windfarms) return;
        if (selectedWindfarm && selectedWindfarm.id === windfarm_id) return;

        setSelectedWindfarm(windfarms.find(wf => wf.id === windfarm_id));
        setNotifications(null);
        setSelectedNotification(null);
        setHistoricActivity(null);
        setHistoricUserAction(null);
        setHistoricTriggers(null);
    }, [selectedWindfarm, windfarm_id, windfarms])

    // initialization
    useEffect(() => {
        dispatch(listLightningWindfarms())
        const year = new Date().getFullYear()
        setSelectedTimePeriod({
            from: `${year}-01-01`,
            to: `${year}-12-31`
        })
    }, []);

    useEffect(() => {
        if (!windfarm_id) return;
        dispatch(doFetchScenarios(windfarm_id));
    }, [windfarm_id]);

    useEffect(() => {
        if (!windfarm_id || !selectedTimePeriod.from || !selectedTimePeriod.to) return;

        lassieDashboardAPI.listNotificationOverview(
            windfarm_id,
            selectedTimePeriod.from,
            selectedTimePeriod.to
        ).then(setNotifications)
    }, [windfarm_id, selectedTimePeriod.from, selectedTimePeriod.to]);

    useEffect(() => {
        if (!selectedNotification || !windfarm_id) return;

        lassieDashboardAPI.listHistoricActivity({
            windfarm_id,
            from_timestamp: toStandardWplTimestamp(new Date(selectedNotification.first_lightning_timestamp)),
            to_timestamp: toStandardWplTimestamp(new Date(selectedNotification.last_lightning_timestamp))
        }).then(setHistoricActivity)

        const lastTimestamp = new Date(selectedNotification.last_lightning_timestamp)
        const toDate = new Date(selectedNotification.last_lightning_timestamp)
        toDate.setDate(lastTimestamp.getDate() + 21)

        lassieDashboardAPI.listWindfarmsHistoricUserAction(
            windfarm_id,
            toStandardWplTimestamp(new Date(selectedNotification.first_lightning_timestamp)),
            toStandardWplTimestamp(toDate),
        ).then(setHistoricUserAction);

        lassieDashboardAPI.listHistoricTriggers(
            windfarm_id,
            toStandardWplTimestamp(new Date(selectedNotification.first_lightning_timestamp)),
            toStandardWplTimestamp(new Date(selectedNotification.last_lightning_timestamp)),
        ).then(setHistoricTriggers);
    }, [selectedNotification, windfarm_id]);

    return (<div className='lightning-report'>
        <WplInput
            title='Jump to windfarm'
            className='no-risk-windfarms'
            options={windfarms.map(wf => wf.name)}
            onlySelectableOptions
            onChanged={name => {
                const wf = windfarms.find(w => w.name === name)
                if (wf) {
                    navigate(`/lassie/report/windfarm/${wf.id}`)
                }
            }}
        />

        <div className='windfarm-report'>
            <h1>{selectedWindfarm && selectedWindfarm.name}</h1>
            <p>Scenarios set up to allow quick response:</p>
            <div className='scenarios'>
                {scenarios.map(scenario => <div className='scenario' key={scenario.id}>
                    <h3>"{scenario.title}"</h3>
                    <ShowLessMore showMore={!!openScenarios[scenario.id]}
                                  setShowMore={o => setOpenScenarios(p => ({...p, [scenario.id]: o}))}>
                        <label><b>Proposed Immediate Action:</b></label>
                        <p>{scenario.immediate_action}</p>

                        <label><b>Description:</b></label>
                        <p>{scenario.description}</p>
                    </ShowLessMore>
                </div>)}
            </div>

            <div className='from-to-date-wrapper'>
                {[3, 2, 1, 0].map(offset => {
                    const y = new Date().getFullYear() - offset
                    const f = `${y}-01-01`
                    return <WplButton
                        className={`year-option ${selectedTimePeriod.from === f ? 'selected' : ''}`}
                        value={y}
                        key={offset}
                        onClick={_ => {
                            setSelectedTimePeriod({
                                from: f,
                                to: `${y}-12-31`
                            })
                        }}
                    />
                })}
            </div>

            <h1>In-depth analysis</h1>
            {notifications && <>
                <h2>There were {notifications.length} notification{notifications.length === 1 ? '' : 's'} in this
                    period.</h2>
                <p>
                    Each notification suggests serious lightning activity close to at least one turbine.
                    In the following section, you will see an overview of what happened and what the response was.
                </p>
                <div className='notification-selection'>
                    {notifications.map(notif =>
                        <div key={notif.id}
                             className={`selectable-notification ${selectedNotification && notif.date === selectedNotification.date ? 'selected' : ''}`}
                             onClick={_ => setSelectedNotification(notif)}>
                            <h3>{toPrettyDateStr(new Date(notif.first_lightning_timestamp))}</h3>
                        </div>)}
                </div>
                {selectedNotification &&
                    <div key={selectedNotification.first_lightning_timestamp} className='notification'>
                        <div className='date-title'>
                            <p><b>{toPrettyDateStr(new Date(selectedNotification.first_lightning_timestamp))}</b></p>
                            <p><b>&#10230;</b></p>
                            <p><b>{toPrettyDateStr(new Date(selectedNotification.last_lightning_timestamp))}</b></p>
                        </div>

                        <div className='date-title'>
                            <p>{toPrettyDateStr(new Date(selectedNotification.first_lightning_timestamp), true).split(" ").pop()}</p>
                            <p>{toPrettyDateStr(new Date(selectedNotification.last_lightning_timestamp), true).split(" ").pop()}</p>
                        </div>
                        {historicActivity && <OlMap
                            forceShiftkeyToZoom
                            lightning_strikes={[...historicActivity.map(t => t.turbine_lightning)].flat()}
                            turbines={historicActivity}/>}

                        <h1>Team response</h1>
                        {historicUserAction && <div className='user-action'>
                            <p>In the following <b>three</b> weeks, there was {historicUserAction.length} registered
                                user
                                actions.</p>
                            <InfoCards statistics={[
                                {
                                    value: historicUserAction.filter(hs => hs.action === 'inspection').length,
                                    title: 'Inspections'
                                }, {
                                    value: historicUserAction.filter(hs => hs.action === 'acceptable_risk').length,
                                    title: 'Accepted Risk'
                                }, {
                                    value: historicUserAction.filter(hs => hs.document_id !== null).length,
                                    title: 'Uploaded Documents'
                                }, {
                                    value: historicUserAction.filter(hs => !!hs.user_comment).length,
                                    title: 'User Comments'
                                },
                            ]}/>
                        </div>}

                        {historicActivity && historicActivity
                            .filter(turbine => turbine.turbine_lightning.length > 0) // || !!historicTriggers.some(ht => ht.turbine_id === turbine.id)
                            .sort((at, bt) => bt.probability_of_atleast_one - at.probability_of_atleast_one)
                            .map(turbine => {
                                let lightningDates = new Set(turbine.turbine_lightning.map(tl => toPrettyDateStr(new Date(tl.timestamp))))
                                lightningDates = Array.from(lightningDates).map(d => new Date(d)).sort((a, b) => a - b);
                                const latestDate = lightningDates[0]

                                return (<div className='notification-turbine' key={turbine.id}>
                                    <h2 title={{
                                        'acceptable_risk': 'Managed',
                                        'at_risk': 'At risk',
                                        'marked_for_inspection': 'Marked for inspection'
                                    }[turbine.status]}>
                                        <img style={{height: '1em'}}
                                             src={getIconForTurbine(turbine, false)}/>
                                        {turbine.name}
                                    </h2>
                                    {lightningDates.map(k => <p>{toPrettyDateStr(k)}</p>)}

                                    <TurbineLightningRuler strikes={turbine.turbine_lightning}/>

                                    <table className='scenario-table'>
                                        <tbody>
                                        <tr>
                                            <th colSpan={2}>Triggered scenarios</th>
                                        </tr>
                                        {historicTriggers && historicTriggers.filter(ht => ht.turbine_id === turbine.id).map(ht => {
                                            const sc = scenarios.find(s => s.id === ht.scenario_id)
                                            if (!sc) return;
                                            return <tr key={sc.id}>
                                                <td>{sc.title}</td>
                                                <td>{ht.trigger_count}</td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>

                                    {historicUserAction && historicUserAction.filter(hs => hs.turbine_id === turbine.id).sort((a, b) => new Date(a.target_date) - new Date(b.target_date)).map(hs => {
                                        const dayDiff = Math.round((new Date(hs.target_date) - latestDate) / 86400 / 1000)
                                        return (
                                            <div key={hs.id} className='user-action'>
                                                <p>{toPrettyDateStr(new Date(hs.target_date))} {!!dayDiff && `(${dayDiff} day${dayDiff > 1 ? 's' : ''} later)`}</p>
                                                <b>Set status {hs.action}</b>
                                                {hs.document_id && <p><b>Document</b></p>}
                                                {!!hs.user_comment &&
                                                    <p className='user-comment'>"{hs.user_comment}"</p>}
                                                <p>> {hs.user_name}</p>
                                            </div>)
                                    })}
                                    {historicUserAction && historicUserAction.filter(hs => hs.turbine_id === turbine.id).length === 0 && <div>
                                        <p style={{color: 'darkred'}}>No team reaction within the first 3 weeks.</p>
                                    </div>}
                                </div>)
                            })}
                    </div>}
            </>}
        </div>
    </div>)
}
