import React, {useRef} from 'react'
import './wpl-popup.css'
import cross from '../../assets/img/clear-24px.svg'

export default function WplPopup({children, showPopup, closePopup, className, showCloseIcon=true}) {
    const me = useRef(null)
    if (!showPopup) return <div></div>
    return (<div ref={me} className='popup' onClick={e => {
        if (e.target !== me.current) return;
        console.log(e)
        closePopup(false);
    }}>
        {showCloseIcon && <img className='close-popup-cross-btn' onClick={_ => closePopup(false)} src={cross} alt='close popup' />}
        <div className={`popup-wrapper ${className}`}>
            {children}
        </div>
    </div>)
}
