import VectorSource from "ol/source/Vector";
import {GeoJSON} from "ol/format";

export default (features) => {
    if (features.length === 0) return new VectorSource({
            features: []
        })

    const geojsonObject = {
        type: "FeatureCollection",
        crs: {
            type: "name",
            properties: {
                name: "EPSG:3857"
            }
        },
        features: features
    };
    return new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObject)
    })
};
