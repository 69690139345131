import React, {useRef, useState, useCallback, useEffect, useContext} from 'react'
import WplInput from "../wpl-input/WplInput";
import WplButton from "../wpl-button/WplButton";
import {defectAPI, imageAPI} from "../../api";
import './defect.css'
import WplPopup from "../wpl-popup-window/WplPopup";
import loading from '../../assets/loading.svg'
import cross from '../../assets/img/clear-24px.svg'
import EndlessScrollImg from "../endless-scroll-img/EndlessScrollImg";
import {useSelector} from "react-redux";
import {selectAllDefects} from "../../flow/defectFLOW";

export default function ({defect, showCloseButton=false, close}) {
    const [dirtyCustomerNote, setDirtyCustomerNote] = useState(null);
    const [dirtyBudgetNote, setDirtyBudgetNote] = useState(null);
    const [dirtyDowntimeNote, setDirtyDowntimeNote] = useState(null);

    const [previousDefects, setPreviousDefects] = useState([]);

    const [largeDefectImg, setLargeDefectImage]= useState('');
    const [smallDefectImg, setSmallDefectImage]= useState('');

    const [showLargeImg, setShowLargeImg] = useState(false);

    const defects = useSelector(selectAllDefects)

    useEffect(() => {
        if (showLargeImg) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [showLargeImg])

    useEffect(() => {
        if (!defect.id) return;

        setDirtyBudgetNote(defect.budgetNote);
        setDirtyCustomerNote(defect.customerNote);
        setDirtyDowntimeNote(defect.downtimeNote);

        const prev = defects
            .filter(d => d.turbineName === defect.turbineName)
            .filter(d => d.inspectionDate !== defect.inspectionDate)
            .filter(d => d.blade === defect.blade)
            .filter(d => d.surface === defect.surface)
            .filter(d => Math.abs(d.distanceFromHub - defect.distanceFromHub) < 1.5)

        setPreviousDefects(prev);

    }, [defect, defects, setDirtyBudgetNote, setDirtyCustomerNote, setDirtyDowntimeNote, setPreviousDefects]);

    useEffect(() => {
        if (!defect.id || !showLargeImg || largeDefectImg === loading) return;

        setLargeDefectImage(loading);
        imageAPI.getDefectBladeImageUrl(defect.id).then(setLargeDefectImage)
    }, [defect.id, showLargeImg]);

    useEffect(() => {
        if (!defect.id || smallDefectImg === loading) return;
        setSmallDefectImage(loading);
        imageAPI.getDefectImageUrl(defect.id).then(setSmallDefectImage)
    }, [defect.id])

    const onSave = useCallback(() => {
        // call api to save comment
        if (dirtyBudgetNote !== defect.budgetNote) {
            console.log('Saving defect budgetNote');
            defectAPI.setBudgetNote(defect.id, dirtyBudgetNote);
            // defect.budgetNote = dirtyBudgetNote
        }
        if (dirtyCustomerNote !== defect.customerNote) {
            console.log('Saving defect customerNote');
            defectAPI.setCustomerNote(defect.id, dirtyCustomerNote);
            // defect.customerNote = dirtyCustomerNote
        }
        if (dirtyDowntimeNote !== defect.downtimeNote) {
            console.log('Saving defect downtimeNote');
            defectAPI.setDowntimeNote(defect.id, dirtyDowntimeNote);
            // defect.downtimeNote = dirtyDowntimeNote
        }
    }, [defect, dirtyCustomerNote, dirtyBudgetNote, dirtyDowntimeNote]);

    const outerWrapper = useRef()
    return (<div className='defect-wrapper' ref={outerWrapper}>
            <WplPopup className='large-img-popup' closePopup={setShowLargeImg} showPopup={showLargeImg}>
                <EndlessScrollImg defect={defect} outerWrapperRef={outerWrapper} img={largeDefectImg}/>
            </WplPopup>
            <div className='header'>
                <h1>Defect {defect.reportDefectId}</h1>
                <div className='spacer'>&nbsp;</div>
                {showCloseButton && <img src={cross} alt='close' onClick={close} />}
            </div>
            <div className='info-wrapper'>
                <div className='defect-info-wrapper'>
                    <table>
                        <tbody>
                        <tr>
                            <th>Windfarm</th>
                            <td>{defect.windfarm}</td>
                        </tr>
                        <tr>
                            <th>Turbine</th>
                            <td>{defect.turbineName}</td>
                        </tr>
                        <tr>
                            <th>Blade</th>
                            <td>{defect.blade}</td>
                        </tr>
                        <tr>
                            <th>Defect type</th>
                            <td>{defect.defectType}</td>
                        </tr>
                        <tr>
                            <th>Layer</th>
                            <td>{defect.layer}</td>
                        </tr>
                        <tr>
                            <th>Surface</th>
                            <td>{defect.surface}</td>
                        </tr>
                        <tr>
                            <th>Inspection date</th>
                            <td>{defect.inspectionDate}</td>
                        </tr>
                        <tr>
                            <th>Severity:</th>
                            <td>{defect.severity}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className='defect-info-wrapper img-wrapper'>
                    <img className='defect-img' src={smallDefectImg} onClick={() => setShowLargeImg(true)}
                         alt='defect img' />
                    {previousDefects.length > 0 && <h3>Previous</h3>}
                    <table>
                        <tbody>

                        {previousDefects
                            .sort((a, b) => Math.abs(defect.distanceFromHub - a.distanceFromHub) - Math.abs(defect.distanceFromHub - b.distanceFromHub))
                            .map(d => <tr key={d.id}>
                                <td>{d.inspectionDate}</td>
                                <td>{d.defectType}</td>
                                <td>{d.layer}</td>
                                <td>{Math.round(Math.abs(d.distanceFromHub-defect.distanceFromHub)*100)/100}m</td>
                            </tr>)
                        }
                        </tbody>
                    </table>
                </div>
                <div className='defect-info-wrapper'>
                    {defect.repairComment && <label>Repair comment:</label>}
                    {defect.repairComment && <span>{defect.repairComment}</span>}
                    <div className='defect-comment'>
                        <WplInput type='text' title='Defect comment' value={dirtyCustomerNote ?? ''} onChanged={setDirtyCustomerNote}/>
                        <WplButton value='Save' onClick={onSave} hidden={(dirtyCustomerNote === defect.customerNote)} />
                    </div>
                    <div className='defect-comment'>
                        <WplInput type='text' title='Budget' value={dirtyBudgetNote ?? ''} onChanged={setDirtyBudgetNote}/>
                        <WplButton value='Save' onClick={onSave} hidden={(dirtyBudgetNote === defect.budgetNote)} />
                    </div>
                    <div className='defect-comment'>
                        <WplInput type='text' title='Downtime' value={dirtyDowntimeNote ?? ''} onChanged={setDirtyDowntimeNote}/>
                        <WplButton value='Save' onClick={onSave} hidden={(dirtyDowntimeNote === defect.downtimeNote)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
