import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import exclamation_mark from "../../assets/img/exclamation-mark.svg";
import {
    listLightningWindfarms,
    selectLightningWindfarms
} from "../../flow/lightningFlow";
import './at-risk-windfarm-list.css'
import {icons_handled} from "../ol-map/icons";


export default function WindfarmList({onlyShowAtRisk = false, onClick = null}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const allWindfarms = useSelector(selectLightningWindfarms);
    const riskWindfarms = useMemo(() => {
        return allWindfarms.filter(wf => wf.require_attention_count > 0).sort(wf => 1 - wf.require_attention_count)
    }, [allWindfarms]);

    const noRiskWindfarms = useMemo(() => {
        return allWindfarms.filter(wf => wf.require_attention_count === 0)
    }, [allWindfarms]);

    useEffect(() => {
        if (!!allWindfarms) return;
        dispatch(listLightningWindfarms());
    }, [dispatch]);

    const wfs = useMemo(() => {
        if (onlyShowAtRisk) return riskWindfarms
        return allWindfarms
    }, [onlyShowAtRisk, allWindfarms, riskWindfarms]);

    return (<div className='windfarm-list'>
        <h1>Windfarms </h1>
        {riskWindfarms.length > 0 && <>
            {riskWindfarms.map(wf => <div
                key={wf.id}
                onClick={_ => onClick ? onClick(wf) : navigate(`windfarm/${wf.id}`)}
                className={`windfarm ${wf.require_attention_count > 0 ? 'at-risk' : ''}`}>
                <h2>{wf.name}</h2>
                <div className='icon-wrapper bad'>
                    <label>{wf.require_attention_count}</label>
                    <img alt='Turbines on this windfarm has had lightning activity' src={exclamation_mark}/>
                    <label>Turbines</label>
                </div>
            </div>)}
        </>}
        <hr/>
        {noRiskWindfarms.length > 0 && <>
            {/*<hr />*/}
            {noRiskWindfarms.map(wf => <div
                key={wf.id}
                onClick={_ => onClick ? onClick(wf) : navigate(`windfarm/${wf.id}`)}
                className={`windfarm ${wf.require_attention_count > 0 ? 'at-risk' : ''}`}>
                <h2>{wf.name}</h2>
                <div className='icon-wrapper'>
                    <label>Managed</label>
                    <img alt='Turbines on this windfarm has had lightning activity' src={icons_handled.High}/>
                </div>
            </div>)}
        </>
        }
        {wfs.length === 0 && <h3>Loading...</h3>}
    </div>)
}
