import React, { useState } from 'react'
import './header.css'
import logo from "../../assets/img/WPL-Global-Blade-Experts-04.png";
import HoverMenu from "../hover-menu/HoverMenu";
import menuMan from "../../assets/img/emoji_people-24px.svg";

export default function Header() {
    return (<div className='main-header'>
        <img className='logo' alt='Wind Power Lab' src={logo} style={{
            height: 40,
            padding: 10,
            width: 'auto'
        }} onClick={() => { window.location = '/' }} />
        {process.env.REACT_APP_ENVIRONMENT === 'DEV' && <h3>DEVELOPMENT</h3>}
        <HoverMenu im={menuMan} />
    </div>)
}
