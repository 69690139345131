import React, {useEffect, useRef} from 'react'
import * as d3 from 'd3';

export default function ({
                             innerRadius,
                             outerRadius,
                             data,
                             colors
                         }) {
    const ref  = useRef(null)
    const createPie = d3
        .pie()
        .value(d => d.value)
        .sort(null);

    const createArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

    function transformData(data, colors) {
        const spacerWidth = 0.005;

        const total = data.reduce((c, p) => {
            return p.value + c
        }, 0);

        let res = []
        let resColors = []
        data.forEach(({text, value}, idx) => {
            if (value <= 0) return;

            const val = (value/total) - spacerWidth
            res.push({
                text,
                value: val < 0.02 ? 0.02 : val
            });
            resColors.push(colors[idx]);

            res.push({
                text: 'spacer',
                value: spacerWidth
            });
            resColors.push('white');
        });
        return [res, resColors]
    }

    useEffect(() => {
        [data, colors] = transformData(data, colors);
        const d3Data = createPie(data)
        const group = d3.select(ref.current);
        const groupWithData = group.selectAll('g.arc').data(d3Data);

        groupWithData.exit().remove()

        const groupWithUpdate = groupWithData.enter().append('g').attr('class', 'arc');

        const path = groupWithUpdate
            .append('path')
            .merge(groupWithData.select('path.arc'));

        path
            .attr('class', 'arc')
            .attr('d', createArc)
            .attr('fill', (d, i) => colors[i])

    }, [data, ref.current])

    return (<svg className='pie-svg' style={{
        width: outerRadius*2,
        height: outerRadius*2,
    }}>
            <g onMouseEnter={_ => console.log()}
                ref={ref}
                transform={`translate(${outerRadius} ${outerRadius})`}
            />
        </svg>)
}
