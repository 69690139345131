import React, {useState, useCallback} from 'react'
import WplFileUpload from "../../components/wpl-file-upload/WplFileUpload";
import {datamanagementAPI} from "../../api";
import './self-service-upload-pdf.css'
import DmWindDiagnostics from "../data-management/DmWindDiagnostics";
import SkaliaDisplay from "./SkaliaDisplay";
import WplInput from "../../components/wpl-input/WplInput";


export default function SelfServiceUploadPdf() {
    const [parsedData, setParsedData] = useState({});
    const [useForRR, setUseForRR] = useState(false);

    const uploadedPdfFile = useCallback((filename, data) => {
        console.log('Uploaded pdf file to WD with name', filename)

        if (useForRR) {
            datamanagementAPI.parseSelfServiceDefectPdfForRepairRecommendation(filename, data).then(data => {
                setParsedData(data)
            })
        } else {
            datamanagementAPI.parseSelfServiceDefectPdf(filename, data).then(data => {
                setParsedData(data)
            })
        }
    }, [setParsedData, useForRR]);

    return (<div className='self-service-upload-pdf'>
            <h1>Import repair inspections from third party clients</h1>
            <WplInput type='checkbox' title='Use for repair recommendations' value={useForRR} onChanged={setUseForRR}/>
            <p>Supported pdf formats:</p>
            <ol>
                <li>Skalia</li>
                <li>Sulzer & Schmid Laboratories</li>
                <li>Skyspecs</li>
                <li>Cornis</li>
                <li>Deutsche Windtechnik</li>
            </ol>
            <WplFileUpload asPopup={false} displayFiles={false} didSelectFile={uploadedPdfFile}/>

            {!parsedData.pdf_type && <DmWindDiagnostics initialWdData={parsedData} image_source='local' />}
            {parsedData.pdf_type === 'skalia' && <SkaliaDisplay parsedData={parsedData}/>}
        </div>
    );
}
