import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {
    doFetchLightningTurbine, doSetSelectedTurbines, selectLightningTurbineById, selectSelectedTurbineIds,
} from "../../../flow/lightningFlow";
import './lightning-turbine-page.css'
import LightningStrikeList from "../LightningStrikeList";
import OlMap from "../../../components/ol-map/OlMap";
import {demAPI, lassieDashboardAPI, lightningUserActionAPI} from "../../../api";
import {toPrettyDateStr, toStandardWplTimestamp} from "../../../prettyDate";
import WplButton from "../../../components/wpl-button/WplButton";
import CreateUserAction from "../../../components/create-user-action/CreateUserAction";

export default function LightningTurbinePage() {
    const [historicUserActions, setHistoricUserActions] = useState([]);
    const [demUrl, setDemUrl] = useState(null);
    const [loadingDemUrl, setLoadingDemUrl] = useState(null);

    const dispatch = useDispatch()

    let {turbine_id} = useParams();
    turbine_id = parseInt(turbine_id);

    const turbine = useSelector(selectLightningTurbineById(turbine_id))

    const fetchUserActions = useCallback(() => {
        const today = new Date()
        const twoYearsAgo = new Date(today.setDate(today.getDate() - 720))
        lassieDashboardAPI.listTurbineHistoricUserAction(
            turbine_id,
            toStandardWplTimestamp(twoYearsAgo),
            toStandardWplTimestamp(new Date())).then(setHistoricUserActions);

    }, [turbine_id]);

    useEffect(() => {
        dispatch(doFetchLightningTurbine(turbine_id));
        dispatch(doSetSelectedTurbines([turbine_id]))
        fetchUserActions()
        // demAPI.get_turbine_dem_embedded(turbine_id).then(setDemEmbeddedHtml);
    }, [dispatch, fetchUserActions, turbine_id]);

    useEffect(() => {
        if (demUrl !== null) {
            return;
        }
        setLoadingDemUrl(true);
        setDemUrl(`${process.env.REACT_APP_API_URL}/dem/turbine/${turbine_id}/embedded`)
    }, [process.env.REACT_APP_API_URL, turbine_id]);

    if (!turbine) return null;

    return (<div className='lightning-turbine-page'>
        <h1>{turbine.name}</h1>
        <OlMap turbines={[turbine]} lightning_strikes={turbine.turbine_lightning}/>

        <h2>Historic User Actions</h2>
        <table className='user-action-table'>
            <thead>
            <tr>
                <th>Action</th>
                <th>Target date</th>
                <th>Timestamp created</th>
                <th>Username</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            {historicUserActions.map((action) => (
                <tr key={action.id}>
                    <td>{action.action}</td>
                    <td>{action.target_date}</td>
                    <td>{action.timestamp_created}</td>
                    <td>{action.user_name}</td>
                    <td>{action.user_comment}</td>
                    {turbine.latest_user_action && turbine.latest_user_action.id === action.id &&
                        <td><WplButton value='delete' red onClick={_ =>
                            lightningUserActionAPI.delete_user_action(action.id).then(_ => {
                                dispatch(doFetchLightningTurbine(turbine_id));
                                fetchUserActions()
                            })
                        }/></td>}
                </tr>
            ))}
            {historicUserActions.length === 0 && <tr>
                <td colSpan={6}>No registered user actions for this turbine.</td>
            </tr>}
            </tbody>
        </table>

        {/*<h2>Update turbine</h2>*/}
        {/*<CreateUserAction />*/}

        {turbine.turbine_lightning.length > 0 && <>
            <h2>Strikes since last 'accepted risk' or since 'last inspection' user action</h2>
            <LightningStrikeList lightningStrikes={turbine.turbine_lightning}/>
        </>}

        {/*<div className='dem-image-wrapper'>*/}
        {/*    {loadingDemUrl === false && <h1>3d model of turbine terrain</h1>}*/}
        {/*    {demUrl && <img onLoad={_ => setLoadingDemUrl(false)} src={demUrl} alt='DEM'/>}*/}
        {/*</div>*/}
    </div>)
}
