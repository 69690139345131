import React, {useEffect, useRef, useState} from 'react';
import WplInput from "../../components/wpl-input/WplInput";
import './team-color-page.css'

export default function TeamColorPage() {
    const [colors, setColors] = useState('')

    const canvasRef = useRef(null)

    function colorFromInput(inp) {
        let final = [];

        [...inp].forEach(character => {
            let col = '#FFFFFF'
            switch (character) {
                case 'r':
                    col = '#FF0000'
                    break
                case 'b':
                    col = '#0000FF'
                    break
                case 'g':
                    col = '#00FF00'
                    break
                case 'p':
                    col = '#FF00FF'
                    break
                case 'y':
                    col = '#FFFF00'
                    break
                case 'o':
                    col = '#FFA500'
                    break
                case 'w':
                    col = '#FFFFFF'
                    break
                case 'B':
                    col = '#000000'
            }
            final.push(col)
        })

        return final
    }

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        context.fillStyle = '#ffffff'
        context.fillRect(0, 0, context.canvas.width, context.canvas.height)

        const checkIns = colors.split(',')
        if (checkIns.length === 0) return;
        const MAX_PR_LINE = 5

        const currentItemsPrLine = Math.min(checkIns.length, MAX_PR_LINE)

        const width = (context.canvas.width-(currentItemsPrLine*2))/currentItemsPrLine

        checkIns.map((inp, idx) => {
            const colors = colorFromInput(inp)

            const leftIdx = idx%MAX_PR_LINE
            const left = leftIdx*width+(leftIdx*2)
            const lineIdx = Math.floor(idx/MAX_PR_LINE)

            colors.forEach((col, colIdx) => {
                const squareHeight = 15
                const rowHeight = squareHeight/colors.length
                context.fillStyle = col
                context.fillRect(left, (lineIdx*squareHeight)+(lineIdx*2)+colIdx*rowHeight, width, rowHeight)
            })
        })

    }, [canvasRef, colors]);

    return (<div className='team-color-page'>
        <h1>Color check-in image generator</h1>
        <WplInput value={colors} onChanged={setColors} />

        <canvas ref={canvasRef} />
    </div>)
}
