import {documentAPI} from "../api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {DocumentApi} from "../api_client/Document";
import {Buffer} from "buffer";

const initialState = {
    documentsLoading: false,
    documents: [],
}

export const uploadDocument = createAsyncThunk('document/upload', ({
                                                                       file_name,
                                                                       content,
                                                                       windfarm_id,
                                                                       turbine_id,
                                                                       callback
                                                                   }) => {
    const [heads, d] = content.split(';base64,');
    const binary_data = Buffer.from(d, 'base64');
    return documentAPI.upload(file_name, binary_data, windfarm_id, turbine_id).then(docs => {
        callback()
        return docs
    })
})
export const deleteDocument = createAsyncThunk('document/delete', (document_id) => {
    return documentAPI.delete(document_id)
})

export const getTurbineDocuments = createAsyncThunk('document/listTurbine', (turbine_id) => {
    return documentAPI.getTurbineDocuments(turbine_id)
})
export const getWindfarmDocuments = createAsyncThunk('document/listWindfarm', (windfarm_id) => {
    return documentAPI.getWindfarmDocuments(windfarm_id)
})

const documentReducer = createSlice({
    name: 'document',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(uploadDocument.pending, (state, action) => {
                state.documentsLoading = true
            })
            .addCase(uploadDocument.fulfilled, (state, action) => {
                state.documentsLoading = false
                state.documents = [...action.payload]
            })

            .addCase(deleteDocument.pending, (state, action) => {
                state.documentsLoading = true
            })
            .addCase(deleteDocument.fulfilled, (state, action) => {
                state.documentsLoading = false
                state.documents = state.documents.filter(doc => doc.id !== action.payload)
            })

            .addCase(getTurbineDocuments.pending, (state, action) => {
                state.documentsLoading = true
            })
            .addCase(getTurbineDocuments.fulfilled, (state, action) => {
                state.documentsLoading = false
                state.documents = action.payload
            })
            .addCase(getWindfarmDocuments.pending, (state, action) => {
                state.documentsLoading = true
            })
            .addCase(getWindfarmDocuments.fulfilled, (state, action) => {
                state.documentsLoading = false
                state.documents = action.payload
            })
    }
})

export default documentReducer.reducer

export const selectAllDocuments = state => {
    return state.document.documents
}

export const selectDocumentsLoading = state => {
    return state.document.documentsLoading
}
