import {http} from "../api";

const easyUnpack = ({data}) => data;
export const GoosePictureApi = {
    rename_turbine: {
        f: function rename_turbine(windfarm, turbine, new_turbine_name, {blade = null, surface = null}) {
            let url = '/goose/picture';
            return http.patch(url, {params: {windfarm, turbine, new_turbine_name, blade, surface}}).then(easyUnpack)
        },
    },
    delete_pictures: {
        f: function delete_pictures(picture_ids) {
            let url = '/goose/picture/delete';
            return http.post(url, {picture_ids}).then(easyUnpack)
        },
    },
    list_defects_by_picture_ids: {
        f: function list_defects_by_picture_ids(picture_ids) {
            let url = '/goose/picture/defects';
            return http.post(url, {picture_ids}).then(easyUnpack)
        },
    },
    get: {
        f: function get({windfarm = null, turbine = null, blade = null, surface = null, report_id = null}) {
            let url = '/goose/picture';
            return http.get(url, {params: {windfarm, turbine, blade, surface, report_id}}).then(easyUnpack)
        },
    },
    count_pictures: {
        f: function count_pictures({windfarm = null, turbine = null, blade = null, surface = null, report_id = null}) {
            let url = '/goose/picture/count';
            return http.get(url, {params: {windfarm, turbine, blade, surface, report_id}}).then(easyUnpack)
        },
    },
    list_unique_filters: {
        f: function list_unique_filters() {
            let url = '/goose/picture/unique-filters';
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    picture_series_by_picture_id: {
        f: function picture_series_by_picture_id(picture_id) {
            let url = '/goose/picture/$picture_id/series';
            let parameterNames = ["$picture_id"];
            [picture_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {}).then(easyUnpack)
        },
    },
    picture_series_by_report_and_turbine: {
        f: function picture_series_by_report_and_turbine(report_id, turbine) {
            let url = '/goose/picture/series';
            return http.post(url, {report_id, turbine}).then(easyUnpack)
        },
    },
    signed_url_from_id: {
        f: function signed_url_from_id(picture_id) {
            let url = '/goose/picture/$picture_id/signed';
            let parameterNames = ["$picture_id"];
            [picture_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    segmentation_signed_url_from_id: {
        f: function segmentation_signed_url_from_id(picture_id) {
            let url = '/goose/picture/$picture_id/segmentation/signed';
            let parameterNames = ["$picture_id"];
            [picture_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    set_distance_validation: {
        f: function set_distance_validation(picture_id, polygon, location) {
            let url = '/goose/picture/$picture_id/distance-validation';
            let parameterNames = ["$picture_id"];
            [picture_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {polygon, location}).then(easyUnpack)
        },
    },
};
