import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import './dual-range-input.css';


function clamp(v, min, max, noBoundaries) {
    if (v >= max) v = noBoundaries ? Infinity : max;
    if (v <= min) v = noBoundaries ? -Infinity : min;
    return v;
}

export default function DualRangeInput({value, min, max, onChange, noBoundaries=false, className=''}) {
    const parentRef = useRef();
    const [mouseDownOn, setMouseDownOn] = useState('');

    const stepSize = useMemo(() => {
        return 100/(max - min)
    }, [min, max]);

    const calculateOffset = useCallback(v => {
        if (v <= min) return 0;
        if (v >= max) return 100;

        return Math.round((v - min) * stepSize)
    }, [stepSize, min, max]);

    const mouseMoved = useCallback(e => {
        if (mouseDownOn === '') return;

        let rawNewFrom = value[0] === -Infinity ? min : value[0];
        let rawNewTo = value[1] === Infinity ? max : value[1]

        const parentRect = parentRef.current.getBoundingClientRect();

        if (mouseDownOn === 'left' || mouseDownOn === 'right') {
            const pageX = e.pageX;
            const parentX = parentRect.x;

            const pixelInset = pageX - parentX;
            const percentual = pixelInset/parentRect.width;

            let newValue = Math.round(min + (max-min)*percentual)
            if (mouseDownOn === 'left') {
                rawNewFrom = newValue
            } else if (mouseDownOn === 'right') {
                rawNewTo = newValue
            }
            const from = clamp(rawNewFrom, min, rawNewTo, noBoundaries);
            const to = clamp(rawNewTo, rawNewFrom, max, noBoundaries)

            onChange([from, to])
        } else if (mouseDownOn === 'slider') {
            const mouseMoveX = e.movementX
            const percentage = Math.abs(mouseMoveX)/parentRect.width;

            let newValue = (max-min)*percentage;
            if (mouseMoveX < 0) newValue *= -1

            onChange(([pFrom, pTo]) => {
                pFrom = pFrom === -Infinity ? min : pFrom
                pTo = pTo === Infinity ? max : pTo

                if (pFrom+newValue <= min) return [-Infinity, Math.round(pTo)]
                if (pTo+newValue >= max) return [Math.round(pFrom), Infinity]

                return [Math.round(pFrom+newValue), Math.round(pTo+newValue)]
            })
        }
    }, [mouseDownOn, stepSize, parentRef]);

    const mouseUp = useCallback(e => {
        setMouseDownOn('');
    }, []);

    useEffect(() => {
        document.addEventListener('mousemove', mouseMoved);
        document.addEventListener('mouseup', mouseUp);
        return () => {
            document.removeEventListener('mousemove', mouseMoved);
            document.removeEventListener('mouseup', mouseUp);
        }
    }, [mouseMoved, mouseUp]);

    return (<div className={`dual-range-input ${className}`} ref={parentRef}>
        <div className='slider'
             draggable={false}
             onMouseDown={e => {
                 setMouseDownOn('slider')
             }}
             style={{
            left: `${calculateOffset(value[0])}%`,
            width: `${calculateOffset(value[1])-calculateOffset(value[0])}%`
        }}/>
        <div className='knob'
             draggable={false}
             onMouseDown={e => {
                 setMouseDownOn('left')
             }}
             style={{
                 left: `${calculateOffset(value[0])}%`
             }}/>
        <div className='knob'
             draggable={false}
             onMouseDown={e => {
                 setMouseDownOn('right')
             }}
             style={{
                 left: `${calculateOffset(value[1])}%`
             }}/>
        <label className='knob-text'
             draggable={false}
             style={{
                 left: `${calculateOffset(value[0])}%`
             }}>{value[0] === -Infinity ? 'min' : Math.round(value[0])}</label>
        <label className='knob-text'
             draggable={false}
             style={{
                 left: `${calculateOffset(value[1])}%`
             }}>{value[1] === Infinity ? 'max' : Math.round(value[1])}</label>
    </div>)
}
