import React, {useCallback, useEffect, useRef, useState} from "react";
import {useEventShortcut, useKeyboardShortcut} from "../../components/UseKeyboardShortcut";
import './endless-scroll-img.css'
import {defectAPI} from "../../api";

export default function ({defect, img, outerWrapperRef}) {
    const [spaceDown, setSpaceDown] = useState(false);
    const [defectPosition, setDefectPosition] = useState('');

    const [xOffset, _setXOffset] = useState(0);
    const [yOffset, _setYOffset] = useState(0);
    const [zoomxOffset, setZoomXOffset] = useState(0);
    const [zoomyOffset, setZoomYOffset] = useState(0);

    const [zoom, setZoom] = useState(1);

    const [imageWidth, setImageWidth] = useState(1000)
    const [imageHeight, setImageHeight] = useState(1000)

    const [xScreenLast, setXScreenLast] = useState(0)
    const [yScreenLast, setYScreenLast] = useState(0)
    const [xImage, setXImage] = useState(0)
    const [yImage, setYImage] = useState(0)

    function setXOffset(x) {
        const {width: imWidth} = imageRef.current.getBoundingClientRect()
        const {width: windowWidth} = outerWrapperRef.current.getBoundingClientRect()

        const maxOffset = (imWidth - windowWidth) / 2
        if (x > maxOffset) x = maxOffset
        if (x < -maxOffset) x = -maxOffset
        _setXOffset(x)
    }

    function setYOffset(y) {
        const {height} = imageRef.current.getBoundingClientRect()
        const maxOffset = height / 4
        if (y > maxOffset) y = maxOffset
        if (y < -maxOffset) y = -maxOffset
        _setYOffset(y)
    }

    const [hideBoxes, setHideBoxes] = useState(false)

    const imageRef = useRef(null);
    const wrapperRef = useRef(null);

    function mouseMove(event) {
        if ((!spaceDown || event.buttons !== 1) && (event.buttons !== 1)) {
            return;
        }
        event.preventDefault()
        const dragSpeed = zoom < 1 ? zoom : 1 / (zoom * 2)
        setXOffset(xOffset + event.movementX * dragSpeed)
        setYOffset(yOffset + event.movementY * dragSpeed)
    }

    useKeyboardShortcut(['z'], () => {
    }, () => {
        const newZoom = zoom * 0.9
        setZoom(newZoom)
    });
    useKeyboardShortcut(['x'], () => {
    }, () => {
        const newZoom = zoom * 1.1
        setZoom(newZoom)
    });
    useKeyboardShortcut(['b'], () => {
    }, () => {
        setHideBoxes(!hideBoxes)
    });

    useKeyboardShortcut([' '], () => setSpaceDown(true), () => setSpaceDown(false))
    useEventShortcut('wheel', (e) => {
        if (['box', 'wing-img'].indexOf(e.target.className) === -1) return;
        const {
            deltaY
        } = e
        let newZoom = zoom - deltaY / 500
        if (newZoom < 0.8) newZoom = 0.8
        if (newZoom > 5) newZoom = 5

        let {
            pageX,
            pageY
        } = e

        const {left, top} = outerWrapperRef.current.getBoundingClientRect()
        let xScreen = pageX - left;
        let yScreen = pageY - top;

        const newXImage = xImage + ((xScreen - xScreenLast) / zoom);
        const newYImage = yImage + ((yScreen - yScreenLast) / zoom);

        let xNew = (xScreen - newXImage) / newZoom;
        let yNew = (yScreen - newYImage) / newZoom;

        setZoom(newZoom)
        setZoomXOffset(xNew)
        setZoomYOffset(yNew)

        setXScreenLast(xScreen)
        setYScreenLast(yScreen)
        setXImage(newXImage)
        setYImage(newYImage)
    })

    const onImageLoaded = useCallback(node => {
        setImageHeight(node.naturalHeight);
        setImageWidth(node.naturalWidth)

        defectAPI.getDefectPosition(defect.reportDefectId).then(pos => {
            if (pos.x === 0 && pos.y === 0) return;
            setDefectPosition(pos)
            const {height, width} = imageRef.current.getBoundingClientRect()
            const relativePosition = {
                x: 0.5 - (pos.x / node.naturalWidth),
                y: 0.5 - (pos.y / node.naturalHeight)
            };
            // setXOffset(relativePosition.x * width)
            setYOffset(relativePosition.y * height)
            setZoom(0.9)
        })
    }, [defect, imageRef, setYOffset]);

    return <div className='wing-wrapper'
                ref={wrapperRef}
                onMouseMove={mouseMove} style={{
        transform: `scale(${zoom}) translate(${zoomxOffset + xOffset}px, ${zoomyOffset + yOffset}px)`,
        transformOrigin: `${xImage}px ${yImage}px`,
    }}>
        <img
            ref={imageRef}
            src={img}
            draggable={false}
            onLoad={() => onImageLoaded(imageRef.current)}
            alt='new'
            style={{width: '100%'}}
            className='wing-img'/>
        <div style={{
            position: 'absolute',
            left: `${defectPosition.x / imageWidth * 100}%`,
            top: `${defectPosition.y / imageHeight * 100}%`,
            border: '1px solid black',
            width: `${defectPosition.width / imageWidth * 100}%`,
            height: `${defectPosition.height / imageHeight * 100}%`,
            pointerEvents: 'none',
        }}>
            &nbsp;
        </div>
    </div>
}
