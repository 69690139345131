import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

export default function Logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExpires')

    const navigate = useNavigate()

    useEffect(() => {
        navigate('/login')
    }, [navigate])

    return null
}
