import {http} from "../api";

const easyUnpack = ({data}) => data;
export const AdminApi = {
    list_lassie_windfarms: {
        f: function list_lassie_windfarms() {
            let url = '/admin/windfarms';
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
};
