import React from 'react';
import {Link, Outlet, useLocation} from "react-router-dom";
import './lightning-settings-page.css'
import WplInput from "../../../components/wpl-input/WplInput";

export default function LightningSettingsParent() {
    const location = useLocation()
    const url = location.pathname

    return (<div className='lightning-settings'>
        <div className='subsettings-link-list'>
            <Link to='scenario'>Scenario</Link>
            <Link to='windfarm'>Windfarm</Link>
            {/*<Link to='data'>Data</Link>*/}
            {/*<Link to='severity'>Severities</Link>*/}
            <Link to='turbines'>Turbine Setup</Link>
        </div>
        <div className='content-wrapper'>
            <Outlet/>
        </div>
    </div>)
}
