import React, {useState, useEffect, useCallback} from 'react'
import WplButton from "../../components/wpl-button/WplButton";
import {defectAPI, turbineAPI, windfarmAPI} from "../../api";
import asyncFunc from 'async';
import exclamationMark from '../../assets/img/exclamation-mark.svg'
import './data-management.css'
import WplInput from "../../components/wpl-input/WplInput";
import ClickToShow from "../../components/click-to-show/ClickToShow";
import {toPrettyDateStr} from "../../prettyDate";

export default function DmWindDiagnostics({initialWdData, image_source = 'bucket', report_id = null}) {
    const [wdData, setWdData] = useState({})
    const [showPaths, setShowPaths] = useState({})

    const [existingWindfarms, setExistingWindfarms] = useState({});
    const [existingWDTurbines, setExistingWDTurbines] = useState([]);

    const [selectedTurbines, setSelectedTurbines] = useState({});

    const [existingDefects, setExistingDefects] = useState({})
    const [defectStatus, setDefectStatus] = useState({});

    const [newTurbineInspectionDate, setNewTurbineInspectionDate] = useState('')

    const [creatingDefectsOnTurbines, setCreatingDefectsOnTurbines] = useState({});

    useEffect(() => {
        windfarmAPI.listAllWindfarms().then(awfs => {
            let keyedWfs = {}
            awfs.forEach(windfarm => {
                keyedWfs[windfarm.name] = windfarm
            })
            setExistingWindfarms(keyedWfs)
        })
    }, []);

    const fetchTurbinesForWindfarm = useCallback((windfarm_id) => {
        if (existingWDTurbines.length > 0) return;
        return windfarmAPI.listTurbines(windfarm_id).then(turbines => {
            setExistingWDTurbines(turbines)
            console.log('TURBINES')
            console.log(turbines)

            turbines.forEach(turbine => {
                defectAPI.getTurbineDefects(turbine.id).then(defects => {
                    console.log('Got defects', {defects})
                    setExistingDefects(e => ({
                        ...e,
                        ...defects.reduce((p, def) => ({...p, [def.reportDefectId]: true}), {})
                    }))
                    const deps = defects.reduce((p, def) => ({...p, [def.reportDefectId]: 'DEPLOYED'}), {})
                    setDefectStatus(e => ({
                        ...e,
                        ...deps
                    }))
                })
            })
        })
    }, []);

    const createWindfarm = useCallback((wf_key, name, customer) => {
        windfarmAPI.createWindfarm(name, customer, report_id).then(new_id => {
            setExistingWindfarms(e => ({
                ...e,
                [wf_key]: {
                    id: new_id,
                    name,
                    customer
                }
            }));
            return fetchTurbinesForWindfarm(new_id)
        })
    }, [fetchTurbinesForWindfarm, report_id]);

    const createTurbine = useCallback((windfarm, turbine, inspection_date) => {
        console.log('Creating turbine:', {
            ...turbine,
            turbine_name: turbine.name,
            windfarm_id: windfarm.id,
            blade_length: turbine.blade_length,
            valid_to: null,
            inspection_date,
        });
        turbineAPI.createTurbine({
            ...turbine,
            turbine_name: turbine.name,
            windfarm_id: windfarm.id,
            blade_length: turbine.blade_length,
            valid_to: null,
            inspection_date,
        }).then(new_id => {
            console.log('Created new turbine', new_id)
            setExistingWDTurbines(e => ([
                ...e,
                {
                    id: new_id,
                    ...turbine,
                    turbine_name: turbine.name,
                    windfarm_id: windfarm.id,
                    blade_length: turbine.blade_length,
                    valid_to: '',
                    defect_count: 0,
                    inspection_date,
                }
            ]))
        })
    }, []);

    const createDefectNew = useCallback((turbine_id, defect, callback, override_existing = false) => {
        if (!override_existing && existingDefects[defect.report_defect_id] !== undefined) {
            callback()
            return;
        }
        let request = {
            defect: {
                ...defect,
                defect_type: defect.type
            },
            turbine_id: turbine_id,
            image_source: image_source
        }
        if (image_source === 'local') {
            request.defect_image_data = defect.small_path
            request.blade_image_data = defect.large_path
        }
        setDefectStatus(e => ({
            ...e,
            [defect.report_defect_id]: 'loading...'
        }))

        console.log('CREATING DEFECT', request)
        defectAPI.createDefect(request).then(def_id => {
            setDefectStatus(e => ({
                ...e,
                [defect.report_defect_id]: 'DEPLOYED'
            }));
            callback()
        })
    }, [image_source, existingDefects, setDefectStatus]);

    const createTurbineDefects = useCallback((wf_key, turbine) => {
        const selectedTurbine = selectedTurbines[turbine.name]
        if (selectedTurbine === undefined) return;

        const shared_key = `${wf_key}-${turbine.id}`
        setCreatingDefectsOnTurbines(prev => ({...prev, [shared_key]: true}))
        return asyncFunc.mapLimit(turbine.defects, 5, (defect, callback) => {
            createDefectNew(selectedTurbine.id, defect, callback)
        }).then(_ => setCreatingDefectsOnTurbines(prev => ({...prev, [shared_key]: false})))
    }, [createDefectNew, selectedTurbines]);

    const renderValidation = useCallback((field, value) => {
        let valid = true
        if (field === 'severity' && (value < 1 || value > 5)) {
            valid = false
        }
        if (field === 'distance' && (isNaN(parseInt(value)) || parseInt(value) < 0)) {
            valid = false
        }

        if (valid) return null;

        return <img style={{height: '16px'}} src={exclamationMark} alt='Not valid'/>
    }, []);

    const renderTurbine = useCallback((wf_key, goose_turbine) => {

    }, [newTurbineInspectionDate, createTurbine, existingWindfarms, selectedTurbines, createDefectNew, defectStatus, renderValidation, showPaths, existingWDTurbines, createTurbineDefects])

    useEffect(() => {
        if (!initialWdData) return;
        console.log('Setting initial wd data', initialWdData);
        console.log('Existing wf', existingWindfarms)
        setWdData(initialWdData)

    }, [initialWdData, existingWindfarms]);

    useEffect(() => {
        if (!initialWdData || Object.keys(existingWindfarms).length === 0) return;
        const windfarmName = Object.keys(initialWdData)[0]
        if (!(windfarmName in existingWindfarms)) return;

        fetchTurbinesForWindfarm(existingWindfarms[windfarmName].id)
    }, [fetchTurbinesForWindfarm, existingWindfarms, initialWdData])

    return (<div>
        <div className='display-result'>
            {Object.keys(wdData).map(wf_key => {
                const {
                    customer,
                    name: windfarm_name
                } = wdData[wf_key]

                const existing_windfarm = existingWindfarms[wf_key]
                if (existing_windfarm) {
                    return (<div className='wf-wrapper' key={wf_key}>
                        <h2>Adding to existing windfarm</h2>
                        <label><b>Name:</b> {existing_windfarm.name}</label>
                        <label><b>Customer:</b> {existing_windfarm.customer}</label>
                    </div>)
                } else {
                    return (<div className='wf-wrapper' key={wf_key}>
                        <h2>Creating a new windfarm</h2>
                        <label><b>Name:</b> {windfarm_name}</label>
                        <label><b>Customer:</b> {customer}</label>
                        <WplButton onClick={() => createWindfarm(wf_key, windfarm_name, customer)}
                                   value='Accept and create WF'/>
                    </div>)
                }
            })}
            {Object.keys(wdData).length > 0 && Object.keys(wdData).map((wf_key) => {
                if (existingWindfarms[wf_key] === undefined) return null;
                const turbines = wdData[wf_key].turbines
                return (<div className='windfarm-section' key={wf_key}>
                    {Object.keys(turbines).map(turbine_key => {
                        const goose_turbine = turbines[turbine_key]
                        const selectedTurbine = selectedTurbines[goose_turbine.name]

                        return <div key={`${wf_key}-${goose_turbine.name}-yes`}>
                            <h2>{goose_turbine.name}</h2>
                            <div className='turbine-selection'>
                                <WplInput
                                    onlySelectableOptions
                                    title='Select existing turbine'
                                    options={existingWDTurbines.filter(e => e.name === goose_turbine.name).map(e => `${e.name} - ${toPrettyDateStr(new Date(e.inspection_date))}`)}
                                    value={selectedTurbine ? `${selectedTurbine.name} - ${toPrettyDateStr(new Date(selectedTurbine.inspection_date))}` : ''}
                                    onChanged={n => setSelectedTurbines(prev => ({
                                        ...prev,
                                        [goose_turbine.name]: existingWDTurbines.find(e => `${e.name} - ${toPrettyDateStr(new Date(e.inspection_date))}` === n)
                                    }))}
                                />
                                <ClickToShow buttonText='Create new turbine...' className='turbine-popup'>
                                    <h2>Creating a new turbine</h2>
                                    <p>turbine name: {goose_turbine.name}</p>
                                    <p>blade_length: {goose_turbine.blade_length}</p>
                                    <WplInput title='inspection date' onChanged={setNewTurbineInspectionDate}
                                              value={newTurbineInspectionDate} type='date'/>

                                    <WplButton value='Create' onClick={_ => {
                                        createTurbine(existingWindfarms[wf_key], goose_turbine, newTurbineInspectionDate)
                                    }}/>
                                </ClickToShow>
                            </div>
                            <br/>
                            <div className='wpl-table data-management-table' key={`${wf_key}-${goose_turbine.name}`}>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Report ID</th>
                                        <th>(Goose) Turbine</th>
                                        <th>Blade</th>
                                        <th>Surface</th>
                                        <th>Layer</th>
                                        <th>Type</th>
                                        <th>Dist.</th>
                                        <th>Sev.</th>
                                        <th title='Marked for repair'>MFR</th>
                                        <th>Repair step</th>
                                        <th></th>
                                        <th>Defect img</th>
                                        <th>Blade img</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {goose_turbine.defects
                                        .sort((a, b) => a.severity - b.severity)
                                        .map(rowDefect => {
                                            return (<tr key={rowDefect.report_defect_id}>
                                                <td>{rowDefect.report_defect_id}</td>
                                                <td>{goose_turbine.name}</td>
                                                <td>{rowDefect.blade}</td>
                                                <td>{rowDefect.surface}</td>
                                                <td>{rowDefect.layer}</td>
                                                <td>{rowDefect.type}</td>
                                                <td>{rowDefect.distance}m {renderValidation('distance', rowDefect.distance)}</td>
                                                <td>{rowDefect.severity} {renderValidation('severity', rowDefect.severity)}</td>
                                                <td>{rowDefect.marked_for_repair !== null ? 'yes' : 'no'}</td>
                                                <td>{rowDefect.repair_recommendation}</td>
                                                <td><WplButton
                                                    disabled={selectedTurbine === undefined || defectStatus[rowDefect.report_defect_id]}
                                                    value='create'
                                                    onClick={_ => createDefectNew(selectedTurbine.id, rowDefect, () => {
                                                    }, true)}/></td>
                                                <td><WplButton
                                                    value={showPaths[rowDefect.small_path] ? 'Hide' : 'Show images'}
                                                    onClick={() => setShowPaths(e => ({
                                                        ...e,
                                                        [rowDefect.small_path]: !e[rowDefect.small_path]
                                                    }))}/></td>
                                                {showPaths[rowDefect.small_path] && <>
                                                    <td><img className='preview-image'
                                                             src={rowDefect.small_path}
                                                             alt='MISSING'/>
                                                    </td>
                                                    <td>
                                                        <img className='preview-image'
                                                             src={rowDefect.large_path}
                                                             alt='MISSING'/>
                                                    </td>
                                                </>
                                                }
                                                <td>
                                                    {defectStatus[rowDefect.report_defect_id]}
                                                </td>
                                            </tr>)
                                        })}
                                    <tr>
                                        <td colSpan={10}>
                                            {selectedTurbine && <WplButton
                                                loading={creatingDefectsOnTurbines[`${wf_key}-${selectedTurbine.id}`]}
                                                value={'Deploy defects to live'}
                                                onClick={() => createTurbineDefects(wf_key, goose_turbine)}/>}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    })}
                </div>)
            })}
        </div>
    </div>)
}
