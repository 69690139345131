import React, {useCallback, useMemo, useState} from 'react'
import './show-less-more.css'

export default function ShowLessMore({showMore, setShowMore, className, children = null}) {
    const [localShowMore, setLocalShowMore] = useState(false);

    const _showMore = useMemo(() => {
        if (setShowMore) return showMore;
        return localShowMore
    }, [showMore, setShowMore, localShowMore]);

    const _setShowMore = useCallback(v => {
        if (setShowMore) return setShowMore(v)
        return setLocalShowMore(v)
    }, [setShowMore, setLocalShowMore]);

    return (<>
        {_showMore && children}
        <div className={`show-less-more-wrapper ${className}`}>
            <div/>
            {<span className='show-less-more'
                   onClick={() => _setShowMore(!_showMore)}>Show {_showMore ? 'less -' : 'more +'}</span>}
        </div>
    </>)
}
