import React, {useCallback, useEffect, useState} from 'react'
import {segmentationAPI} from "../../../api";
import WplInput from "../../../components/wpl-input/WplInput";
import WplButton from "../../../components/wpl-button/WplButton";

export default function DmSegmentationMask(props) {
    const [rfModels, setRfModels] = useState([]);
    const [selectedRfModel, setSelectedRfModel] = useState('');
    const [pictureId, setPictureId] = useState('');

    const [maskUrl, setMaskUrl] = useState('');

    useEffect(() => {
        segmentationAPI.listRfModels().then(setRfModels)
    }, []);

    const getMask = useCallback(() => {
        setMaskUrl(`http://localhost:5000/segmentation/generate-background-mask?model_id=${selectedRfModel}&picture_id=${pictureId}`)
    }, [selectedRfModel, pictureId]);

    return (<div className='dm-mask'>
        <WplInput onlySelectableOptions={true} options={rfModels.map(rf => rf.id)} onChanged={setSelectedRfModel} value={selectedRfModel} title='RF Model' />
        <WplInput onChanged={setPictureId} value={pictureId} title='Picture ID' />
        <WplButton value='Fetch mask' onClick={getMask} />
        <img src={maskUrl} alt='mask' />
    </div>)
}
