import React, {useState, useEffect, useCallback} from 'react'
import SimpleTable from "../../components/simple-table/SimpleTable";
import WplInput from "../../components/wpl-input/WplInput";
import WplButton from "../../components/wpl-button/WplButton";
import {datamanagementAPI} from "../../api";
import './parsed-object.css'

export default function ParsedObject({parsedObject, setParsedObject, existingPictures=[]}) {
    const [bladeFilter, setBladeFilter] = useState('')
    const [surfaceFilter, setSurfaceFilter] = useState('')

    const [showOnlyBadPictures, setShowOnlyBadPictures] = useState(false);
    const [displayPictures, setDisplayPictures] = useState(false);

    const [filteredPictures, setFilteredPictures] = useState([])
    const [allPictures, setAllPictures] = useState([]);

    const [commitLoading, setCommitLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(parsedObject).length === 0) return;
        const pictures = [];
        parsedObject.blades.forEach(blade => {
            blade.surfaces.forEach(surface => {
                surface.images.forEach(image => {
                    pictures.push({
                        blade: blade.name,
                        surface: surface.surface,
                        distance: image.distance,
                        pixelMm: image.pixel_mm,
                        file_name: image.file_name,
                        signed_url: image.signed_url,
                        image_exists: image.image_exists,
                        cr2_exists: image.cr2_exists,
                        bucket_path: image.bucket_path
                    })
                })
            })
        })

        setAllPictures(pictures);
    }, [parsedObject]);

    useEffect(() => {
        const fp = (allPictures
                .filter(p => !bladeFilter || p.blade === bladeFilter)
                .filter(p => !surfaceFilter || p.surface === surfaceFilter)
        )
        setFilteredPictures(fp)
    }, [allPictures, bladeFilter, surfaceFilter])

    const commitParsedObject = useCallback(() => {
        setCommitLoading(true)
        datamanagementAPI.commitCornis(parsedObject).then(_ => {
            setCommitLoading(false);
        })
    }, [parsedObject])

    return (<div className='parsed-object-page'>
        {Object.keys(parsedObject).length !== 0 && <>
            <SimpleTable
                headers={['Customer', 'Inspection date', 'Rotor diameter', 'Windfarm', 'Turbine', 'Blade Count']}
                rows={[parsedObject]}
                renderRow={po => <tr key='parsed-object'>
                    <td><WplInput value={po.customer} onChanged={tn => setParsedObject(prev => ({...prev, customer: tn}))} /></td>
                    <td><WplInput value={po.inspection_date} onChanged={tn => setParsedObject(prev => ({...prev, inspection_date: tn}))} /></td>
                    <td><WplInput value={po.rotor_diameter} onChanged={tn => setParsedObject(prev => ({...prev, rotor_diameter: tn}))} /></td>
                    <td><WplInput value={po.windfarm} onChanged={tn => setParsedObject(prev => ({...prev, windfarm: tn}))} /></td>
                    <td><WplInput value={po.turbine_name}
                                  onChanged={tn => setParsedObject(prev => ({...prev, turbine_name: tn}))}/></td>
                    <td>{po.blades.length}</td>
                </tr>}
            />
            <hr/>
            {Object.keys(parsedObject).length > 0 && <>
                <div className='parsed-filters'>
                    {<WplButton value='x' className='clear-btn' onClick={_ => {
                        setSurfaceFilter('')
                        setBladeFilter('')
                    }}/>}
                    <WplInput
                        title='Blade'
                        value={bladeFilter}
                        options={parsedObject.blades.map(b => b.name)}
                        onChanged={setBladeFilter}
                        onlySelectableOptions
                    />
                    {bladeFilter && <WplInput
                        title='Surface'
                        value={surfaceFilter}
                        onChanged={setSurfaceFilter}
                        options={parsedObject.blades.find(b => b.name === bladeFilter).surfaces.map(s => s.surface)}
                        onlySelectableOptions
                    />}

                    {(bladeFilter || surfaceFilter) && <div className='edit-object-wrapper'>
                        <b>Edit the object you are about to import</b>
                        {bladeFilter && <WplButton big value={`Delete BLADE ${bladeFilter}`} onClick={_ => {
                            if (!window.confirm(`Delete BLADE ${bladeFilter} from import-object?`)) return;

                            setSurfaceFilter('')
                            setBladeFilter('')
                            setParsedObject(prev => ({
                                ...prev,
                                blades: prev.blades.filter(blade => blade.name !== bladeFilter)
                            }))
                        }}/>}
                        {bladeFilter && surfaceFilter &&
                        <WplButton big value={`Delete SURFACE ${surfaceFilter}`} onClick={_ => {
                            setSurfaceFilter('')
                            setBladeFilter('')
                            setParsedObject(prev => ({
                                ...prev,
                                blades: prev.blades.map(blade => {
                                    if (blade.name !== bladeFilter) return blade

                                    return {
                                        ...blade,
                                        surfaces: blade.surfaces.filter(surface => surface.surface !== surfaceFilter)
                                    }
                                })
                            }))
                        }}/>}
                    </div>}
                </div>
                <div className='checkbox-wrap'>
                    <label>Display pictures (only shows pictures after selecting blade and surface)</label>
                    <input type='checkbox' checked={displayPictures}
                           onChange={e => setDisplayPictures(e.target.checked)}/>
                </div>
                <div className='checkbox-wrap'>
                    <label>Show only bad pictures</label>
                    <input type='checkbox' checked={showOnlyBadPictures}
                           onChange={e => setShowOnlyBadPictures(e.target.checked)}/>
                </div>
            </>}

            <hr/>
            {filteredPictures.length > 0 && <div>
                <SimpleTable headers={[
                    'Blade',
                    'Surface',
                    'Distance',
                    'PixelMM',
                    'Filename',
                    'Photo'
                ]} renderRow={pic => {
                    if (showOnlyBadPictures && pic.image_exists) return;

                    return <tr key={pic.file_name} className={pic.image_exists ? '' : 'bad-row'}>
                        <td>{pic.blade}</td>
                        <td>{pic.surface}</td>
                        <td>{pic.distance.toFixed(2)}</td>
                        <td>{pic.pixelMm.toFixed(2)}</td>
                        <td>{pic.file_name}</td>
                        <td>{pic.image_exists ? '' : 'Image not found'}</td>
                        {!pic.image_exists && !pic.cr2_exists && <td>CR2 not found</td>}
                        {!pic.image_exists && pic.cr2_exists &&
                        <td><WplButton
                            value='Convert from CR2 to JPG'
                            onClick={() => datamanagementAPI.convertCr2ToJpg(pic.bucket_path)}/></td>
                        }
                        {displayPictures && bladeFilter !== '' && surfaceFilter !== '' && pic.image_exists &&
                        <td><img style={{maxWidth: '256px'}} src={pic.signed_url} alt={'img'}/></td>}
                        <td>{existingPictures.find(p => p.bucket_path === pic.bucket_path) ? 'A picture with this exact bucket_path already exists in the DB' : ''}</td>
                    </tr>
                }} rows={filteredPictures}/>
                <WplButton loading={commitLoading} value='commit to database' onClick={commitParsedObject}/>
            </div>}

        </>}
    </div>)
}
