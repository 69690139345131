import React from 'react'
import {Link} from "react-router-dom";
import './admin-header.css'

export default function AdminHeader() {
    return (<div className={'admin-header'}>
        <Link to='/admin'>WD user handling</Link>
        <Link to='/admin/data'>WD data</Link>
        <div className='vert-line' />
        <Link to='/admin/lightning'>Lightning user subscription</Link>
        <Link to='/admin/lightning/data'>Lightning data</Link>
    </div>)
}
