import React, {useState, useEffect} from 'react'
import WplPopup from "../wpl-popup-window/WplPopup";
import WplTable from "../wpl-table/WplTable";
import './defect-list-popup.css'

export default function DefectListPopup({defects, isOpen=true, onClose, title}) {
    const [focusedDefect, setFocusedDefect] = useState('');

    function doClose() {
        setFocusedDefect('');
        onClose()
    }

    useEffect(() => {
        if (isOpen && defects.length === 1 && !focusedDefect) {
            setFocusedDefect(defects[0])
        }
    }, [isOpen, defects, focusedDefect]);

    if (!isOpen) return null;

    return (
        <WplPopup className='distance-list-popup' showPopup={isOpen}
                  closePopup={doClose}>
            {title}
            <WplTable defects={defects} selectedDefect={focusedDefect} setSelectedDefect={setFocusedDefect} inlineSelectable={true} />
        </WplPopup>)
}
