import {http} from "../api";

const easyUnpack = ({data}) => data;
export const DocumentApi = {
    patch_document: {
        f: function patch_document(document_id, delete_document) {
            let url = '/wd/documents/$document_id';
            let parameterNames = ["$document_id"];
            [document_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.patch(url, {delete_document}).then(easyUnpack)
        }
    },
    prepare_upload: {
        f: function prepare_upload(filename, windfarm_id, {turbine_id = null}) {
            let url = '/wd/documents/upload/prepare';
            return http.post(url, {filename, windfarm_id, turbine_id}).then(easyUnpack)
        }
    },
    register_upload: {
        f: function register_upload(filename, bucket_path, {windfarm_id = null, turbine_id = null}) {
            let url = '/wd/documents/upload/done';
            return http.post(url, {filename, bucket_path, windfarm_id, turbine_id}).then(easyUnpack)
        }
    },
    upload_document: {
        f: function upload_document(filename, file_data, {windfarm_id = null, turbine_id = null}) {
            let url = '/wd/documents/upload';
            return http.post(url, {filename, file_data, windfarm_id, turbine_id}).then(easyUnpack)
        }
    },
    download_document: {
        f: function download_document(document_id) {
            let url = '/wd/documents/download/$document_id';
            let parameterNames = ["$document_id"];
            [document_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    get_windfarm_documents: {
        f: function get_windfarm_documents(windfarm_id) {
            let url = '/wd/documents/windfarm/$windfarm_id';
            let parameterNames = ["$windfarm_id"];
            [windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    get_turbine_documents: {
        f: function get_turbine_documents(turbine_id) {
            let url = '/wd/documents/turbine/$turbine_id';
            let parameterNames = ["$turbine_id"];
            [turbine_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
};
