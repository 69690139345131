import React, {useCallback, useState} from 'react'
import './reset-pw-page.css'
import WplFullpageForm from "../../components/fullpage-form/WplFullpageForm";
import WplInput from "../../components/wpl-input/WplInput";
import WplButton from "../../components/wpl-button/WplButton";
import {useNavigate, useParams} from "react-router-dom";
import {userAPI} from "../../api";
import {setAuthToken} from "../../auth/user-service";

export default function ResetPasswordPage(props) {
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [error, setError] = useState('')
    const [authenticated, setAuthenticated] = useState(false)

    let {token} = useParams();
    const navigate = useNavigate()

    const submit = useCallback(() => {
        if (password !== rePassword) return;
        setError('');
        userAPI.resetPassword(token, password).then(t => {
            setAuthToken(t);
            setAuthenticated(true)
        }).catch(e => {
            setError(e)
        })
    }, [token, password, rePassword, setError, setAuthenticated]);

    if (!token) {
        return <div className='error-wrapper'><h4>No token. Close this page and open the link from your email again.</h4></div>
    }

    if (authenticated) {
        navigate('/')
        return null;
    }

    return (<WplFullpageForm subtitle='Update password'>
        <WplInput title='Password' type='password' value={password} onChanged={setPassword}/>
        <WplInput title='Re-type password' type='password' value={rePassword} onChanged={setRePassword} error={error}/>

        <WplButton value='UPDATE' onClick={submit} />
    </WplFullpageForm>)
}
