import {http} from "../api";

const easyUnpack = ({data}) => data;
export const WdTurbineApi = {
    create_turbine: {
        f: function create_turbine(windfarm_id, rdspp, turbine_name, blade_length, inspection_date, {valid_to = null}) {
            let url = '/wd/turbines';
            return http.post(url, {
                windfarm_id,
                rdspp,
                turbine_name,
                blade_length,
                inspection_date,
                valid_to
            }).then(easyUnpack)
        },
    },
};
