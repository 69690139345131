import React, {useCallback, useMemo, useState} from "react";
import WplButton from "../../../components/wpl-button/WplButton";
import CenteredPopup from "../../../components/centered-popup/CenteredPopup";
import './list-of-turbines.css'
import WplInput from "../../../components/wpl-input/WplInput";
import TurbineListFriendly from "../turbine-list-friendly/TurbineListFriendly";
import {useNavigate} from "react-router-dom";

export default function ListOfTurbines({PopupComponent, title, buttonText, turbines, windfarm_id = null, hideRisk=true}) {
    const [manageRiskOpen, setManageRiskOpen] = useState(false);
    const [firstClickedTurbine, setFirstClickedTurbine] = useState(null);
    const [searchText, setSearchText] = useState('');

    const filteredTurbines = useMemo(_ => turbines.filter(t => !searchText || t.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0), [turbines, searchText]);

    const navigate = useNavigate()

    return (<div className='list-of-turbines'>
        <CenteredPopup className='turbine-risk-popup' showPopup={manageRiskOpen} closePopup={setManageRiskOpen}>
            <PopupComponent
                collectionTitle={title}
                windfarm_id={windfarm_id}
                firstClickedTurbine={firstClickedTurbine}
                turbines={turbines}/>
        </CenteredPopup>
        <h2>{title}</h2>

        <WplInput placeholder='Search...' value={searchText} onChanged={setSearchText} clearable/>

        <div className='turbine-risk-scroller'>
            <div className='turbine-risks'>
                {!turbines.length && <p>
                    Nothing to see here - have a great day!
                </p>}
                {filteredTurbines && [...filteredTurbines]
                    .filter(t => !windfarm_id || t.windfarm_id === windfarm_id)
                    .sort((a, b) => b.probability_of_atleast_one - a.probability_of_atleast_one)
                    .map(tr => <TurbineListFriendly hideRisk={hideRisk} tr={tr} key={tr.id} onClick={_ => {
                        navigate(`/lassie/windfarm/${tr.windfarm_id}/turbine/${tr.id}`)
                    }} />)}
            </div>
        </div>
        <WplButton disabled={!filteredTurbines.length}
                   className='manage-risk-btn'
                   value={buttonText}
                   big onClick={_ => setManageRiskOpen(true)}/>
    </div>)
}
