import React, {useEffect, useState} from 'react';
import './lightning-scenario-settings.css'
import {useDispatch, useSelector} from "react-redux";
import {
    doCreateScenario,
    doFetchScenarios,
    listLightningWindfarms,
    selectLightningWindfarms,
    selectScenarios
} from "../../../flow/lightningFlow";
import WplInput from "../../../components/wpl-input/WplInput";
import Scenario from "./scenario/Scenario";
import CenteredPopup from "../../../components/centered-popup/CenteredPopup";
import WplButton from "../../../components/wpl-button/WplButton";

export default function LightningScenarioSettings({specific_windfarm_id, can_edit=true}) {
    const dispatch = useDispatch()

    const [creatingNewScenario, setCreatingNewScenario] = useState(false);
    const [title, setTitle] = useState('');
    const [immediate, setImmediate] = useState('');
    const [description, setDescription] = useState('');
    const [windfarmId, setWindfarmId] = useState(null);

    const windfarms = useSelector(selectLightningWindfarms)

    const [selectedWindfarm, setSelectedWindfarm] = useState(null);
    const scenarios = useSelector(selectScenarios)

    useEffect(() => {
        dispatch(listLightningWindfarms())
    }, [dispatch]);

    useEffect(() => {
        if (!windfarms || selectedWindfarm) return;
        if (specific_windfarm_id) {
            setSelectedWindfarm(windfarms.find(wf => wf.id === specific_windfarm_id))
            return;
        }
        setSelectedWindfarm(windfarms[0])
    }, [windfarms, selectedWindfarm]);

    useEffect(() => {
        if (!selectedWindfarm) return;
        dispatch(doFetchScenarios(selectedWindfarm.id))
    }, [dispatch, selectedWindfarm]);


    return (<div className='lightning-scenario-settings'>
        <CenteredPopup showPopup={creatingNewScenario} closePopup={setCreatingNewScenario}>
            <WplInput title='title' value={title} onChanged={setTitle} />
            <WplInput title='immediate' value={immediate} onChanged={setImmediate} />
            <WplInput title='description' value={description} onChanged={setDescription} />
            <WplInput title='windfarmId'
                      value={windfarmId}
                      onChanged={name => setWindfarmId(windfarms.find(wf => wf.name === name).id)}
                      options={windfarms.map(wf => wf.name)} onlySelectableOptions />
            <WplButton value='Submit' onClick={_ => {
                dispatch(doCreateScenario({
                    title,
                    immediate_action: immediate,
                    description,
                    windfarm_id: windfarmId
                }))
                setCreatingNewScenario(false)
                setTitle('')
                setImmediate('')
                setDescription('')
                setWindfarmId(null)
            }} />
        </CenteredPopup>
        <h2>Scenarios</h2>
        {!specific_windfarm_id && windfarms && <WplInput options={windfarms.map(wf => wf.name)}
                                title='Windfarm'
                                onlySelectableOptions
                                value={selectedWindfarm ? selectedWindfarm.name : ''}
                                onChanged={n => setSelectedWindfarm(windfarms.find(wf => wf.name === n))}/>}
        {can_edit && <WplButton value='New Scenario' onClick={_ => setCreatingNewScenario(true)} big/>}
        {scenarios.map(scenario => <Scenario key={scenario.id} scenario={scenario} can_edit={can_edit} />)}
    </div>)
}
