import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {lightningAPI} from "../../../api";
import './lassie-region-list.css'
import CenteredPopup from "../../../components/centered-popup/CenteredPopup";
import DataCoverageTimeline from "../data-coverage-timeline/DataCoverageTimeline";
import {toPrettyDateStr, toPrettyTimeSince, toPrettyTimeUntil} from "../../../prettyDate";
import WplInput from "../../../components/wpl-input/WplInput";
import RegionDataOverview from "../region-data-overview/RegionDataOverview";
import {useNavigate} from "react-router-dom";
import {heavyLassieRegionApi} from "../../../heavy_duty_api";

export default function LassieRegionList() {
    const [regions, setRegions] = useState([]);
    const [loadingRegions, setLoadingRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [autoUpdate, setAutoUpdate] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate();

    const doGetRegions = useCallback(region_ids => {
        heavyLassieRegionApi.listRegions(region_ids).then(rs => {
            setLoadingRegions(prev => prev.filter(r => !rs.map(r => r.id).includes(r)))
            const final = rs.map(region => {
                const coords = region.polygon
                    .substring(9, region.polygon.length - 2)
                    .split(',')
                    .map(s => s.split(' ').map(parseFloat).reverse())

                const latTot = coords.reduce((prev, curr) => prev + curr[1], 0)
                const lonTot = coords.reduce((prev, curr) => prev + curr[0], 0)
                const count = coords.length
                const center = [Math.floor(latTot / count * 10000) / 10000, Math.floor(lonTot / count * 10000) / 10000]

                return {
                    ...region,
                    center,
                    polygon_coordinates: coords
                }
            })
            setRegions(final)
        });
    }, []);

    useEffect(() => {
        doGetRegions(null)
    }, [doGetRegions]);

    // rotation handling
    useEffect(() => {
        if (!autoUpdate) return;

        const interval = setInterval(() => {
            // setCurrentRotation(prev => prev + rotationSpeed)
            const nextPlannedBehindUs = regions.filter(r => r.next_planned_execution && new Date(r.next_planned_execution) < new Date()).map(r => r.id)
            if (nextPlannedBehindUs.length) {
                console.log('nextPlannedBehindUs', nextPlannedBehindUs)
                doGetRegions(nextPlannedBehindUs)
                setLoadingRegions(prev => [...prev, ...nextPlannedBehindUs])
            }
        }, 5 * 1000);

        return () => clearInterval(interval);
    }, [autoUpdate]);

    useEffect(() => {
        if (!selectedRegion) return;
        if (selectedRegion.turbines) return;

        lightningAPI.listTurbinesByRegion(selectedRegion.id).then(turbines => setSelectedRegion(prev => ({
            ...prev,
            turbines
        })))
    }, [selectedRegion]);

    const byDataSources = useMemo(() => {
        const byDataSources = {}
        regions.forEach(r => {
            if (!byDataSources[r.data_source]) byDataSources[r.data_source] = []
            byDataSources[r.data_source].push(r)
        })
        return byDataSources
    }, [regions]);

    return (<div className='lassie-region-list'>
        {selectedRegion &&
            <CenteredPopup showPopup={true} closePopup={_ => setSelectedRegion(null)} className='selected-region-popup'>
                {selectedRegion &&
                    <RegionDataOverview selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion}/>}
            </CenteredPopup>
        }
        <WplInput value={searchQuery} onChanged={setSearchQuery} className='search-bar' placeholder='Search..' big/>
        <div className='auto-update-wrapper'>
            <input type='checkbox' id='auto-update' checked={autoUpdate}
                   onChange={e => setAutoUpdate(e.target.checked)}/>
            <label htmlFor={'auto-update'}
                   title='Automatically updates regions every 5 seconds if "next planned" is behind us time-wise.'>Auto
                update</label>
            <input type='button' value='Update now' onClick={_ => {
                setLoadingRegions(prev => [...prev, ...regions.map(r => r.id)]);
                doGetRegions(null);
            }}/>
        </div>
        <table className='regions-table'>
            <thead>
            <th>Region</th>
            <th>Data</th>
            <th>Processed</th>
            <th>Last succesful execution</th>
            <th>Next planned</th>
            <th>Process id</th>
            </thead>
            <tbody>
            {Object.keys(byDataSources)
                .map(dataSource => {
                    const regions = byDataSources[dataSource]
                    return [
                        <tr>
                            <td colSpan={6} style={{textAlign: 'center'}}><b>{dataSource}</b></td>
                        </tr>,
                        ...regions
                            .filter(r => !searchQuery || r.name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0)
                            .map(region => {
                                    const last_successful = new Date(region.last_successful_execution)
                                    const next_planned = new Date(region.next_planned_execution)
                                    return <tr onClick={_ => navigate(`/admin/lightning/data/region/${region.id}`)} className='region'
                                               key={region.name}>
                                        <td>{region.name}</td>
                                        <td className='timeline'>
                                            <DataCoverageTimeline coverage={region.regional_query_coverage}/>
                                        </td>
                                        <td className='timeline'>
                                            <DataCoverageTimeline coverage={region.turbine_lightning_coverage}/>
                                        </td>
                                        <td title={toPrettyDateStr(last_successful, true)}>{toPrettyTimeSince(last_successful)}</td>
                                        <td title={toPrettyDateStr(next_planned, true)}>{toPrettyTimeUntil(next_planned)}</td>
                                        <td>{region.worker_process_id}</td>
                                        {loadingRegions.includes(region.id) ? <td>loading</td> : null}
                                    </tr>
                                }
                            )
                    ]
                })}
            </tbody>
        </table>
    </div>)
}
