import React from 'react'
import './wpl-form.css'
import {useKeyboardShortcut} from "../UseKeyboardShortcut";

export default function WplForm({children, doSubmit, className}) {
    useKeyboardShortcut(['z'], () => {}, doSubmit);
    return (<div className={`wpl-form ${className}`}>
        {children}
    </div>)
}
