
export function toPrettyDateStr(date, withHours=false, withMilliseconds = false) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    let timestamp = ' ' + [date.getHours(), date.getMinutes(), date.getSeconds()].map(k => k.toString().padStart(2, '0')).join(':')
    let millisecond = date.getMilliseconds().toString().padStart(3, '0')
    return `${date.getFullYear()}-${month}-${day}${withHours ? timestamp : ''}${withMilliseconds ? '.' + millisecond : ''}`
}

export function toPrettyTimeSince(date, comparedTo=null) {
    if (isNaN(date)) {
        return 'Never'
    }
    comparedTo = comparedTo ? comparedTo : new Date()
    const diff = (comparedTo - date)/1000

    if (diff < 0) {
        return toPrettyTimeUntil(date, comparedTo)
    }

    const secondsPrHour = 60*60
    const secondsPrDay = 24 * secondsPrHour
    const daysPrMonth = 28.5

    if (diff < secondsPrHour) {
        return `${Math.round(diff/60)} minutes ago`
    }
    if (diff < secondsPrDay) {
        const hours = Math.round(diff/secondsPrHour)
        return `${hours} hour${hours === 1 ? '' : 's'} ago`
    }
    const days = Math.round(diff/secondsPrDay)
    if (days === 1) {
        return 'yesterday'
    }

    if (days > 365) {
        const yrs = Math.round(days/365)
        return `${yrs} year${yrs === 1 ? '' : 's'} ago`
    }

    if (days > 28) {
        const months = Math.round(days/daysPrMonth)
        return `${months} month${months === 1 ? '' : 's'} ago`
    }

    return `${days} day${days === 1 ? '' : 's'} ago`
}

export function toPrettyTimeUntil(date, comparedTo=null) {
    if (isNaN(date)) {
        return 'Never'
    }
    comparedTo = comparedTo ? comparedTo : new Date().getTime()
    const diff = (date - comparedTo)/1000

    if (diff < 0) return toPrettyTimeSince(date)

    const secondsPrHour = 60*60
    const secondsPrDay = 24 * secondsPrHour
    const daysPrMonth = 28.5

    const minutes = Math.round(diff/60)

    if (diff < secondsPrHour) {
        return `In ${minutes} minute${minutes === 1 ? '' : 's'}`
    }
    if (diff < secondsPrDay) {
        const hours = Math.round(diff/secondsPrHour)
        return `In ${hours} hour${hours === 1 ? '' : 's'}`
    }
    const days = Math.round(diff/secondsPrDay)
    if (days === 1) {
        return 'tomorrow'
    }

    if (days > 365) {
        const yrs = Math.round(days/365)
        return `In ${yrs} year${yrs === 1 ? '' : 's'}`
    }

    if (days > 28) {
        const months = Math.round(days/daysPrMonth)
        return `In ${months} month${months === 1 ? '' : 's'}`
    }

    return `In ${days} day${days === 1 ? '' : 's'}`
}

export function parseStandardWplDate(date) {
    if (!date) return null;
    // parses format: %Y-%m-%dT%H:%M:%SZ
    const [datePart, timePart] = date.split('T')
    const [year, month, day] = datePart.split('-')
    const [hour, minute, second] = timePart.split(':')
    if (!second) {
        console.log('ohh nooo', date, timePart)
    }
    return new Date(year, month-1, day, hour, minute, second.replace('Z', ''))
}

export function toStandardWplTimestamp(date) {
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
    const day = date.getUTCDate().toString().padStart(2, '0')
    let timestamp = [date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()].map(k => k.toString().padStart(2, '0')).join(':')
    return `${date.getFullYear()}-${month}-${day}T${timestamp}Z`
}
