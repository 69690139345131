import React, {useMemo} from 'react'
import { format } from "date-fns";
import {toPrettyDateStr} from "../../prettyDate";

export default function SimpleTable({headers, rows, renderRow, className, columns=null, data=null}) {
    const _headers = useMemo(() => {
        if (headers) return headers
        return columns.filter(c => !c.hide).map(({title}) => title)
    }, [headers, columns]);

    const _rows = useMemo(() => {
        if (rows) return rows;

        return data.map(row_data => columns.filter(c => !c.hide).map(({field, field_format}) => {
            const res = row_data[field]
            if (!field_format) return res

            if (field_format === 'date') {
                return format(new Date(res), 'yyyy-MM-dd')
            } else if (field_format === 'prettyDate') {
                return toPrettyDateStr(new Date(res), true)
            }

            return res
        }))
    }, [rows, columns, data]);

    return (<table className={className}>
        <thead>
        <tr>
            {_headers.map(h => <th key={h}>{h}</th>)}
        </tr>
        </thead>
        <tbody>
        {renderRow && _rows.map(renderRow)}
        {!renderRow && _rows.map(row => <tr key={row.id}>
            {row.map(r => <td>{r}</td>)}
        </tr>)}
        </tbody>
    </table>)
}
