import {http} from "../api";

const easyUnpack = ({data}) => data;
export const LassieRegionTaskmasterApi = {
    taskmaster_region_data_fetch: {
        f: function taskmaster_region_data_fetch() {
            let url = '/lassie/region-data-handler/taskmaster';
            return http.post(url, {}).then(easyUnpack)
        },
    },
    download_data: {
        f: function download_data(region_id, {dtn_token = null}) {
            let url = '/lassie/region-data-handler/region/$region_id';
            let parameterNames = ["$region_id"];
            [region_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {dtn_token}).then(easyUnpack)
        },
    },
    download_data_historic: {
        f: function download_data_historic(region_id, from_timestamp, to_timestamp) {
            let url = '/lassie/region-data-handler/region/$region_id/historic';
            let parameterNames = ["$region_id"];
            [region_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {from_timestamp, to_timestamp}).then(easyUnpack)
        },
    },
    get_logs: {
        f: function get_logs(region_id) {
            let url = '/lassie/region-data-handler/logs';
            return http.get(url, {params: {region_id}}).then(easyUnpack)
        },
    },
};
