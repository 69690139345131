import React, {useState, useEffect} from 'react'
import {datamanagementAPI} from "../../../api";
import WplButton from "../../../components/wpl-button/WplButton";
import './dm-internal.css'
import Folder from "../../../components/folder/Folder";

export default function DmInternalFileSelection() {
    const [folderStructure, setFolderStructure] = useState([])

    useEffect(() => {
        datamanagementAPI
            .getFolderStructure('', ['.json', '.MOV', '.mp4', '.txt'])
            .then(folders => {
                setFolderStructure(folders)
            })
    }, [])

    return (<div className='dm-internal'>
        <Folder hideFileTitle={true} renderFolderTitle={(name, fullName, folderClicked) => {
            return <div key={name} className='folder-title'>
                <h4 onClick={_ => folderClicked(name)}>{name}</h4>
            </div>
        }} folder={folderStructure} renderFileCallback={fileName =>
            <div>
                {fileName.indexOf('.MOV') !== -1 && <hr/>}
                <p>{fileName}</p>
                {fileName.indexOf('.MOV') !== -1 && <WplButton value='Stabilize' onClick={_ => datamanagementAPI.stabilizeVideo(fileName)} />}
            </div>} />
    </div>)
}
