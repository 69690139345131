import {http} from "../api";

const easyUnpack = ({data}) => data;
export const SegmentationApi = {
    run_for_surface: {
        f: function run_for_surface(blade, turbine, report_id, surface) {
            let url = '/segmentation/report/$report_id/turbine/$turbine/blade/$blade/surface/$surface';
            let parameterNames = ["$blade", "$turbine", "$report_id", "$surface"];
            [blade, turbine, report_id, surface].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    list_backgrounds_for_picture_ids: {
        f: function list_backgrounds_for_picture_ids(windfarm, turbine, blade) {
            let url = '/segmentation/backgrounds';
            return http.get(url, {params: {windfarm, turbine, blade}}).then(easyUnpack)
        },
    },
    list_report_status: {
        f: function list_report_status(report_id) {
            let url = '/segmentation/report/$report_id/status';
            let parameterNames = ["$report_id"];
            [report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    delete_masks: {
        f: function delete_masks(report_id, turbine, blade, surface, mask_type) {
            let url = '/segmentation/backgrounds';
            return http.delete(url, {data: {report_id, turbine, blade, surface, mask_type}}).then(easyUnpack)
        },
    },
    list_signed_urls: {
        f: function list_signed_urls(report_id, turbine, blade, surface) {
            let url = '/segmentation/signed-urls';
            return http.get(url, {params: {report_id, turbine, blade, surface}}).then(easyUnpack)
        },
    },
    set_mask_qc: {
        f: function set_mask_qc(mask_id, quality) {
            let url = '/segmentation/qc/$mask_id';
            let parameterNames = ["$mask_id"];
            [mask_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {quality}).then(easyUnpack)
        },
    },
    create_mask_feedback: {
        f: function create_mask_feedback(picture_id, polygon, label) {
            let url = '/segmentation/feedback';
            return http.post(url, {picture_id, polygon, label}).then(easyUnpack)
        },
    },
};
