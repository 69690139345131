import {http} from "../api";

const easyUnpack = ({data}) => data;
export const InternalVideoProcessingApi = {
    find_inspections: {
        f: function find_inspections(root_bucket_path) {
            let url = '/internal-video/processing/find-inspections';
            return http.post(url, {root_bucket_path}).then(easyUnpack)
        },
    },
    stitch: {
        f: function stitch(inspection_values) {
            let url = '/internal-video/processing/stitch';
            return http.post(url, {inspection_values}).then(easyUnpack)
        },
    },
    parse_log: {
        f: function parse_log(inspection_values) {
            let url = '/internal-video/processing/parse-log';
            return http.post(url, {inspection_values}).then(easyUnpack)
        },
    },
    generate_stabilization: {
        f: function generate_stabilization(inspection_values) {
            let url = '/internal-video/processing/stabilize';
            return http.post(url, {inspection_values}).then(easyUnpack)
        },
    },
    generate_3d_map: {
        f: function generate_3d_map(inspection_values) {
            let url = '/internal-video/processing/3d-map';
            return http.post(url, {inspection_values}).then(easyUnpack)
        },
    },
};
