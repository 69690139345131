import React, {useState, useEffect, useCallback, useMemo} from 'react'
import './segmentation-admin.css'
import {useStream} from 'react-fetch-streams';

export default function StreamedSurface({report_id, turbine, blade, surface, status}) {
    const [rawData, setRawData] = useState('');
    const [result, setResult] = useState([]);
    const [latestDebugMessage, setLatestDebugMessage] = useState('');

    const streamReturned = useCallback(async res => {
        const txt = await res.text()
        setRawData(prev => (prev + txt))
    }, []);

    useStream(`${process.env.REACT_APP_SEGMENTATION_URL}/segmentation/report/${report_id}/turbine/${turbine}/blade/${blade}/surface/${surface}`, {
        onNext: streamReturned
    });

    useEffect(() => {
        const parsed = JSON.parse(`{"statusses": [${rawData.substring(0, rawData.length-1)}]}`)
        setResult(parsed.statusses)
    }, [rawData]);

    const progress = useMemo(() => {
        const final = {}
        let latestMsg = ''
        result.forEach(res => {
            if (res.type !== 'progress-update') {
                latestMsg = res.message
                return;
            }
            final[res.identifier] = Math.round(res.done / res.out_of*100)
        })
        setLatestDebugMessage(latestMsg)
        return final
    }, [result]);

    return (<tr className='surface' key={surface}>
        <td>{surface}</td>
        <td className={(progress['download-images'] ?? 0) ? 'green' : 'red'}>{progress['download-images'] ?? '0'}%</td>
        <td className={(progress['generate-unet-mask'] ?? 0) ? 'green' : 'red'}>{progress['generate-unet-mask'] ?? '0'}%</td>
        <td className={(progress['training-rf'] ?? 0) ? 'green' : 'red'}>{progress['training-rf'] ?? '0'}%</td>
        <td className={(progress['generate-final-background'] ?? 0) ? 'green' : 'red'}>{progress['generate-final-background'] ?? '0'}%</td>
        <td>{latestDebugMessage}</td>
    </tr>)
}
