import React, {useCallback, useEffect, useState} from 'react'
import {pictureAPI, segmentationAPI} from "../../../api";
import WplButton from "../../../components/wpl-button/WplButton";
import WplInput from "../../../components/wpl-input/WplInput";
import SimpleTable from "../../../components/simple-table/SimpleTable";
import {heavySegmentationAPI} from "../../../heavy_duty_api";

export default function DmSegmentationRfModels(props) {
    const [allWindfarms, setAllWindfarms] = useState({});
    const [selectedRfModel, setSelectedRfModel] = useState({});
    const [reportId, setReportId] = useState('');

    const [filters, setFilters] = useState({windfarm: 'Greater Gabbard', turbine: 'I05', blade: '', surface: ''});

    const [rfStatus, setRfStatus] = useState([])

    useEffect(() => {
        segmentationAPI.listRfModelStatus(reportId).then(setRfStatus)
        pictureAPI.listUniqueFilters().then(awf => {
            let d = {}
            awf.forEach(wf => d[wf.windfarm] = wf)
            setAllWindfarms(d)
        })
    }, [reportId]);

    useEffect(() => {
        if (Object.keys(selectedRfModel).length === 0 || selectedRfModel.backgrounds) return;
        console.log('fetching backgrounds for', selectedRfModel)
        segmentationAPI.listBackgrounds(selectedRfModel).then(backgrounds => {
            setSelectedRfModel(p => ({...p, backgrounds}))
        })
    }, [selectedRfModel, setSelectedRfModel]);

    const generateRfModel = useCallback(({windfarm, turbine, blade}) => {
        heavySegmentationAPI.generateRfModel({windfarm, turbine, blade})
    }, []);

    function renderInput() {
        if (Object.keys(allWindfarms).length === 0) return;
        return <div className='unet-inp-wrapper'>
            <WplInput title='windfarm' value={filters.windfarm}
                      options={Object.keys(allWindfarms)} onlySelectableOptions={true}
                      onChanged={v => setFilters(p => ({...p, 'windfarm': v}))} clearable={true}/>

            {filters.windfarm && <WplInput title='turbine' value={filters.turbine}
                                           options={allWindfarms[filters.windfarm].turbines}
                                           onlySelectableOptions={true}
                                           onChanged={v => setFilters(p => ({...p, 'turbine': v}))}
                                           clearable={true}/>}

            {filters.turbine && <WplInput title='blade' value={filters.blade}
                                          options={allWindfarms[filters.windfarm].blades}
                                          onlySelectableOptions={true}
                                          onChanged={v => setFilters(p => ({...p, 'blade': v}))}
                                          clearable={true}/>}
            <div style={{flexGrow: 1}}>&nbsp;</div>
        </div>
    }

    function getRowRfStatus(row) {
        const missingBackgrounds = row.picture_count - row.background_count
        if (missingBackgrounds > 0) {
            return [`Missing ${missingBackgrounds} backgrounds`, 'Generate backgrounds']
        } else {
            if (row.existing_model === 0) {
                return ['Has all backgrounds.', 'Generate RF model']
            } else {
                const backgroundsMissingFromModel = row.background_count - row.images_included_in_model
                if (backgroundsMissingFromModel > 0) {
                    return [`Model is old and missing ${backgroundsMissingFromModel} backgrounds`, 'Generate RF model']
                } else {
                    return ['All done.', 'Nothing.']
                }
            }
        }
    }

    return (<div>
        {/*{renderInput()}*/}
        <WplInput
            title='Report'
            value={filters.report_id}
            onChanged={v => setFilters(p => ({...p, 'report_id': v}))}
        />
        <div className='rf-model-status'>
            <h2>rf models</h2>
            <SimpleTable rows={rfStatus} headers={[
                'windfarm',
                'turbine',
                'blade',
                'Model exists',
                'Status',
                'Next step',
                'Action'
            ]} renderRow={(row, idx) => {
                if (filters.windfarm && row.windfarm !== filters.windfarm) return;
                if (filters.turbine && row.turbine !== filters.turbine) return;
                if (filters.blade && row.blade !== filters.blade) return;

                const [status, action] = getRowRfStatus(row)

                const showBtn = action === 'Generate RF model'

                return (<tr key={idx}>
                    <td>{row.windfarm}</td>
                    <td>{row.turbine}</td>
                    <td>{row.blade}</td>
                    <td>{row.existing_model === 1 ? 'True' : 'False'}</td>
                    <td>{status}</td>
                    <td>{action}</td>
                    <td>{showBtn && <WplButton value='Generate RF Model' onClick={_ => {
                        generateRfModel(row)
                    }}/>}</td>
                </tr>)
            }
            }/>
        </div>
    </div>)
}
