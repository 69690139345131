import React, {useState, useEffect, useMemo} from 'react'
import './pivot-table.css'
import Card from "../card/Card";
import filterIcon from '../../assets/img/mail-24px.svg'
import DefectListPopup from "../defect-list-popup/DefectListPopup";
import WplMultiSelect from "../wpl-multi-select/wpl-multi-select";

export default function PivotTable({defects}) {
    const [groups, setGroups] = useState([]);
    const [open, setOpen] = useState('');

    const [popupData, setPopupData] = useState({});
    const [popupDefects, setPopupDefects] = useState([]);
    const [popupIsOpen, setPopupIsOpen] = useState(false)

    const [filteredDefects, setFilteredDefects] = useState([]);

    const initialFilterState = useMemo(() => ({
        OEM: [],
        defectType: [],
        country: []
    }), []);

    const [filterOptions, setFilterOptions] = useState(initialFilterState);
    const [selectedFilters, setSelectedFilters] = useState(initialFilterState);

    const filterTitles = {
        OEM: 'Manufacturer',
        defectType: 'Type',
        country: 'Country'
    };

    useEffect(() => {
        setFilteredDefects(defects);

        // Create options for dropdowns
        let filters = {}
        Object.keys(initialFilterState).forEach(filterName => {
            const allOptions = defects.map(d => d[filterName])
            const unique = allOptions.filter((t, idx) => allOptions.indexOf(t) === idx)
            filters = {
                ...filters,
                [filterName]: unique
            }
        })
        setFilterOptions(filters);
        setSelectedFilters(initialFilterState);
    }, [defects, initialFilterState]);

    useEffect(() => {
        if (Object.keys(selectedFilters).length === 0) {
            setFilteredDefects(defects);
            return;
        }

        setFilteredDefects(defects
            .filter((defect, idx) => {
                return Object.keys(selectedFilters).every(filterName => {
                    const filter = selectedFilters[filterName]
                    if (!filter.length) return true;

                    return filter.indexOf(defect[filterName]) !== -1
                })
            })
        )
    }, [defects, selectedFilters]);

    const step = 10;
    useEffect(() => {
        let tmpGroups = []
        const data = filteredDefects
            .map(d => [d.distanceFromHub, parseInt(d.severity)])
            .sort((a, b) => a[0] - b[0])
        const maxDistance = Math.max(...data.map(d => d[0]))

        for (let lower = 0; lower < maxDistance + 1; lower += step) {
            const groupDistanceCounts = new Array(step);
            const totalCounts = [0, 0, 0, 0, 0]
            for (let i = 0; i < step; i++) groupDistanceCounts[i] = [0, 0, 0, 0, 0];

            data
                .filter(([distance, sev]) => distance >= lower && distance < lower + step)
                .forEach(([dist, sev]) => {
                    groupDistanceCounts[Math.floor(dist) - lower][sev - 1] += 1
                    totalCounts[sev - 1] += 1
                })
            tmpGroups = [...tmpGroups, {
                lowerDistance: lower,
                upperDistance: lower + step - 1,
                counts: groupDistanceCounts,
                totalCounts
            }]
        }
        setGroups(tmpGroups);
    }, [filteredDefects]);

    function onGroupClicked(groupLower) {
        if (open === groupLower) {
            setOpen('')
        } else {
            setOpen(groupLower);
        }
    }

    function showPopup(distance, severity) {
        setPopupData({distance, severity})
        const d = filteredDefects.filter(d => d.severity === severity && Math.floor(d.distanceFromHub) === distance)
        setPopupDefects(d)
        setPopupIsOpen(true);
    }

    function filterSelected(filterName, item) {
        if (selectedFilters[filterName].indexOf(item) === -1) {
            setSelectedFilters({
                ...selectedFilters,
                [filterName]: [...selectedFilters[filterName], item]
            })
        } else {
            setSelectedFilters({
                ...selectedFilters,
                [filterName]: selectedFilters[filterName].filter(s => s !== item)
            })
        }
    }

    function closePopup() {
        setPopupData({});
        setPopupDefects([]);
        setPopupIsOpen(false);
    }

    return (<Card className='pivot-table'>
        <DefectListPopup isOpen={popupIsOpen} onClose={closePopup} title={(<div className='title-group'>
            <div>
                <h2 className={`sev-${popupData.severity}`}>Severity level {popupData.severity}</h2>
                <label>Defects between {popupData.distance} and {popupData.distance + 1} meters from hub</label>
            </div>
        </div>)} defects={popupDefects}/>

        <h2>Pivot distribution model</h2>
        <label>Blade defect distribution model</label>
        <hr/>
        <div className='filters'>
            <img src={filterIcon} alt='filters'/>
            <span>Filter by:</span>
            {Object.keys(initialFilterState).map(filterName => {
                return <WplMultiSelect
                    key={filterName}
                    title={filterTitles[filterName]}
                    selected={selectedFilters[filterName]}
                    onSelected={item => filterSelected(filterName, item)}
                    options={filterOptions[filterName]}
                />
                }
            )}
        </div>
        <table className='pivot-table-table'>
            <thead>
            <tr>
                <th>Distance from root</th>
                <th className='severity-level-1'>Severity 1</th>
                <th className='severity-level-2'>Severity 2</th>
                <th className='severity-level-3'>Severity 3</th>
                <th className='severity-level-4'>Severity 4</th>
                <th className='severity-level-5'>Severity 5</th>
            </tr>
            </thead>
            {groups.map(({
                             lowerDistance,
                             upperDistance,
                             counts,
                             totalCounts
                         }) => <tbody key={`${lowerDistance}-${upperDistance}`}>
                <tr className='summation' key={'summation'} onClick={() => onGroupClicked(lowerDistance)}>
                    <td className={open === lowerDistance ? 'open-group' : ''}>{lowerDistance} - {upperDistance} meter</td>
                    {totalCounts.map((tc, severityIdx) => tc > 0 ?
                        <td key={severityIdx} className={`severity-level-${severityIdx + 1}`}>{tc}</td> :
                        <td key={severityIdx}>&nbsp;</td>)}
                </tr>
                {open === lowerDistance && counts.map((severities, distanceIdx) => <tr key={distanceIdx}>
                    <td className='specific-count-distance'
                        key={distanceIdx + lowerDistance}>{distanceIdx + lowerDistance} meters
                    </td>
                    {severities.map((count, severityIdx) =>
                        count > 0 ?
                            <td key={severityIdx}
                                onClick={() => showPopup(distanceIdx + lowerDistance, severityIdx + 1)}
                                className={`severity-level-${severityIdx + 1} specific-count`}>{count}</td> :
                            <td key={severityIdx}>&nbsp;</td>)}
                </tr>)}
                </tbody>
            )}
        </table>
    </Card>)
}
