import React, { useState } from 'react'
import './fullpage-form.css'
import logo from "../../assets/img/WPL-Global-Blade-Experts-04.png";

export default function ({ children, subtitle, className }) {
    return (<div className='fullpage-form'>
        <img alt='Wind Power Lab' src={logo} style={{
            height: 40,
            padding: 10,
            width: 'auto'
        }} />
        <h1>{subtitle}</h1>
        <div className={`segment ${className}`}>
            {children}
        </div>
    </div>)
}
