import {http} from "../api";

const easyUnpack = ({data}) => data;
export const WdExportApi = {
    start_export: {
        f: function start_export(windfarm_id) {
            let url = '/wd/export/windfarm/$windfarm_id';
            let parameterNames = ["$windfarm_id"];
            [windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {}).then(easyUnpack)
        },
    },
    start_export_defects: {
        f: function start_export_defects(defect_ids, skip_images) {
            let url = '/wd/export';
            return http.post(url, {defect_ids, skip_images}).then(easyUnpack)
        },
    },
    mark_export_ready: {
        f: function mark_export_ready(export_id) {
            let url = '/wd/export/mark_export_ready/$export_id';
            let parameterNames = ["$export_id"];
            [export_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {}).then(easyUnpack)
        },
    },
    get_download_url: {
        f: function get_download_url(export_id) {
            let url = '/wd/export/download/$export_id';
            let parameterNames = ["$export_id"];
            [export_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {}).then(easyUnpack)
        },
    },
};
