import {http} from "../api";

const easyUnpack = ({data}) => data;
export const ReportApi = {
    list_reports: {
        f: function list_reports() {
            let url = '/reports';
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    list_requim_reports: {
        f: function list_requim_reports() {
            let url = '/reports/requim';
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    get_report: {
        f: function get_report(report_id) {
            let url = '/reports/$report_id';
            let parameterNames = ["$report_id"];
            [report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    create_report: {
        f: function create_report(manufacturer, turbine_model, rated_power, blade_type, commisioning_year, inspection_company, inspection_technician, inspection_method, turbine_downtime, title, customer, windfarm, hub_height, rotor_diameter, date_of_inspection, blade_length, report_type, defect_type) {
            let url = '/reports/new';
            return http.post(url, {
                manufacturer,
                turbine_model,
                rated_power,
                blade_type,
                commisioning_year,
                inspection_company,
                inspection_technician,
                inspection_method,
                turbine_downtime,
                title,
                customer,
                windfarm,
                hub_height,
                rotor_diameter,
                date_of_inspection,
                blade_length,
                report_type,
                defect_type
            }).then(easyUnpack)
        },
    },
    update_report: {
        f: function update_report(report_id, manufacturer, turbine_model, rated_power, blade_type, commisioning_year, inspection_company, inspection_technician, inspection_method, turbine_downtime, title, customer, windfarm, hub_height, rotor_diameter, date_of_inspection, blade_length, report_type, defect_type) {
            let url = '/reports/$report_id/update';
            let parameterNames = ["$report_id"];
            [report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {
                manufacturer,
                turbine_model,
                rated_power,
                blade_type,
                commisioning_year,
                inspection_company,
                inspection_technician,
                inspection_method,
                turbine_downtime,
                title,
                customer,
                windfarm,
                hub_height,
                rotor_diameter,
                date_of_inspection,
                blade_length,
                report_type,
                defect_type
            }).then(easyUnpack)
        },
    },
    get_turbine_blades: {
        f: function get_turbine_blades(turbine_name, report_id) {
            let url = '/reports/$report_id/turbine/$turbine_name/blade-names';
            let parameterNames = ["$turbine_name", "$report_id"];
            [turbine_name, report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    get_report_turbine_summary: {
        f: function get_report_turbine_summary(turbine, report_id) {
            let url = '/reports/$report_id/review-summary/$turbine';
            let parameterNames = ["$turbine", "$report_id"];
            [turbine, report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    get_turbine_repair_summary: {
        f: function get_turbine_repair_summary(turbine, report_id, is_internal) {
            let url = '/reports/$report_id/repair-summary/$turbine';
            let parameterNames = ["$turbine", "$report_id"];
            [turbine, report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {is_internal}}).then(easyUnpack)
        },
    },
    unique_turbines_by_report_id: {
        f: function unique_turbines_by_report_id(report_id) {
            let url = '/reports/$report_id/turbine-names';
            let parameterNames = ["$report_id"];
            [report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    rename_report_turbine: {
        f: function rename_report_turbine(report_id, turbine_name, new_turbine_name) {
            let url = '/reports/$report_id';
            let parameterNames = ["$report_id"];
            [report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.patch(url, {params: {turbine_name, new_turbine_name}}).then(easyUnpack)
        },
    },
    list_types_of_inspections: {
        f: function list_types_of_inspections(report_id) {
            let url = '/reports/$report_id/types-of-inspections';
            let parameterNames = ["$report_id"];
            [report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    list_defect_updates: {
        f: function list_defect_updates(report_id) {
            let url = '/reports/$report_id/defect-updates';
            let parameterNames = ["$report_id"];
            [report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    distance_validation_breakdown: {
        f: function distance_validation_breakdown(turbine, report_id) {
            let url = '/reports/$report_id/turbine/$turbine/distance-validation';
            let parameterNames = ["$turbine", "$report_id"];
            [turbine, report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    list_wd_defect_updates: {
        f: function list_wd_defect_updates(report_id, windfarm_id) {
            let url = '/reports/$report_id/wd-defect-updates';
            let parameterNames = ["$report_id"];
            [report_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {windfarm_id}}).then(easyUnpack)
        },
    },
};
