import React, {useEffect, useMemo, useState} from 'react';
import './dashboard.css'
import InfoCards from "../../../components/info-card/InfoCard";
import {lassieDashboardAPI} from "../../../api";
import {Link, useNavigate, useParams} from "react-router-dom";
import home from '../../../assets/img/home-24px.svg';
import Card from "../../../components/card/Card";
import DashboardPie from "./dashboard-pie/DashboardPie";
import PieHooks from "../../../components/pie-chart/PieHooks";
import ShowLessMore from "../../../components/show-lessmore/ShowLessMore";
import {toPrettyDateStr} from "../../../prettyDate";

export default function DashboardPage() {
    const navigate = useNavigate();

    const [windfarmStatistics, setWindfarmStatistics] = useState(null);
    const [turbineStatistics, setTurbineStatistics] = useState(null);
    const [responseStatistics, setResponseStatistics] = useState(null);
    const [scenarioStatistics, setScenarioStatistics] = useState(null);

    let {windfarm_id: wf_id} = useParams();

    const windfarm_id = useMemo(() => {
        if (wf_id) {
            return parseInt(wf_id);
        }
        return null
    }, [wf_id]);

    const selectedWfStats = useMemo(() => {
        if (!windfarm_id || !windfarmStatistics || windfarmStatistics.length === 0) return null;
        return windfarmStatistics.find(s => s.id === windfarm_id)
    }, [windfarm_id, windfarmStatistics]);

    const dates = useMemo(() => {
        let endDate = new Date()
        let startDate = new Date(new Date().setDate(endDate.getDate() - 365));

        return {
            start: toPrettyDateStr(startDate),
            end: toPrettyDateStr(endDate),
        }
    }, []);

    useEffect(() => {
        if (!dates) return null;
        lassieDashboardAPI.listTurbineStatistics(dates.start, dates.end, windfarm_id).then(setTurbineStatistics)
    }, [windfarm_id, dates]);

    useEffect(() => {
        if (!dates) return null;
        lassieDashboardAPI.listScenarioStatistics().then(setScenarioStatistics)
        lassieDashboardAPI.listResponseStatistics(dates.start, dates.end).then(setResponseStatistics)
        lassieDashboardAPI.listStatistics(dates.start, dates.end).then(setWindfarmStatistics)
    }, [dates]);

    const indicators = useMemo(() => {
        if (!windfarmStatistics || windfarmStatistics.length === 0) return [];

        let totalStrikes = 0;
        let outsideIec = 0;
        let mostActiveWindfarm = windfarmStatistics.sort((a, b) => b.total - a.total)[0].name;
        windfarmStatistics.forEach(s => {
            totalStrikes += s.total;
            outsideIec += s.outside_iec;
        })

        return [
            {
                title: 'Total Strikes',
                value: totalStrikes
            },
            {
                title: 'Strikes outside IEC 2010',
                value: outsideIec
            },
            {
                title: 'Windfarm; highest lightning activity',
                value: mostActiveWindfarm
            }
        ]
    }, [windfarmStatistics]);

    const responseIndicators = useMemo(() => {
        if (!responseStatistics) return [];

        let filteredResponseStatistics = responseStatistics

        if (windfarm_id) {
            filteredResponseStatistics = responseStatistics.filter(r => r.windfarm_id === windfarm_id)
        }

        const marked_for = filteredResponseStatistics.reduce((p, c) => p + (c.action === 'marked_for_inspection'), 0)
        const inspections = filteredResponseStatistics.reduce((p, c) => p + (c.action === 'inspection'), 0)

        return [
            {
                title: 'Marked for Inspection',
                value: marked_for
            },
            {
                title: 'Inspections',
                value: inspections
            }
        ]
    }, [responseStatistics, windfarm_id]);

    const responseByWindfarm = useMemo(() => {
        if (!responseStatistics) return [];

        const byWf = responseStatistics.reduce((res, curr) => {
            const name = curr.windfarm_name

            const inspected = curr.action === 'inspection';
            const marked = curr.action === 'marked_for_inspection'

            if (!(name in res)) {
                res[name] = {inspection: 0, marked_for_inspection: 0}
            }
            res[name].inspection += inspected
            res[name].marked_for_inspection += marked

            return res
        }, {})

        return Object.keys(byWf).map(windfarm_name => ({windfarm_name, ...byWf[windfarm_name]}))
    }, [responseStatistics]);

    const avgStrikeWithin500m = useMemo(() => {
        if (!windfarmStatistics) return {};
        const res = {};
        windfarmStatistics.forEach(s => res[s.id] = Math.round(s['500m'] / s.turbine_count * 10) / 10)
        return res
    }, [windfarmStatistics]);

    return (<div className='lightning-dashboard'>
        <div className='filter-menu open'>
            <Link className={`wf-menu-item ${!windfarm_id ? 'selected' : ''}`} to='/lassie/dashboard'>All
                windfarms</Link>
            {windfarmStatistics && windfarmStatistics.map(s =>
                <Link className={`wf-menu-item ${s.id === windfarm_id ? 'selected' : ''}`}
                      key={s.id}
                      to={`/lassie/dashboard/windfarm/${s.id}`}>
                    {s.name}
                </Link>
            )}
        </div>
        <div className='main-scroll-wrapper'>
            <img src={home} alt='Home' className='home-button' onClick={_ => navigate('/lassie')}/>
            <div className='main-dashboard'>
                <h1 className='period-title'>{selectedWfStats && selectedWfStats.name} Last 365 days</h1>
                {!windfarm_id && <InfoCards statistics={indicators}/>}

                {!windfarm_id && windfarmStatistics && <table>
                    <thead>
                    <tr>
                        <th>Windfarm</th>
                        <th>Turbines</th>
                        <th>Average strikes per turbine within 500 meters</th>
                    </tr>
                    </thead>
                    <tbody>
                    {windfarmStatistics.map(s => <tr key={s.id}>
                        <td>{s.name}</td>
                        <td>{s.turbine_count}</td>
                        <td>
                            <div
                                style={{width: `${Math.round(avgStrikeWithin500m[s.id] / Math.max(...Object.values(avgStrikeWithin500m)) * 100)}%`}}
                                className='bar-chart-column'>
                                {avgStrikeWithin500m[s.id]}
                            </div>
                        </td>
                    </tr>)}
                    </tbody>
                </table>}

                {selectedWfStats && <div>
                    <InfoCards statistics={[
                        {
                            title: 'Total Strikes',
                            value: selectedWfStats.total
                        },
                        {
                            title: 'No. outside IEC 2010',
                            value: selectedWfStats.outside_iec
                        },
                        {
                            title: 'Turbines',
                            value: selectedWfStats.turbine_count
                        },
                        {
                            title: 'Average strikes within 500 meters per turbine',
                            value: Math.round(selectedWfStats['500m'] / selectedWfStats.turbine_count * 10) / 10
                        },
                    ]}/>
                </div>}
                {turbineStatistics && windfarm_id && <div>
                    <h2>Theoretical risk overview</h2>
                    <InfoCards statistics={[
                        {
                            title: 'Theoretical expected attachments',
                            value: Math.round(turbineStatistics.reduce((p, c) => p + c.expected_value, 0) * 10) / 10,
                            color: 'red'
                        }, {
                            title: 'Turbines with no lightning activity',
                            value: turbineStatistics.filter(ts => ts.expected_value <= 1).length,
                            color: 'green'
                        }, {
                            title: 'Turbines with more than 1 strike',
                            value: turbineStatistics.filter(ts => ts.expected_value > 1).length,
                            color: 'red'
                        },
                    ]}/>
                </div>
                }
                <h2>Turbines with most lightning activity</h2>
                {turbineStatistics && <table>
                    <thead>
                    <tr>
                        {!windfarm_id && <th>Windfarm</th>}
                        <th>Turbine</th>
                        <th>{'Theoretical Attachment Count'}</th>
                        <th>{'<500m'}</th>
                        <th>{'<200m'}</th>
                        <th>{'<100m'}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {turbineStatistics.filter((_, idx) => idx < 8).map(s => <tr key={s.turbine_id}>
                        {!windfarm_id && <td>{s.windfarm_name}</td>}
                        <td>{s.turbine_name}</td>
                        <td>{Math.round(s.expected_value * 10) / 10}</td>
                        {/*<td>{Math.round(s['probability_of_atleast_one'] * 100)}%</td>*/}
                        <td>{s['500m']}</td>
                        <td>{s['200m']}</td>
                        <td>{s['100m']}</td>
                    </tr>)}
                    </tbody>
                </table>}

                <h1>Team Response</h1>
                <InfoCards statistics={responseIndicators}/>
                {windfarm_id && scenarioStatistics && <div className='pie-area'>
                    {scenarioStatistics.filter(ss => ss.windfarm_id === windfarm_id).map(ss =>
                        <Card className='pie-wrapper'>
                            <h4>{ss.trigger_title}</h4>
                            <PieHooks
                                data={[{text: 'Triggers', value: 1 - (ss.handled / ss.events)}, {
                                    text: 'Handled',
                                    value: ss.handled / ss.events
                                }]}
                                innerRadius={50}
                                outerRadius={80}
                                colors={[
                                    '#ae0006',
                                    '#7cbb42',
                                ]}/>
                            <p>{Math.round((ss.handled / ss.events) * 100)}% handled</p>
                            <ShowLessMore>
                                <p className='description'>{ss.description}</p>
                            </ShowLessMore>
                        </Card>)}
                </div>}
                {responseByWindfarm && !windfarm_id && <table>
                    <thead>
                    <tr>
                        <th>Windfarm</th>
                        <th>Marked for inspection</th>
                        <th>Inspected</th>
                    </tr>
                    </thead>
                    <tbody>
                    {responseByWindfarm.map(s => <tr key={s.windfarm_name}>
                        <td>{s.windfarm_name}</td>
                        <td>{s.marked_for_inspection}</td>
                        <td>{s.inspection}</td>
                    </tr>)}
                    </tbody>
                </table>}
            </div>
        </div>
    </div>)
}
