import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {lassieDashboardAPI} from "../../../api";
import {
    doFetchScenarios,
    doFetchWindfarmLightningTurbines,
    listLightningWindfarms,
    selectAllLightningTurbinesByWindfarm,
    selectLightningWindfarms,
    selectScenarios
} from "../../../flow/lightningFlow";
import './historic-lightning-analysis.css';
import WplInput from "../../../components/wpl-input/WplInput";
import {toPrettyDateStr, toStandardWplTimestamp} from "../../../prettyDate";
import OlMap from "../../../components/ol-map/OlMap";
import {getIconForTurbine} from "../../../components/ol-map/icons";
import TurbineLightningRuler from "../../../components/turbine-lightning-ruler/TurbineLightningRuler";
import LightningTable from "../lightning-table/LightningTable";
import WplButton from "../../../components/wpl-button/WplButton";

export default function HistoricLightningAnalysis() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedWindfarm, setSelectedWindfarm] = useState(null);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState({
        from: '',
        to: '',
    });

    const [historicActivity, setHistoricActivity] = useState(null);
    const [filters, setFilters] = useState({
        outside_certification: {
            filter: tl => true,
            value: 'All'
        },
        distance: {
            filter: tl => tl.distance_km * 1000 <= 2500,
            value: 2500
        },
        probability_of_hit: {
            filter: tl => tl.probability_of_hit * 100 >= 0,
            value: 0
        },
        cloud_to_ground: {
            filter: tl => tl.cloud_to_ground,
            value: 'cloud-to-ground'
        }
    });

    const windfarms = useSelector(selectLightningWindfarms);

    let {windfarm_id, turbine_id} = useParams();
    if (windfarm_id) {
        windfarm_id = parseInt(windfarm_id);
    }
    if (turbine_id) {
        turbine_id = parseInt(turbine_id);
    }

    const selectedWindfarmTurbines = useSelector(selectAllLightningTurbinesByWindfarm(windfarm_id))

    // search params:
    const {from, to} = useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        const from = params.get('from');
        const to = params.get('to');
        return {
            from: from || '',
            to: to || '',
        }
    }, [window.location.search]);

    const selectedTurbine = useMemo(() => {
        if (!selectedWindfarmTurbines || !turbine_id) return null;
        return selectedWindfarmTurbines.find(t => t.id === turbine_id)
    }, [selectedWindfarmTurbines, turbine_id]);

    const filteredLightningStrikes = useMemo(() => {
        if (!historicActivity) return [];
        return historicActivity.filter(tl => {
            for (const [key, filter] of Object.entries(filters)) {
                if (!filter.filter(tl)) return false;
            }
            return true;
        })
    }, [historicActivity, filters]);

    const selectedTurbineWithLightning = useMemo( () => {
            return {
                ...selectedTurbine,
                turbine_lightning: filteredLightningStrikes
            }
        }, [selectedTurbine, filteredLightningStrikes]
    )

    const downloadLightningCSV = useCallback(() => {
        console.log(filteredLightningStrikes)
        const exportTurbine =
            filteredLightningStrikes.map(s => ({
                windfarm: selectedTurbine.windfarm,
                turbine_name: selectedTurbine.name,
                timestamp_local: toPrettyDateStr(new Date(s.timestamp), true) + '.' + String(Math.round(s.nanosecond / 1e6)).padStart(3, '0'),
                peak_current: Math.round(s.peak_current * 10) / 10,
                distance_meters: Math.round(s.distance_km * 100) * 10,
                semi_major_meters: Math.round(s.semi_major_axis * 100) * 10,
                semi_minor_meters: Math.round(s.semi_minor_axis * 100) * 10,
                probability_within_collection_area: Math.round(s.probability_of_hit * 100),
                outside_iec_certification: Boolean(s.outside_certification),
                cloud_to_ground: Boolean(s.cloud_to_ground)
            }))

        const csvData = 'data:text/csv;charset=utf-8,' + Object.keys(exportTurbine[0]).join(',') + '\n' +
            exportTurbine.map(row => Object.values(row).join(',')).join('\n');

        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csvData));
        link.setAttribute('download', `lassie-${exportTurbine[0]['windfarm']}-${exportTurbine[0]['turbine_name']}.csv`);
        document.body.appendChild(link);
        link.click();
    }, [selectedTurbine, filteredLightningStrikes]);

    useEffect(() => {
        if (!windfarm_id || !windfarms) return;
        if (selectedWindfarm && selectedWindfarm.id === windfarm_id) return;

        setSelectedWindfarm(windfarms.find(wf => wf.id === windfarm_id));
        dispatch(doFetchWindfarmLightningTurbines(windfarm_id));
    }, [selectedWindfarm, windfarm_id, windfarms]);

    // initialization
    useEffect(() => {
        dispatch(listLightningWindfarms());
    }, []);

    useEffect(() => {
        if (!turbine_id) return;
        if (!from || !to) return;
        lassieDashboardAPI.listHistoricLightningActivity({
            turbine_id,
            from_timestamp: toStandardWplTimestamp(new Date(from)),
            to_timestamp: toStandardWplTimestamp(new Date(to)),
        }).then(hs => {
            console.log('Got historic activity', hs)
            setHistoricActivity(hs)
        });

    }, [from, to, turbine_id]);

    console.log(selectedTurbine)

    return (<div className='historic-lightning-analysis'>
        <WplInput
            title='Windfarm'
            className='no-risk-windfarms'
            options={windfarms.map(wf => wf.name)}
            onlySelectableOptions
            value={selectedWindfarm && selectedWindfarm.name}
            onChanged={name => {
                const wf = windfarms.find(w => w.name === name)
                if (wf) {
                    navigate(`/lassie/historic-lightning/windfarm/${wf.id}`)
                }
            }}
        />
        <WplInput
            title='Turbine'
            className='no-risk-windfarms'
            options={selectedWindfarmTurbines.map(t => t.name).sort((a, b) => a.localeCompare(b))}
            onlySelectableOptions
            value={selectedTurbine && selectedTurbine.name}
            onChanged={name => {
                const t = selectedWindfarmTurbines.find(t => t.name === name)
                if (t) {
                    navigate(`/lassie/historic-lightning/windfarm/${selectedWindfarm.id}/turbine/${t.id}`)
                }
            }}
        />

        <div className='period-selector'>
            <input type='date'
                   value={selectedTimePeriod.from || from}
                   onChange={e => setSelectedTimePeriod({
                       ...selectedTimePeriod,
                       from: e.target.value
                   })}/>
            <input type='date'
                   value={selectedTimePeriod.to || to}
                   onChange={e => setSelectedTimePeriod({
                       ...selectedTimePeriod,
                       to: e.target.value
                   })}/>
            <button onClick={_ => {
                if (!selectedWindfarm) return;
                navigate(`/lassie/historic-lightning/windfarm/${selectedWindfarm.id}/turbine/${selectedTurbine.id}?from=${selectedTimePeriod.from || from}&to=${selectedTimePeriod.to || to}`)
            }}>Fetch data
            </button>
            <WplButton value='Export lightning to .csv'
                       className='export-btn'
                       onClick={downloadLightningCSV}
                       disabled={!selectedTurbine || filteredLightningStrikes.length === 0} />
        </div>

        <div className='windfarm-report'>
            {/*Two phases*/}
            {/*1. Zoom out - do I have enough 'grov' data */}
            {/* Time period */}
            {/* Distance */}
            {/* cloud to cloud, if not enough grov data */}
            {/*  */}

            {/*2. Zoom in - remove the data that does not fit this question */}
            {/* order by in-/outside cert */}

            {/* in-/outside certification */}
            {/* distance */}
            {/* probability of hit */}
            {/* peak current */}

            {/*    Does this cover what I need?     */}
            {/*    Do I think I can answer my primary question with this data?     */}
            {/*    distance     */}
            {/*    cloud to cloud?      */}
            {/*    time period     */}

            {selectedTurbine && <div className='notification-turbine' key={selectedTurbine.id}>
                <h1>{selectedTurbine.name}</h1>

                <TurbineLightningRuler strikes={filteredLightningStrikes}/>

                <div className='table-wrapper'>
                    <div className='filters' style={{display: 'flex'}}>
                        <WplInput
                            title='In-/outside certification'
                            options={['All', 'Inside certification', 'Outside certification']}
                            onlySelectableOptions
                            value={filters.outside_certification && filters.outside_certification.value || 'All'}
                            onChanged={value => {
                                let newFilter;
                                if (value === 'All') newFilter = () => true;
                                if (value === 'Inside certification') newFilter = tl => !tl.outside_certification;
                                if (value === 'Outside certification') newFilter = tl => tl.outside_certification;
                                setFilters(currentFilterValues => ({
                                    ...currentFilterValues, outside_certification: {
                                        filter: newFilter,
                                        value
                                    }
                                }));
                            }}
                        />
                        <WplInput
                            title='Distance (m)'
                            value={filters.distance ? filters.distance.value : 2500}
                            onChanged={value => {
                                setFilters(p => ({
                                    ...p, distance: {
                                        filter: tl => (Math.round(tl.distance_km * 100) * 10) <= value,
                                        value
                                    }
                                }));
                            }}
                        />
                        <WplInput
                            title='Min probability of hit (%)'
                            value={filters.probability_of_hit ? filters.probability_of_hit.value : 0}
                            onChanged={value => {
                                setFilters(p => ({
                                    ...p, probability_of_hit: {
                                        filter: tl => tl.probability_of_hit * 100 >= value,
                                        value
                                    }
                                }));
                            }}
                        />
                        <WplInput
                            title='Lightning type'
                            options={['All', 'cloud-to-ground', 'intra-cloud']}
                            onlySelectableOptions
                            value={filters.cloud_to_ground ? filters.cloud_to_ground.value : 'cloud-to-ground'}
                            onChanged={value => {
                                let newFilter;
                                if (value === 'All') newFilter = () => true;
                                if (value === 'cloud-to-ground') newFilter = tl => tl.cloud_to_ground;
                                if (value === 'intra-cloud') newFilter = tl => !tl.cloud_to_ground;
                                setFilters(currentFilterValues => ({
                                    ...currentFilterValues, cloud_to_ground: {
                                        filter: newFilter,
                                        value
                                    }
                                }));
                            }}
                        />
                    </div>
                    <div className='table-scroll'>
                        <LightningTable
                            fields={[
                                {
                                    key: 'distance_km',
                                    title: 'Distance',
                                },
                                {
                                    key: 'peak_current',
                                    title: 'Peak',
                                },
                                {
                                    key: 'probability_of_hit',
                                    title: 'Probability',
                                },
                                {
                                    key: 'outside_certification',
                                    title: 'Outside certification',
                                },
                                {
                                    key: 'semi_major_axis',
                                    title: 'Major axis',
                                },
                                {
                                    key: 'semi_minor_axis',
                                    title: 'Minor axis',
                                },
                                {
                                    key: 'timestamp',
                                    title: 'Timestamp',
                                },
                            ]}
                            items={filteredLightningStrikes}
                            className='lightning-table'
                            customRenderFunction={tl => <tr
                                className={`lightning-row ${tl.outside_certification ? 'outside-cert' : ''}`}
                                key={tl.id}>
                                <td>{Math.round(tl.distance_km * 100) * 10} m</td>
                                <td>{Math.round(tl.peak_current * 10) / 10} kA</td>
                                <td>{Math.round(tl.probability_of_hit * 100)}%</td>
                                <td>{tl.outside_certification ? 'Yes' : 'No'}</td>

                                <td>{tl.semi_major_axis*1000} m</td>
                                <td>{tl.semi_minor_axis*1000} m</td>

                                <td>{toPrettyDateStr(new Date(tl.timestamp), true, true)}</td>
                                {tl.outside_certification ? <td>Outside certification!</td> : null}
                            </tr>}
                        />
                    </div>
                </div>
                {historicActivity && <OlMap
                    lightning_strikes={filteredLightningStrikes}
                    turbines={[selectedTurbineWithLightning]}/>}
            </div>
            }
        </div>
    </div>)
}
