import {http} from "../api";

const easyUnpack = ({data}) => data;
export const LightningUserAccessApi = {
    list_all_lightning_subscribers: {
        f: function list_all_lightning_subscribers() {
            let url = '/lassie/subscriber';
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    list_user_subscriptions_by_user_id: {
        f: function list_user_subscriptions_by_user_id(user_id) {
            let url = '/lassie/subscriber/user/$user_id/subscriptions';
            let parameterNames = ["$user_id"];
            [user_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    subscribe: {
        f: function subscribe(windfarm_id, user_id) {
            let url = '/lassie/subscriber/user/$user_id/windfarm/$windfarm_id';
            let parameterNames = ["$windfarm_id", "$user_id"];
            [windfarm_id, user_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.put(url, {params: {}}).then(easyUnpack)
        },
    },
    unsubscribe: {
        f: function unsubscribe(windfarm_id, user_id) {
            let url = '/lassie/subscriber/user/$user_id/windfarm/$windfarm_id';
            let parameterNames = ["$windfarm_id", "$user_id"];
            [windfarm_id, user_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.delete(url, {data: {}}).then(easyUnpack)
        },
    },
};
