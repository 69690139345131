import React, {useRef, useState} from 'react'
import clear from '../../assets/img/clear-24px.svg'
import './centered-popup.css'

export default function CenteredPopup({children, showPopup, closePopup, className}) {
    const me = useRef(null)

    const [downTarget, setDownTarget] = useState('')

    if (!showPopup) return <div></div>

    return (<div ref={me} className='centered-popup' onMouseDown={e => setDownTarget(e.target)} onMouseUp={e => {
        if (e.target !== me.current) return;
        if (downTarget !== me.current) return;
        closePopup(false);
    }}>
        <img className='close-popup-cross-btn' onClick={_ => closePopup(false)}
             src={clear} alt='close popup' />
        <div className={`popup-wrapper ${className}`} onMouseDown={e => setDownTarget(e.target)}>
            {children}
        </div>
    </div>)
}
