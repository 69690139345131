import React, {useMemo, useCallback} from 'react'
import ValueKeyLabel from "../../components/value-key-label/ValueKeyLabel";
import './skalia_display.css'
import {datamanagementAPI} from "../../api";
import WplButton from "../../components/wpl-button/WplButton";
import SimpleTable from "../../components/simple-table/SimpleTable";

export default function SkaliaDisplay({parsedData}) {
    const blades = useMemo(() => {
        let bs = parsedData.defects.map(d => d.blade)
        return bs.filter((b, idx) => bs.indexOf(b) === idx)
    }, [parsedData])

    const download = useCallback(() => {
        datamanagementAPI
            .getSkaliaZip(parsedData)
            .then(signed_url => {
                window.open(signed_url, '_blank')
            })
    }, [parsedData])

    return (<div className='skalia-display'>
        <WplButton value='Download zip with images and excel' onClick={download}/>
        <div className='header'>
            <ValueKeyLabel title='Windfarm'>
                {parsedData.windfarm}
            </ValueKeyLabel>
            <ValueKeyLabel title='Customer'>
                {parsedData.customer}
            </ValueKeyLabel>
            <ValueKeyLabel title='inspection Date'>
                {parsedData.inspection_date}
            </ValueKeyLabel>
        </div>
        {blades.map(blade => <div className='blade' key={blade}>
            <h2>Start blade: {blade}</h2>
            {parsedData
                .defects
                .filter(d => d.blade === blade)
                .sort((a, b) => a.report_defect_id.localeCompare(b.report_defect_id))
                .map(defect =>
                    <div key={`${defect.blade}-${defect.report_defect_id}`} className='defect'>
                        <SimpleTable headers={[
                            'Blade',
                            'Report Defect ID',
                            'Description',
                            'Distance',
                            'Severity',
                            'Surface'
                        ]} rows={[defect]} renderRow={defect => <tr key={defect.report_defect_id}>
                            <td>{defect.blade}</td>
                            <td>{defect.report_defect_id}</td>
                            <td>{defect.description}</td>
                            <td>{defect.distance}</td>
                            <td>{defect.severity}</td>
                            <td>{defect.surface}</td>
                        </tr>}/>
                        <div className='images'>
                            {defect.images.map(img => <div className='img-wrap'>
                                <img src={img.img_data} key={img.file_name} alt={img.file_name}/>
                                <p>{img.file_name}</p>
                            </div>)}
                        </div>
                    </div>
                )}
        </div>)}
    </div>)
}
