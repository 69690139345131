import React, {useState, useCallback, useEffect, useMemo} from 'react'
import WplFileUpload from "../../components/wpl-file-upload/WplFileUpload";
import WplInput from "../../components/wpl-input/WplInput";
import './data-management.css'
import WplButton from "../../components/wpl-button/WplButton";
import {defectAPI, documentAPI, windfarmAPI} from "../../api";

export default function DmSpecialDefect(props) {
    const [windfarms, setWindfarms] = useState([])
    const [selectedWindfarm, setSelectedWindfarm] = useState('')

    const [turbines, setTurbines] = useState([])

    const [pdfReport, setPdfReport] = useState({})
    const [defectImg, setDefectImg] = useState({})

    const [loading, setLoading] = useState(false)

    const initialDefectInfo = useMemo(() => ({
        windfarm: '',
        turbine: '',
        blade: '',
        surface: '',
        layer: '',
        severity: '',
        defect_type: '',
        distance: '',
        report_defect_id: '',
        repair_comment: '',
        repair_step: '',
    }), []);

    const [defectInfo, setDefectInfo] = useState(initialDefectInfo);

    useEffect(() => {
        windfarmAPI.getWindfarms().then(setWindfarms)
    }, []);

    useEffect(() => {
        if (selectedWindfarm === '') return;
        const selectedWf = windfarms.find(wf => wf.name === selectedWindfarm);
        windfarmAPI.listTurbines(selectedWf.id).then(setTurbines)
    }, [windfarms, selectedWindfarm]);

    function uploadedPdf(filename, data) {
        if (!filename.endsWith('.pdf')) {
            alert('Must upload PDF file')
            return false;
        }
        setPdfReport({filename, data})
        return true;
    }

    function uploadedImage(filename, data) {
        setDefectImg({filename, data})
    }

    const updatedInfo = useCallback((key, value) => {
        setDefectInfo(prev => ({
            ...prev,
            [key]: value
        }))
    }, [setDefectInfo]);

    const submit = useCallback(() => {
        setLoading(true)
        let matchingTurbs = turbines.filter(t => t.name === defectInfo['turbine'])
        if (matchingTurbs.length > 1) {
            // Select the latest possible inspection date
            matchingTurbs = matchingTurbs.sort((a, b) => {
                return (new Date(a.inspection_date) < new Date(b.inspection_date)) ? 1 : -1
            })
        }
        let turbine = matchingTurbs[0];

        defectAPI.createDefect({
            defect: {
                defect_type: defectInfo['defect_type'],
                distance: defectInfo['distance'],
                layer: defectInfo['layer'],
                severity: defectInfo['severity'],
                blade: defectInfo['blade'],
                surface: defectInfo['surface'],
                action_comment: defectInfo['repair_step'],
                report_defect_id: defectInfo['report_defect_id'],
                marked_for_repair: defectInfo['marked_for_repair'],
            },
            repair_comment: defectInfo['repair_comment'],
            turbine_id: turbine.id,
            image_source: 'local',
            defect_image_data: defectImg.data,
            blade_image_data: defectImg.data,
        }).then(defectId => {
            documentAPI
                .upload(defectId + '_' + pdfReport.filename, pdfReport.data, selectedWindfarm.id, turbine.id)
                .then(() => {
                    alert('Successfully created a new defect')
                    setLoading(false)
                })
            setDefectInfo(initialDefectInfo)
        })

    }, [turbines, initialDefectInfo, pdfReport, selectedWindfarm.id, defectImg, defectInfo]);

    return (<div>
        <div className='form-wrapper'>
            <h3>PDF report</h3>
            <WplFileUpload didSelectFile={uploadedPdf}/>
            {Object.keys(pdfReport).length > 0 && <p>Selected: {pdfReport.filename}</p>}

            <h3>Defect img</h3>
            <WplFileUpload didSelectFile={uploadedImage}/>
            {Object.keys(defectImg).length > 0 && <img className='wf-wrapper' src={defectImg.data} alt='defect img'/>}

            <h3>Defect Information</h3>
            <WplInput
                value={selectedWindfarm}
                onChanged={wf => {
                    setTurbines([])
                    updatedInfo('turbine', '')
                    setSelectedWindfarm(wf)
                }}
                title='Windfarm'
                options={windfarms.map(wf => wf.name)}
                onlySelectableOptions={true}
            />

            <WplInput
                value={defectInfo['turbine']}
                onChanged={d => updatedInfo('turbine', d)}
                title='Turbine'
                options={turbines.map(t => t.name)}
                onlySelectableOptions={true}
            />

            <WplInput value={defectInfo['blade']} onChanged={d => updatedInfo('blade', d)} title='Blade'/>
            <WplInput value={defectInfo['surface']} onChanged={d => updatedInfo('surface', d)} title='Surface'/>
            <WplInput value={defectInfo['layer']} onChanged={d => updatedInfo('layer', d)} title='Layer'/>
            <WplInput value={defectInfo['report_defect_id']} onChanged={d => updatedInfo('report_defect_id', d)}
                      title='Unique Report Defect ID'/>
            <WplInput value={defectInfo['severity']} onChanged={d => updatedInfo('severity', d)} title='Severity'/>
            <WplInput value={defectInfo['defect_type']} onChanged={d => updatedInfo('defect_type', d)} title='Defect Type'/>
            <WplInput value={defectInfo['distance']} onChanged={d => updatedInfo('distance', d)} title='Distance'/>
            <WplInput value={defectInfo['repair_comment']} onChanged={d => updatedInfo('repair_comment', d)}
                      title='Repair Comment'/>
            <WplInput value={defectInfo['repair_step']} onChanged={d => updatedInfo('repair_step', d)}
                      title='Repair Step'/>
        </div>
        {!loading && <WplButton value='Submit defect' onClick={submit}/>}
        {loading && <p>Loading...</p>}
    </div>)
}
