import React, {useCallback} from 'react'
import homewhite from '../../assets/img/white_home.png'

import './left-menu.css'
import {generatePath, useNavigate} from "react-router-dom";

export default function LeftMenu({windfarms, selectedWindfarm, selectedTurbineId}) {
    const navigate = useNavigate()

    const setSelectedWindfarm = useCallback(clicked_windfarm => {
        if (selectedWindfarm && selectedWindfarm.id === clicked_windfarm.id) {
            navigate('/')
        } else {
            navigate(generatePath(`/windfarm/:windfarm_id`, {
                windfarm_id: clicked_windfarm.id
            }))
        }
    }, [navigate, selectedWindfarm]);

    const setSelectedTurbineId = useCallback(clicked_turbine_id => {
        navigate(generatePath(`/windfarm/:windfarm_id/turbine/:turbine_id`, {
            windfarm_id: selectedWindfarm.id,
            turbine_id: clicked_turbine_id
        }))
    }, [navigate, selectedWindfarm]);

    return (<div className='left-menu'>
        <div onClick={_ => navigate('/lassie')} className='menu-header lassie'>
            {/*<img src={engi} alt='Go to Lassie (Lightning System)'/>*/}
            <h1>LASSIE</h1>
        </div>
        <br/>
        <div onClick={_ => navigate('/')} className='menu-header'>
            <img src={homewhite} alt='Go to portfolio'/>
            <h1>PORTFOLIO</h1>
        </div>
        {windfarms.map(wf => {
            return <div key={wf.name} className='windfarm-wrapper'>
                <div key='wf-title' className='windfarm-title' onClick={() => setSelectedWindfarm(wf)}>
                    <span>{wf.name}</span>
                </div>
                <ul key='turbine-list' className='turbine-list'>
                    {selectedWindfarm && selectedWindfarm.id === wf.id &&
                    selectedWindfarm.turbines.sort((a,b) => a.inspection_date.localeCompare(b.inspection_date)).map(turbine => {
                        return <li
                            key={turbine.id}
                            className={`${selectedTurbineId && selectedTurbineId === turbine.id ? 'selected' : ''}`}
                            onClick={() => setSelectedTurbineId(turbine.id)}>
                            <p>{turbine.name}</p> <span> ({turbine.inspection_date})</span>
                        </li>
                    })}
                </ul>
            </div>
        })}
    </div>)
}
