import React, {useEffect, useState} from 'react'
import {segmentationAPI} from "../../../../api";
import './compare-segmentation-results.css';
import WplButton from "../../../../components/wpl-button/WplButton";

export default function CompareSegmentationResults({report_id, turbine, blade, surface}) {
    const [imageUrls, setImageUrls] = useState([]);
    const [displayPictureIds, setDisplayPictureIds] = useState({});
    const [pictureWidths, setPictureWidths] = useState({});

    useEffect(() => {
        if ([report_id, turbine, blade, surface].some(s => !s)) return;

        segmentationAPI.getAllUrls(report_id, turbine, blade, surface).then(setImageUrls)
    }, [report_id, turbine, blade, surface])

    return (<div className='compare-segmentation-results'>
        {imageUrls.map(({picture_id, original_url, unet_mask, final_background, unet_mask_id, final_mask_id}) => <div
            className='row' key={picture_id}>
            <h2 onClick={_ => setDisplayPictureIds(prev => ({...prev, [picture_id]: (!prev[picture_id])}))}>
                {turbine}-{blade}-{surface}-{picture_id}
                {final_background.qc_user_id && ` = ${final_background.quality}`}
            </h2>

            {displayPictureIds[picture_id] && <div className='rate-buttons'>
                <WplButton big value='Good' onClick={_ => {
                    segmentationAPI.QC(final_mask_id, 'good').then(_ => {
                        setImageUrls(prev => prev.map(img => img.picture_id === picture_id ? {
                            ...img,
                            'final_background': {
                                ...img.final_background,
                                qc_user_id: 'you',
                                quality: 'good'
                            }
                        } : img))
                    })
                }}/>
                <WplButton big red value='Bad' onClick={_ => {
                    segmentationAPI.QC(final_mask_id, 'bad').then(_ => {
                        setImageUrls(prev => prev.map(img => img.picture_id === picture_id ? {
                            ...img,
                            'final_background': {
                                ...img.final_background,
                                qc_user_id: 'you',
                                quality: 'bad'
                            }
                        } : img))
                    })
                }}/>
            </div>}

            {displayPictureIds[picture_id] && <div className='img-wrapper-overlay' style={{
                height: picture_id in pictureWidths ? `${550 / pictureWidths[picture_id].aspect}px` : 'auto'
            }}>
                <img alt='original' className='original' src={original_url} onLoad={node => {
                    setPictureWidths(prev => ({
                        ...prev, [picture_id]: {
                            width: node.target.naturalWidth,
                            height: node.target.naturalHeight,
                            aspect: node.target.naturalWidth / node.target.naturalHeight,
                        }
                    }))
                }}/>
                <img style={{aspectRatio: picture_id in pictureWidths ? `${pictureWidths[picture_id].aspect}` : '1'}}
                     alt='unet-mask' className='unet-mask' src={unet_mask.signed_url}/>
                <img style={{aspectRatio: picture_id in pictureWidths ? `${pictureWidths[picture_id].aspect}` : '1'}}
                     alt='final-background' className='final-background' src={final_background.signed_url}/>
            </div>}
            <div className='img-wrapper-absolute'>
                {displayPictureIds[picture_id] && <>
                    <div>
                        <h4>Original</h4>
                        <img alt='original' className='original' src={original_url}/>
                    </div>
                    <div>
                        <h4>Unet mask</h4>
                        <img
                            style={{aspectRatio: picture_id in pictureWidths ? `${pictureWidths[picture_id].aspect}` : '1'}}
                            alt='unet-mask' className='unet-mask' src={unet_mask.signed_url}/>
                    </div>
                    <div>
                        <h4>Final</h4>
                        <img
                            style={{aspectRatio: picture_id in pictureWidths ? `${pictureWidths[picture_id].aspect}` : '1'}}
                            alt='final-background' className='final-background' src={final_background.signed_url}/>
                    </div>
                </>}
            </div>
        </div>)}
    </div>)
}
