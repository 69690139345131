import './info-card.css'
import {toPrettyTimeSince} from "../../prettyDate";

export default function InfoCards({statistics}) {
    if (!statistics) return;

    return (<div className='info-card-wrapper'>
        {statistics.map(entry => {
            let pretty_value = entry.value

            if (entry.format === 'timestamp')
                pretty_value = toPrettyTimeSince(new Date(pretty_value));

            return (<div key={entry.title} className={`info-card ${entry.color ? entry.color : ''}`}>
                <h2 className='value'>{pretty_value}</h2>
                <p className='info-card-title'>{entry.title}</p>
            </div>)
        })}
    </div>)
}
