import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {lightningAPI, lightningScenarioAPI, lightningUserActionAPI} from "../api";
import {LassieScenarioApi} from "../api_client/LassieScenario";

const initialState = {
    loading: false,
    turbinesLoading: false,
    windfarms: null,
    allTurbines: null,
    selectedTurbines: [],
    scenarios: null,
    turbinesDirty: false,
}

export const listLightningWindfarms = createAsyncThunk('lightning/listWindfarms', () => {
    return lightningAPI.listLightningWindfarms()
})

export const doFetchScenarios = createAsyncThunk('lightning/doFetchScenarios', (wf_id) => {
    return lightningScenarioAPI.list_scenarios_by_windfarm_id(wf_id)
})

export const doCreateScenario = createAsyncThunk('lightning/createScenario', (values) => {
    return lightningScenarioAPI.create_scenario(values)
})

export const doCreateScenarioTrigger = createAsyncThunk('lightning/doCreateScenarioTrigger', ({
                                                                                                  scenario_id,
                                                                                                  values
                                                                                              }) => {
    return lightningScenarioAPI.create_scenario_trigger(scenario_id, values)
})

export const subscribeToScenario = createAsyncThunk('lightning/subscribeToScenario', ({scenario_id, frequency}) => {
    // return LassieScenarioApi.base_view(scenario_id, frequency)
    return lightningScenarioAPI.subscribe_to_scenario(scenario_id, frequency)
})

export const unsubscribeToScenario = createAsyncThunk('lightning/unsubscribeToScenario', async ({
                                                                                                    scenario_id,
                                                                                                    frequency
                                                                                                }) => {
    await lightningScenarioAPI.unsubscribe_from_scenario(scenario_id, frequency)
    return {scenario_id, frequency}
})

export const doFetchWindfarmLightningTurbines = createAsyncThunk('lightning/doFetchWindfarmLightningTurbines', (windfarm_id = null) => {
    return lightningAPI.listAllLightningTurbines(windfarm_id, null)
})

export const doFetchLightningTurbine = createAsyncThunk('lightning/doFetchLightningTurbines', (turbine_id) => {
    return lightningAPI.listAllLightningTurbines(null, turbine_id)
})

export const doSetSelectedTurbines = createAsyncThunk('lightning/doSetSelectedTurbines', (turbine_ids) => {
    return turbine_ids
})

export const doSelectDeselectTurbine = createAsyncThunk('lightning/doSelectDeselectTurbine', (turbine_id) => {
    return turbine_id
})

export const doDeselectAllTurbines = createAsyncThunk('lightning/doDeselectAllTurbines', () => {
    return null
})

export const createUserAction = createAsyncThunk('lightning/createUserAction', ({
                                                                                    turbine_ids,
                                                                                    action,
                                                                                    user_comment,
                                                                                    target_date,
                                                                                    file_names,
                                                                                    file_datas
                                                                                }) => {
    return lightningUserActionAPI.insert_user_action(turbine_ids,
        action,
        user_comment,
        target_date,
        file_names,
        file_datas)
})

const lightningSlice = createSlice({
    name: 'lightning',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(listLightningWindfarms.pending, (state, action) => {
                state.loading = true
            })
            .addCase(listLightningWindfarms.fulfilled, (state, action) => {
                state.loading = false
                state.windfarms = action.payload
            })

            .addCase(createUserAction.fulfilled, (state, action) => {
                const updatedTurbines = action.payload

                state.turbinesDirty = true

                updatedTurbines.map(updated => {
                    state.allTurbines = state.allTurbines
                        .map(turbine => turbine.id !== updated.id ? turbine : updated)
                })
            })

            .addCase(doFetchWindfarmLightningTurbines.pending, (state, action) => {
                state.turbinesDirty = false
                state.turbinesLoading = true
            })
            .addCase(doFetchWindfarmLightningTurbines.fulfilled, (state, action) => {
                state.turbinesDirty = false
                state.turbinesLoading = false
                if (state.allTurbines) {
                    const all = [...state.allTurbines, ...action.payload]
                    const ids = all.map(t => t.id)
                    state.allTurbines = all.filter((t, idx) => ids.indexOf(t.id) === idx)
                } else {
                    state.allTurbines = action.payload
                }
            })

            .addCase(doFetchLightningTurbine.fulfilled, (state, action) => {
                if (state.allTurbines) {
                    const all = [...action.payload, ...state.allTurbines]
                    const ids = all.map(t => t.id)
                    state.allTurbines = all.filter((t, idx) => ids.indexOf(t.id) === idx)
                } else {
                    state.allTurbines = action.payload
                }
            })

            .addCase(doFetchScenarios.fulfilled, (state, action) => {
                state.scenarios = action.payload
            })

            .addCase(doSelectDeselectTurbine.fulfilled, (state, action) => {
                const turbineId = action.payload
                if (state.selectedTurbines.indexOf(turbineId) === -1) {
                    state.selectedTurbines = [...state.selectedTurbines, turbineId]
                } else {
                    state.selectedTurbines = state.selectedTurbines.filter(tid => tid !== turbineId)
                }
            })
            .addCase(doSetSelectedTurbines.fulfilled, (state, action) => {
                state.selectedTurbines = action.payload
            })

            .addCase(doDeselectAllTurbines.fulfilled, (state, action) => {
                state.selectedTurbines = []
            })

            .addCase(doCreateScenario.fulfilled, (state, action) => {
                const newScenario = action.payload
                state.scenarios = [...state.scenarios, newScenario]
            })

            .addCase(doCreateScenarioTrigger.fulfilled, (state, action) => {
                const newTrigger = action.payload
                state.scenarios = state.scenarios.map(scen => scen.id !== newTrigger.scenario_id ? scen : {
                    ...scen,
                    triggers: [...scen.triggers, newTrigger]
                })
            })

            .addCase(subscribeToScenario.fulfilled, (state, action) => {
                const updatedSubscription = action.payload
                state.scenarios = state.scenarios.map(scenario => scenario.id === updatedSubscription.scenario_id ? {
                    ...scenario,
                    'subscriptions': [...scenario.subscriptions, updatedSubscription]
                } : scenario)
            })
            .addCase(unsubscribeToScenario.fulfilled, (state, action) => {
                const updatedSubscription = action.payload
                state.scenarios = state.scenarios.map(scenario => scenario.id === updatedSubscription.scenario_id ? {
                    ...scenario,
                    'subscriptions': scenario.subscriptions.filter(sub => sub.frequency !== updatedSubscription.frequency)
                } : scenario)
            })
    }
})

export default lightningSlice.reducer

export const selectLightningWindfarms = state => {
    if (!state.lightning.windfarms) return []
    return state.lightning.windfarms.map(windfarm => {
        const turbines = state.lightning.allTurbines ? state.lightning.allTurbines.filter(t => t.windfarm_id === windfarm.id) : []
        return {
            ...windfarm,
            require_attention_count: turbines.filter(t => t.status !== 'acceptable_risk').length,
            turbines_marked_for_inspection_count: turbines.filter(t => t.status === 'marked_for_inspection').length,
        }
    })
}

const sortedTurbines = state => {
    if (!state.lightning.allTurbines) return [];
    return [...state.lightning.allTurbines].sort((a, b) => b.probability_of_atleast_one - a.probability_of_atleast_one)
}

export const selectAllLightningTurbines = state => {
    return sortedTurbines(state)
}

export const selectAllLightningTurbinesByWindfarm = windfarm_id => state => {
    if (!windfarm_id) return [];

    return sortedTurbines(state).filter(t => t.windfarm_id === windfarm_id)
}

export const selectTurbinesAtRiskByWindfarm = windfarm_id => state => {
    return sortedTurbines(state)
        .filter(t => (!windfarm_id || t.windfarm_id === windfarm_id))
        .filter(t => t.status === 'at_risk')
}

export const selectTurbinesNoRiskByWindfarm = windfarm_id => state => {
    return sortedTurbines(state)
        .filter(t => (!windfarm_id || t.windfarm_id === windfarm_id))
        .filter(t => t.status === 'acceptable_risk')
}

export const selectTurbinesNoRisk = state => {
    return sortedTurbines(state).filter(t => t.status === 'acceptable_risk')
}

export const selectTurbinesAtRisk = state => {
    return sortedTurbines(state).filter(t => t.status === 'at_risk')
}

export const selectTurbinesMarkedForInspectionByWindfarm = windfarm_id => state => {
    return sortedTurbines(state)
        .filter(t => (!windfarm_id || t.windfarm_id === windfarm_id))
        .filter(t => t.status === 'marked_for_inspection')
}

export const selectTurbinesMarkedForInspection = state => {
    return sortedTurbines(state).filter(t => t.status === 'marked_for_inspection')
}

export const selectLightningTurbineById = turbine_id => state => {
    return sortedTurbines(state).find(t => t.id === turbine_id)
}

export const selectSelectedTurbineIds = state => {
    return state.lightning.selectedTurbines
}

export const selectSelectedTurbinesToBeInspected = windfarm_id => state => {
    return sortedTurbines(state)
        .filter(turb => turb.status === 'marked_for_inspection' && state.lightning.selectedTurbines.indexOf(turb.id) !== -1)
        .filter(turb => !windfarm_id || turb.windfarm_id === windfarm_id)
}

export const selectScenarios = state => {
    if (!state.lightning.scenarios) return [];

    return state.lightning.scenarios
}

export const selectTurbinesLoading = state => {
    return state.lightning.turbinesLoading
}

export const selectTurbinesDirty = state => {
    return state.lightning.turbinesDirty
}
