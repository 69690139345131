import {defectAPI} from "../api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    defectsLoading: false,
    defects: [],
}

export const getDefectsFlow = createAsyncThunk('defect/listAllDefects', () => {
    return defectAPI.getDefects()
})
export const getWindfarmDefectsFlow = createAsyncThunk('defect/listWindfarmDefects', (windfarm_id) => {
    return defectAPI.getWindfarmDefects(windfarm_id)
})

export const getTurbineDefectsFlow = createAsyncThunk('defect/listTurbineDefects', (turbine_id) => {
    return defectAPI.getTurbineDefects(turbine_id)
})

export const setDefectRepairStatus = createAsyncThunk('defect/updateDefect', ({defect_id, new_status}) => {
    const newDefectValues = {id: defect_id}
    switch (new_status) {
        case 'Not repaired':
            defectAPI.markAsIgnore(defect_id).then(() => console.log('saved'));
            newDefectValues.markedForRepair = null;
            newDefectValues.repaired = null;
            break;
        case 'Marked for repair':
            defectAPI.setMarkForRepair(defect_id).then(() => console.log('saved'));
            newDefectValues.repaired = null;
            newDefectValues.markedForRepair = 'yes';
            break;
        case 'Repaired':
            defectAPI.setRepaired(defect_id).then(() => console.log('saved'));
            newDefectValues.repaired = 'yes';
            break
        default:
            break
    }
    return newDefectValues
})

const documentReducer = createSlice({
    name: 'defect',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getDefectsFlow.pending, (state, action) => {
                state.defectsLoading = true
            })
            .addCase(getDefectsFlow.fulfilled, (state, action) => {
                state.defectsLoading = false
                state.defects = action.payload
            })

            .addCase(getWindfarmDefectsFlow.pending, (state, action) => {
                state.defectsLoading = true
            })
            .addCase(getWindfarmDefectsFlow.fulfilled, (state, action) => {
                state.defectsLoading = false
                state.defects = action.payload
            })

            .addCase(getTurbineDefectsFlow.pending, (state, action) => {
                state.defectsLoading = true
            })
            .addCase(getTurbineDefectsFlow.fulfilled, (state, action) => {
                state.defectsLoading = false
                state.defects = action.payload
            })

            .addCase(setDefectRepairStatus.fulfilled, (state, action) => {
                const updatedDefect = action.payload
                state.defects = state.defects.map(def => def.id === updatedDefect.id ? {
                    ...def,
                    ...updatedDefect
                } : def)
            })
    }
})

export default documentReducer.reducer

export const selectAllDefects = state => {
    return state.defect.defects
}

export const selectDefectsLoading = state => {
    return state.defect.defectsLoading
}
