import React, {useCallback, useMemo, useState} from "react";
import {toPrettyDateStr} from "../../../prettyDate";
import {probabilityToString} from "../probabilityToString";
import './turbine-list-friendly.css'
import check from '../../../assets/img/done-24px-dark.svg'
import {getIconForTurbine} from "../../../components/ol-map/icons";

export default function TurbineListFriendly({tr, onClick, selected, hideRisk=true}) {
    return (<div
        onClick={onClick}
        className={`turbine-list-friendly ${selected ? 'selected' : ''}`}>
        <div className='title-date-wrapper'>
            <img className='severity-icon'
                alt={probabilityToString(tr.probability_of_atleast_one) + ' risk'}
                 src={getIconForTurbine(tr)} title={probabilityToString(tr.probability_of_atleast_one) + ' risk'} />
            <div>
                <h3>{tr.name}</h3>
                {!hideRisk && <p
                    className={`timestamp severity-level-${probabilityToString(tr.probability_of_atleast_one).split(' ').join('-')}`}>
                    {probabilityToString(tr.probability_of_atleast_one)} risk</p>}
            </div>

            <div className='grower' />

            <div>
                <p><b>{tr.windfarm}</b></p>
                <p style={{textAlign: 'right'}}>{tr.latest_trigger_date ? toPrettyDateStr(new Date(tr.latest_trigger_date)) : ''}</p>
            </div>
        </div>
        {tr.user_comment &&
            <p className='user-comment'>{tr.user_comment}</p>}
    </div>)
}
