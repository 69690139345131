import React, {useState} from 'react'
import './total-defect-overview.css'
import SeverityList from "../severity-list/SeverityList";
import ShowLessMore from "../show-lessmore/ShowLessMore";
import DefectListPopup from "../defect-list-popup/DefectListPopup";

export default function TotalDefectOverview({defects, severityCounts}) {
    const [showMore, setShowMore] = useState(false)

    return (<div className='simple-defect-view'>
        <DefectListPopup isOpen={showMore} onClose={() => setShowMore(false)} title={(<div className='title-group'>
            <div>
                <h2>All defects</h2>
            </div>
        </div>)} defects={defects} />

        <h1>Total defects overview</h1>
        <p>Overview of all defects of this portfolio</p>
        <SeverityList severityCounts={severityCounts} defects={defects} subDescription='All defects' />
        <ShowLessMore showMore={showMore} setShowMore={setShowMore} />
    </div>)
}
