import React, {useState, useEffect} from 'react'
import './wpl-table.css'
import WplTabs from "../wpl-tabs/WplTabs";
import check from '../../assets/img/done-24px-dark.svg'
import Defect from "../defect/Defect";
import {useDispatch} from "react-redux";
import {setDefectRepairStatus} from "../../flow/defectFLOW";

export default function WplTable({defects, className, showSeverity = true, selectedDefect, setSelectedDefect, inlineSelectable=false}) {
    const [sortedDefects, setSortedDefects] = useState([])
    const [sortByColumn, setSortByColumn] = useState('severity')
    const [ascDesc, setAscDesc] = useState(1)
    const [showFirstN, setShowFirstN] = useState(100);

    const dispatch = useDispatch()

    useEffect(() => {
        const sort = (a, b) => {
            if (['reportDefectId', 'turbineName', 'blade', 'defectType', 'surface',
                'markedForRepair', 'repairRecommendation'].indexOf(sortByColumn) !== -1) {
                if (a[sortByColumn] === b[sortByColumn]) return 0;
                if (a[sortByColumn] === null) return -1 * ascDesc;
                if (b[sortByColumn] === null) return ascDesc;

                return a[sortByColumn].localeCompare(b[sortByColumn]) * ascDesc
            } else {
                if (a[sortByColumn] > b[sortByColumn]) {
                    return -1 * ascDesc
                }
                if (a[sortByColumn] < b[sortByColumn]) {
                    return ascDesc
                }
            }

            return 0
        };

        const ds = defects.map(d => {
            let status = 'Not repaired'
            if (d.markedForRepair !== null) {
                status = 'Marked for repair'
            }
            if (d.repaired !== null) {
                status = 'Repaired'
            }

            return {...d, myAction: status}
        });
        if (sortByColumn === '') {
            setSortedDefects(ds)
        } else {
            setSortedDefects([...ds.sort(sort)])
        }
    }, [ascDesc, defects, sortByColumn]);

    const fields = [
        {
            key: 'reportDefectId',
            name: 'ID'
        }, {
            key: 'turbineName',
            name: 'Turbine'
        }, {
            key: 'blade',
            name: 'Blade'
        }, {
            key: 'defectType',
            name: 'Defect type'
        }, {
            key: 'surface',
            name: 'Surface'
        }, {
            key: 'distanceFromHub',
            name: 'Distance'
        }, {
            key: 'severity',
            name: 'Severity'
        }, {
            key: 'repair_status',
            name: 'Repair status'
        }, {
            key: 'repairRecommendation',
            name: 'Repair actions'
        }
    ];

    function clickedSort(headerName) {
        setShowFirstN(100);
        if (headerName === sortByColumn) {
            if (ascDesc === -1) {
                setAscDesc(1);
                setSortByColumn('severity');
            } else {
                setAscDesc(-1);
            }
        } else {
            setSortByColumn(headerName)
            setAscDesc(1)
        }
    }

    function proxySetDefect(defect) {
        setSelectedDefect(defect)
    }

    function setRepairStatus(defect_id, newStatus) {
        dispatch(setDefectRepairStatus({defect_id, new_status: newStatus}))
    }

    function renderRow(rowDefect) {
        let rr = rowDefect.repairRecommendation
        rr = rr === 'None' ? '' : rr
        const r = (<tr key={rowDefect.id} className={(selectedDefect && selectedDefect.id === rowDefect.id) ? 'focused-defect' : ''}>
            <td onClick={() => proxySetDefect(rowDefect)}>{rowDefect.reportDefectId}</td>
            <td onClick={() => proxySetDefect(rowDefect)}>{rowDefect.turbineName}</td>
            <td onClick={() => proxySetDefect(rowDefect)}>{rowDefect.blade}</td>
            <td onClick={() => proxySetDefect(rowDefect)}>{rowDefect.defectType}</td>
            <td onClick={() => proxySetDefect(rowDefect)}>{rowDefect.is_internal === 1 && <b>(i) </b>}{rowDefect.surface}</td>
            <td onClick={() => proxySetDefect(rowDefect)}>{rowDefect.distanceFromHub}m</td>
            {showSeverity && <td
                className={`severity-level-${rowDefect.severity}`}
                onClick={() => proxySetDefect(rowDefect)}>
                <div className='table-severity-wrapper'>
                    <span>{rowDefect.severity}</span>
                    <img hidden={rowDefect.myAction !== 'Repaired'} src={check} alt='repaired'/>
                </div>
            </td>}

            <td className='tab-td'>
                <WplTabs tabTitles={['Not repaired', 'Marked for repair', 'Repaired']}
                         selected={rowDefect.myAction}
                         setSelected={(status) => setRepairStatus(rowDefect.id, status)}/>
            </td>
            <td onClick={() => proxySetDefect(rowDefect)}>{rr}</td>
        </tr>)
        if (!inlineSelectable || !selectedDefect.id || selectedDefect.id !== rowDefect.id) return r

        return [r, <tr key='selected-row' className='selected-row'>
            <td colSpan={9}>
            <Defect defect={selectedDefect} showCloseButton={true} close={() => proxySetDefect({})} />
            </td>
        </tr>]
    }

    function handleScroll(e) {
        const dist = e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight
        if (dist < 1000) {
            setShowFirstN(showFirstN + 100);
        }
    }

    return (<div onScroll={handleScroll} className={`wpl-table ${className}`}>
        <table>
            <thead>
            <tr>
                {fields.map(({key, name}, idx) => {
                    if (key === 'severity' && !showSeverity) return null;
                    return <th style={{zIndex: 1}}
                        key={idx}
                        onClick={() => clickedSort(key)}>
                        {name}
                        <svg
                            className={`arrow ${(sortByColumn === key) ? 'focus' : ''} ${ascDesc === -1 ? "asc" : "desc"}`}
                            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 330 330">
                            <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                        c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                        s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.394z"/>
                        </svg>
                    </th>
                })}
            </tr>
            </thead>
            <tbody>
            {sortedDefects.map((d, idx) => (idx < showFirstN) && renderRow(d))}
            </tbody>
        </table>
    </div>)
}
