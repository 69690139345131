import React, {useState} from 'react'
import './hover-menu.css'
import home from '../../assets/img/home-24px.svg'
import face from '../../assets/img/face-24px.svg'
import upload from '../../assets/img/cloud_upload-24px.svg'
import power from '../../assets/img/power_settings_new-24px.svg'


export default function HoverMenu({im}) {
    const [open, setOpen] = useState(false);
    const [redir, setRedir] = useState('');

    if (redir) {
        setRedir('');
        window.location = redir
    }

    return (<div className='hover-menu' tabIndex='0' onBlur={() => setOpen(false)}>
        <img alt={'Menu'} src={im} onClick={() => setOpen(!open)} />
        {open ? <div className='items'>
            <div onClick={() => setRedir('/')}><img alt={'Home'} src={home} /><span>Home</span></div>
            <hr />
            <div onClick={() => setRedir('/self-service')}><img alt={'Upload'} src={upload} /><span>Upload</span></div>
            <div onClick={() => setRedir('/support')}><img alt={'Support'} src={face} /><span>Support</span></div>
            <div onClick={() => setRedir('/logout')}><img alt={'Sign out'} src={power} /><span>Sign out</span></div>
        </div> : null}
    </div>)
}
