import React, {useEffect, useState} from 'react'
import WplFullpageForm from "../../components/fullpage-form/WplFullpageForm";
import WplInput from "../../components/wpl-input/WplInput";
import WplButton from "../../components/wpl-button/WplButton";
import {useNavigate, useParams} from "react-router-dom";
import {userAPI} from "../../api";
import {setAuthToken} from "../../auth/user-service";
import './invitation-page.css'

export default function InvitationPage(props) {
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [error, setError] = useState('')
    const [authenticated, setAuthenticated] = useState(false)

    const [tokenValidity, setTokenValidity] = useState(null);

    let {token} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) return;
        userAPI.checkInvitationToken(token)
            .then(res => setTokenValidity(true))
            .catch(e => setTokenValidity(e.response.data))
    }, [token]);

    if (!token) {
        return <div className='error-wrapper'><h4>No token. Close this page and open the link from your email
            again.</h4></div>
    }

    function submit() {
        if (password !== rePassword) return;
        setError('')
        userAPI.acceptInvitation(token, password).then(t => {
            setAuthToken(t)
            setAuthenticated(true)
        })
    }

    if (authenticated) {
        navigate('/')
    }

    return (<WplFullpageForm subtitle='Set a password for your new account'>
        {tokenValidity === true && <WplInput title='Password' type='password' value={password} onChanged={setPassword}/>}
        {tokenValidity === true && <WplInput title='Re-type password' type='password' value={rePassword} onChanged={setRePassword} error={error}/>}
        {tokenValidity === true && <WplButton value='SUBMIT' onClick={submit}/>}

        {tokenValidity !== true && <p>{tokenValidity}</p>}
        {tokenValidity !== true && <a href='/login'>Click here to login</a>}

    </WplFullpageForm>)
}
