import React, {useState, useEffect} from 'react'
import './severity-list.css'
import DefectListPopup from "../defect-list-popup/DefectListPopup";

export default function SeverityList({severityCounts, small, defects, subDescription}) {
    const [clickedSeverity, setClickedSeverity] = useState(-1);
    const [focusedDefects, setFocusedDefects] = useState([]);

    useEffect(() => {
        setFocusedDefects(defects.filter(d => d.severity === clickedSeverity))
    }, [defects, setFocusedDefects, clickedSeverity]);

    return (<div>
        <DefectListPopup defects={focusedDefects} isOpen={clickedSeverity !== -1}
                         onClose={() => setClickedSeverity(-1)}
                         title={(<div className='title-group'>
                             <div>
                                 <h2 className={`sev-${clickedSeverity}`}>{subDescription} (severity {clickedSeverity})</h2>
                             </div>
                         </div>)}>
        </DefectListPopup>
        <div className={`severity-list ${small ? 'small' : ''}`}>
            {Object.keys(severityCounts).map(sc => {
                return (<div onClick={() => setClickedSeverity(parseInt(sc))} key={sc}
                             className={`severity-level-${sc}`}>{severityCounts[sc]}</div>)
            })}
        </div>
    </div>)
}
