import React, {useCallback} from 'react';
import './manage-lightning-inspection.css';
import {useDispatch, useSelector} from "react-redux";
import {
    doSelectDeselectTurbine, selectSelectedTurbineIds,
    selectTurbinesMarkedForInspectionByWindfarm,
} from "../../../flow/lightningFlow";
import {useParams} from "react-router-dom";
import TurbineListFriendly from "../turbine-list-friendly/TurbineListFriendly";
import CreateUserAction from "../../../components/create-user-action/CreateUserAction";

export default function ManageLightningInspection() {
    let {windfarm_id} = useParams();
    if (windfarm_id) {
        windfarm_id = parseInt(windfarm_id)
    }

    const dispatch = useDispatch()

    const turbinesMarkedForInspection = useSelector(selectTurbinesMarkedForInspectionByWindfarm(windfarm_id))
    const selectedTurbineIds = useSelector(selectSelectedTurbineIds)

    const clickedTurbine = useCallback(turbine_id => {
        dispatch(doSelectDeselectTurbine(turbine_id))
    }, [dispatch]);

    return (<div className={`manage-lightning-inspection`}>
        <h1 className='title'>Managing open cases</h1>
        <div className='turbine-risk-wrapper'>
            {turbinesMarkedForInspection.map(tr => <div key={tr.id} className='turbine-marked'
                                                        onClick={_ => clickedTurbine(tr.id)}>
                    <input type='checkbox' checked={selectedTurbineIds.indexOf(tr.id) !== -1} onChange={_ => {
                    }}/>
                    <TurbineListFriendly
                        tr={tr}
                        key={tr.id}
                        className={`turbine-risk ${selectedTurbineIds.indexOf(tr.id) === -1 ? '' : 'selected'}`}
                        selected={selectedTurbineIds.indexOf(tr.id) !== -1}
                    />
                </div>
            )}
        </div>

        <a href='https://windpowerlab.com/blade-advice/' target='_blank' rel='noopener noreferrer'>Need assistance with
            assessment? Get our expert advice through image to advice</a>

        <CreateUserAction />
    </div>)
}