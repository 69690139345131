import {configureStore} from "@reduxjs/toolkit";

import lightningReducer from './flow/lightningFlow'
import defectReducer from './flow/defectFLOW'
import documentReducer from './flow/documentFLOW'

export default configureStore({
    reducer: {
        lightning: lightningReducer,
        defect: defectReducer,
        document: documentReducer,
    }
})
