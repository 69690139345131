import React, {useCallback, useState} from 'react';
import './scenario.css'
import icon from '../../../../assets/img/add-24px.svg';
import {useDispatch, useSelector} from "react-redux";
import WplInput from "../../../../components/wpl-input/WplInput";
import CenteredPopup from "../../../../components/centered-popup/CenteredPopup";
import WplButton from "../../../../components/wpl-button/WplButton";
import {lightningScenarioAPI} from "../../../../api";
import {doCreateScenarioTrigger, subscribeToScenario, unsubscribeToScenario} from "../../../../flow/lightningFlow";

export default function Scenario({scenario, can_edit}) {
    const dispatch = useDispatch()
    const [newTriggerOpen, setNewTriggerOpen] = useState(false);
    const [newTriggerValues, setNewTriggerValues] = useState({});

    const [damageMethod, setDamageMethod] = useState('Severity');
    const [hitMethod, setHitMethod] = useState('Probability');

    const createNewTrigger = useCallback(() => {
        if (newTriggerValues.min_probability) {
            const probTranslation = {
                'LOW': 0.01,
                'MED': 0.09,
                'HIGH': 0.37
            }
            newTriggerValues.min_probability = probTranslation[newTriggerValues.min_probability]
        }
        dispatch(doCreateScenarioTrigger({scenario_id: scenario.id, values: newTriggerValues}))
        setNewTriggerOpen(false);
        setNewTriggerValues({});
    }, [scenario, newTriggerValues]);

    return (<>
        <CenteredPopup showPopup={newTriggerOpen} closePopup={setNewTriggerOpen} className='trigger-popup'>
            <h2>New Scenario Trigger</h2>
            <p>Setting up a trigger for a scenario. You can have several trigger for one scenario.</p>
            <p>Define the criteria for lightning strikes that fit this scenario.</p>
            <div className='vertical'>
                <div className='lightning-specification'>
                    <h2>Damage</h2>
                    <WplInput options={['Severity', 'Peak current']}
                              onlySelectableOptions
                              value={damageMethod}
                              onChanged={setDamageMethod}
                              placeholder='Severity or peak current'/>
                    {damageMethod === 'Severity' && <>
                        <WplInput title={`Min severity: LS${newTriggerValues.min_severity ?? 1}`} type='range'
                                  value={newTriggerValues.min_severity ?? 1}
                                  onChanged={v => setNewTriggerValues(prev => ({...prev, min_severity: v}))}
                                  min={1} max={5}/>
                        <WplInput title={`Max severity: LS${newTriggerValues.max_severity ?? 3}`} type='range'
                                  value={newTriggerValues.max_severity ?? 3}
                                  onChanged={v => setNewTriggerValues(prev => ({...prev, max_severity: v}))}
                                  min={1} max={5}/>
                    </>}
                    {damageMethod === 'Peak current' && <><h4>Peak Current</h4>
                        <div className='vertical'>
                            <WplInput title='Min'
                                      value={newTriggerValues.min_peak_current ?? 0}
                                      onChanged={v => setNewTriggerValues(prev => ({...prev, min_peak_current: v}))}/>
                            <WplInput title='Max'
                                      value={newTriggerValues.max_peak_current ?? 0}
                                      onChanged={v => setNewTriggerValues(prev => ({...prev, max_peak_current: v}))}/>
                        </div>
                    </>}
                </div>
                <div className='lightning-specification'>
                    <h2>Hit Chance</h2>
                    <WplInput options={['Probability', 'Distance']}
                              value={hitMethod}
                              onChanged={setHitMethod}
                              onlySelectableOptions
                              placeholder='Probability or distance'/>
                    {hitMethod === 'Probability' && <>
                        <WplInput title='Min probability'
                                  value={newTriggerValues.min_probability ?? 'LOW'}
                                  onChanged={v => {
                                      setNewTriggerValues(prev => ({...prev, min_probability: v}))
                                  }}
                                  options={['LOW', 'MED', 'HIGH']}
                                  onlySelectableOptions/>
                    </>}

                    {hitMethod === 'Distance' && <>
                        <h4>Distance</h4>
                        <WplInput title='Min distance (km)'
                                  value={newTriggerValues.min_distance_km ?? 0}
                                  onChanged={v => setNewTriggerValues(prev => ({...prev, min_distance_km: v}))}/>
                        <WplInput title='Max distance (km)'
                                  value={newTriggerValues.max_distance_km ?? 0}
                                  onChanged={v => setNewTriggerValues(prev => ({...prev, max_distance_km: v}))}/>
                        <WplInput title='Max Uncertainty (km)'
                                  value={newTriggerValues.max_uncertainty_km ?? 0}
                                  onChanged={v => setNewTriggerValues(prev => ({...prev, max_uncertainty_km: v}))}/>
                    </>}
                </div>
            </div>
            <WplButton disabled={
                !(hitMethod !== 'Distance' || (newTriggerValues.min_distance_km && newTriggerValues.max_distance_km && newTriggerValues.max_uncertainty_km)) ||
                !(hitMethod !== 'Probability' || newTriggerValues.min_probability) ||
                !(damageMethod !== 'Severity' || (newTriggerValues.min_severity && newTriggerValues.max_severity)) ||
                !(damageMethod !== 'Peak current' || (newTriggerValues.min_peak_current && newTriggerValues.max_peak_current))
            } value='OK' big onClick={_ => createNewTrigger()}/>
        </CenteredPopup>

        <div className='scenario-wrapper' key={scenario.id}>
            <div className='scenario'>
                <div className='text-fields'>
                    <h1>{scenario.title}</h1>
                    <label><b>Immediate Action</b></label>
                    <p>{scenario.immediate_action}</p>
                    {/*<textarea value={scenario.immediate_action} onChange={_ => {}}/>*/}

                    <label><b>Description</b></label>
                    <p>{scenario.description}</p>
                    {/*<textarea value={scenario.description} onChange={_ => {}}/>*/}
                </div>
                <div className='subscription-list'>
                    <h2>Email Notifications:</h2>
                    <div>
                        <input id={`daily-${scenario.id}`} onChange={e => {
                            if (e.target.checked) {
                                dispatch(subscribeToScenario({scenario_id: scenario.id, frequency: 'daily'}))
                            } else {
                                dispatch(unsubscribeToScenario({scenario_id: scenario.id, frequency: 'daily'}))
                            }
                        }} checked={scenario.subscriptions.some(sub => sub.frequency === 'daily')}
                               type='checkbox'/>
                        <label htmlFor={`daily-${scenario.id}`}>Daily</label>
                    </div>
                </div>
            </div>
            <div className='trigger-wrapper'>
                <div className='vertical'>
                    <h2>Triggers</h2>
                    {can_edit && <img src={icon} className='new-trigger-btn' onClick={_ => setNewTriggerOpen(true)}/>}
                </div>
                <div className='triggers'>
                    {scenario.triggers.map(trigger => <div className='trigger' key={trigger.id}>
                        {trigger.min_distance_km !== null && <p>{trigger.min_distance_km}km min</p>}
                        {trigger.max_distance_km !== null && <p>{trigger.max_distance_km}km max</p>}
                        {trigger.max_uncertainty_km !== null && <p>Uncert: {trigger.max_uncertainty_km}km</p>}

                        {trigger.min_peak_current !== null && <p>{trigger.min_peak_current}kA min</p>}
                        {trigger.max_peak_current !== null && <p>Max {trigger.max_peak_current}kA</p>}

                        {trigger.min_probability !== null && <p>{trigger.min_probability * 100}%</p>}

                        {trigger.min_severity !== null && <p>Min LS{trigger.min_severity}</p>}
                        {trigger.max_severity !== null && <p>Max LS{trigger.max_severity}</p>}
                    </div>)}
                </div>
            </div>
        </div>
    </>)
}
