import React from "react";
import {Route, BrowserRouter, Routes} from "react-router-dom";
import './index.css'

import LoginPage from "./page/login/LoginPage";
import Logout from "./page/login/Logout";
import MainPage from "./page/main/MainPage";
import AdminPage from "./page/admin/AdminPage";
import InvitationPage from "./page/invitation/InvitationPage";
import SupportPage from "./page/support/SupportPage";
import DownloadPage from "./page/download/DownloadPage";
import DataManagementPage from "./page/data-management/DataManagement";
import SelfService from "./page/self-service/SelfService";
import ResetPasswordPage from "./page/reset-pw/ResetPasswordPage";
import SelfServiceUpload from "./page/upload/SelfServiceUploadPdf";
import LightningAdmin from "./page/admin/lightning-admin/LightningAdmin";
import RequireAuth from "./auth/RequireAuth";
import LightningParentPage from "./page/lightning/LightningParentPage";
import LightningSeverityTurbines from "./page/lightning/lightning-severity/turbines/LightningSeverityTurbines";
import LightningHomePage from "./page/lightning/lightning-home-page/LightningHomePage";
import LightningSeverityRulesPage from "./page/lightning/lightning-severity/rules/LightningSeverityRulesPage";
import LightningDocumentation from "./page/lightning/lightning-documentation/LightningDocumentation";
import TeamColorPage from "./page/team-color-page/TeamColorPage";
import LightningSettingsParent from "./page/lightning-settings/lightning-settings-parent/LightningSettingsParent";
import LightningScenarioSettings from "./page/lightning-settings/lightning-scenario-settings/LightningScenarioSettings";

import SegmentationAdminPage from "./page/admin/segmentation-admin/SegmentationAdminPage";
import LightningWindfarmPage from "./page/lightning/lightning-windfarm/LightningWindfarmPage";
import LightningSupportPage from "./page/lightning/lightning-support/LightningSupportPage";
import LightningWindfarmSettings from "./page/lightning/lightning-windfarm-settings/LightningWindfarmSettings";
import LassieRegionList from "./page/lassie-data/lassie-region-list/LassieRegionList";
import CompleteTurbineList from "./page/lightning/complete-turbine-list/CompleteTurbineList";
import DashboardPage from "./page/lightning/dashboard/DashboardPage";
import LightningReport from "./page/lightning/report/LightningReport";
import HistoricLightningAnalysis from "./page/lightning/historic-lightning-analysis/HistoricLightningAnalysis";
import LightningTurbinePage from "./page/lightning/lightning-turbine/LightningTurbinePage";
import Sitemap from "./page/admin/site-map/Sitemap";
import LassieRegionPage from "./page/lassie-data/lassie-region-page/LassieRegionPage";

function App() {
    return (<BrowserRouter>
        <Routes>
            <Route exact path='/' element={<RequireAuth><MainPage/></RequireAuth>}/>
            <Route exact path='/windfarm/:windfarm_id' element={<RequireAuth><MainPage/></RequireAuth>}/>
            <Route exact path='/windfarm/:windfarm_id/turbine/:turbine_id'
                   element={<RequireAuth><MainPage/></RequireAuth>}/>

            <Route exact path='/logout' element={<Logout/>}/>
            <Route exact path='/login' element={<LoginPage/>}/>
            <Route exact path='/invitation/' element={<InvitationPage/>}/>
            <Route exact path='/invitation/:token' element={<InvitationPage/>}/>
            <Route exact path='/reset/:token' element={<ResetPasswordPage/>}/>
            <Route exact path='/support' element={<RequireAuth><SupportPage/></RequireAuth>}/>
            <Route exact path='/self-service' element={<RequireAuth><SelfService/></RequireAuth>}/>
            <Route exact path='/self-service-upload' element={<RequireAuth><SelfServiceUpload/></RequireAuth>}/>
            <Route exact path='/download/:export_id' element={<RequireAuth><DownloadPage/></RequireAuth>}/>

            <Route exact path='/lassie/dashboard'
                   element={<RequireAuth noHeader noFooter extraClass='no-max-width'><DashboardPage /></RequireAuth>}/>
            <Route exact path='/lassie/dashboard/windfarm/:windfarm_id'
                   element={<RequireAuth noHeader noFooter extraClass='no-max-width'><DashboardPage /></RequireAuth>} />

            <Route path='/lassie' element={<RequireAuth noHeader><LightningParentPage/></RequireAuth>}>
                <Route path='' element={<LightningHomePage/>}/>
                <Route path='windfarm/:windfarm_id' element={<LightningWindfarmPage />} />
                <Route path='windfarm/:windfarm_id/complete-turbine-list' element={<CompleteTurbineList />} />

                <Route path='windfarm/:windfarm_id/turbine/:turbine_id' element={<LightningTurbinePage/>}/>

                <Route path='report' element={<LightningReport />} />
                <Route path='report/windfarm/:windfarm_id' element={<LightningReport />} />

                <Route path='historic-lightning' element={<HistoricLightningAnalysis />} />
                <Route path='historic-lightning/windfarm/:windfarm_id' element={<HistoricLightningAnalysis />} />
                <Route path='historic-lightning/windfarm/:windfarm_id/turbine/:turbine_id' element={<HistoricLightningAnalysis />} />

                <Route path='support' element={<LightningSupportPage />} />

                <Route path='settings' element={<LightningSettingsParent/>}>
                    <Route path='scenario' element={<LightningScenarioSettings/>}/>

                    <Route path='severity' element={<LightningSeverityRulesPage/>}/>
                    <Route path='severity/rules' element={<LightningSeverityRulesPage/>}/>
                    <Route path='severity/turbines' element={<LightningSeverityTurbines/>}/>

                    <Route path='turbines' element={<LightningSeverityTurbines/>}/>
                    <Route path='windfarm' element={<LightningWindfarmSettings/>}/>
                </Route>

                <Route path='Documentation' element={<LightningDocumentation/>}/>
            </Route>

            <Route exact path='/admin' element={<RequireAuth><AdminPage/></RequireAuth>}/>
            <Route exact path='/admin/site-map' element={<RequireAuth><Sitemap/></RequireAuth>}/>
            <Route exact path='/admin/data' element={<RequireAuth><DataManagementPage/></RequireAuth>}/>
            <Route exact path='/admin/lightning' element={<RequireAuth><LightningAdmin/></RequireAuth>}/>
            <Route exact path='/admin/lightning/data' element={<RequireAuth><LassieRegionList/></RequireAuth>}/>
            <Route exact path='/admin/lightning/data/region/:region_id' element={<RequireAuth><LassieRegionPage /></RequireAuth>}/>

            <Route exact path='/team/color-check-in' element={<RequireAuth><TeamColorPage/></RequireAuth>}/>

            <Route exact path='/admin/segmentation'
                   element={<RequireAuth useMaxWidth={false}><SegmentationAdminPage/></RequireAuth>}/>
        </Routes>
    </BrowserRouter>)
}

export default App;
