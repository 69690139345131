import React from 'react';
import './lightning-documentation.css'

import collection_area_side from './img-description/collection_area_side.png'
import collection_area_top from './img-description/collection_area_top.png'
import lightning_pd_function from './img-description/lightning_pd_function.png'
import lightning_turbine_proability_calculation from './img-description/lightning_turbine_proability_calculation.png'
import lightning_turbine_probabilty from './img-description/lightning_turbine_probabilty.png'
import Lightning_Density_map_2021 from './img-description/Global_lightning_density_2021.jpg'
import Lightning_Density_map_2016_2020 from './img-description/Average_global_lightning_density_2016_2020.jpg'

export default function LightningDocumentation() {

    return (<div className='lightning-documentation'>
        <h1>Documentation</h1>
        <h2>Where do we get our data?</h2>
        <p>
            We fetch information from different sources depending on where on the globe your windfarm is located.
        </p>
        <p>
            We have data-sources from denmark, norway, sweden and from a a global lightning data provider.
        </p>
        <p>
            After fetching raw lightning strikes from all sources, we then filter and combine the datasets to get a
            completely covering dataset with as low uncertainty as possible.
        </p>

        <h2>What we base our recommendations on</h2>
        <p>
            We combine our lighting- and blade expertise and your risk profile  and the IEC standartd to tailer
            our recommendation to your specific need.

        </p>

        <h2>Lightning and wind turbines</h2>

        <p>
            Tall structures are attractive to lightning, especially when located on flat planes
            with nothing much else around. When wind turbines are being installed the lightning
            enviromnent is being modified.
        </p>

        <p>
            Specific types of lightning events, such as upward lightning will not take place unless the
            tall structures are present, and the number and frequency of them are also related to the
            weather conditions in the area.
        </p>
        <p>
            There is some concern that lightning deteection networks like the U.S National Lightning
            Detecton Network (NLDN) or EUCLID, the European Cooperation for Lightning Detection, may
            not report all of the upward-initated lightning from wind turbines, although some of these
            upward flashes might lead to blade damage. The reason for this is that upward initiated
            flashes are very different from downward flashes in terms of their detectability by LLS.
            For the 715 flashes measured at the GBT in Austria we obtained an overall DE of 43 %.
            These relatively low DE is mainly a result of the 338 ICC. Only type discharges, all of them
            not detected by the LLS. This observation is important whenever LLS data are used to investigate lightning
            caused damage and when upward initiated lightning might be involved (e.g.damage to wind
            turbine blades).
        </p>


        <h3>Always the tip </h3>

        <p>
            Lightning is being divided into two main categories. In-Cloud lightning and Cloud to ground lightning.

            <p>
                <b>In Cloud lightning</b>:<br></br>
                A cloud flash is lightning that connects regions with opposite polarity (+/-) within one cloud or between
                multiple clouds. In-cloud lightning usually precedes cloud-to-ground lightning.
            </p>

            <b> Cloud-to-ground lightning</b>: <br></br>
            A cloud-to-ground flash consists of at least one cloud-to-ground stroke and is dangerous to life
            and property. A cloud-to-ground stroke, also known as a stroke, is lightning that connects a charge region in
            a cloud to the ground and is the bright, high current, visible part of lightning that touches the ground or an object.
            <p>
                When lightning attaches to the wind turbine blade research shows that  the tip area of the blade is more prone to receive
                lightning strikes than all other places on the blade. [1][2][3].
            </p>
        </p>

        <h2>Lightning intensity at a global scale</h2>

        <p>
            The distribution of lightning on Earth is far from uniform. The ideal conditions for producing lightning
            and associated thunderstorms occur where warm, moist air rises and mixes with cold air above.
            These conditions occur almost daily in many parts of the Earth, but only rarely in other areas.
        </p>

        <h3>Global lightning density 2021</h3>
        <img src={Lightning_Density_map_2021} alt={'Lightning Density map 2021'} width="800" />

        <h3>Average global lightning density 2016–2020</h3>
        <img src={Lightning_Density_map_2016_2020} alt={'Lightning Density map 2016'} width="800" />

        <h2>What to do if your turbine is hit</h2>
        <p>
            Don't panic. If you're not sure what to do, we are always happy to help.
        </p>
        <p>
            Before contacting us you should find your service agreement - if you have one. Also find any insurance related
            documents that you think might be relevant. Information about the turbines would also be great, if it's not
            already on the platform.
        </p>
        <p>
            If the lightning strike was high severity (4 or 5) you should stop your turbine as
            soon as possible to stop any structural damages from spreading.
        </p>

        <p />
        <p />
        <p />
        <h1>How probabilities of lightning strikes works</h1>
        <h2>Turbine Collection area</h2>
        <div className='text-with-image'>
            <p>
                Around each turbine there exists a 'collection area'. Even though it is a simplification, we assume that
                lightning strikes within this area hits the turbine. The collection area is calculated by taking the total
                height of the turbine and multiply with 3.
            </p>
            <div>
                <img src={collection_area_top} alt={'Collection area from the top'} />
                <img src={collection_area_side} alt={'Collection area from the side'} />
            </div>
        </div>
        <h2>Lightning strike probability</h2>
        <div className='text-with-image'>
            <img src={lightning_pd_function} alt={'Lightning PDF'} />
            <div>
                <p>
                    Each lightning strike has a probability density function (PDF) - which describes an area where the lightning
                    strike was most likely to hit.
                </p>
                <p>
                    The only thing we actually know is that a lightning strike has been inside this area - with highest probability
                    of landing in the center and lower probability as you move towards the edge of the circle.
                </p>
            </div>
        </div>
        <h2>Probability of strike hitting specific turbine</h2>
        <div className='text-with-image'>
            <div>
                <p>
                    In this step we want to combine the two ideas from above.
                </p>
                <p>
                    Say we have a lightning PDF that overlaps with our turbines <b>collection area</b>.
                    Then we want to calculate the probability that this strike actually hit inside the collection area.
                </p>
                <p>
                    We do that by 'cropping' the lightning PDF - as so:
                </p>
            </div>
            <img src={lightning_turbine_probabilty} alt={'Lightning PDF'} />
        </div>
        <div className='text-with-image'>
            <img src={lightning_turbine_proability_calculation} alt={'Lightning PDF'} />
            <div>
                <p>
                    After cropping we sum up the probabilities of the overlapping circles.
                </p>
                <p>
                    For each small area inside the collection area we calculate a probability. And then we sum this probability
                    for the total probability of this specific lightning strike hitting this turbine.
                </p>
            </div>
        </div>

        <h2>Abbreviation</h2>
        <table>
            <tr>
                <td>DE</td>
                <td>Detection Effiency</td>
            </tr>
            <tr>
                <td>EUCLID</td>
                <td>European Cooperation for Lightning Detection</td>
            </tr>
            <tr>
                <td>CG</td>
                <td>Cloud-to-Ground</td>
            </tr>
            <tr>
                <td>CC</td>
                <td>Cloud-to-Cloud</td>
            </tr>
            <tr>
                <td>NLDN</td>
                <td>National Lightnig Detection Network</td>
            </tr>
            <tr>
                <td>LLS</td>
                <td>Lightning Location System</td>
            </tr>
            <tr>
                <td>LPL</td>
                <td>Lightning Protection Level</td>
            </tr>
            <tr>
                <td>PDF</td>
                <td>Probability Density Function</td>
            </tr>
            <tr>
                <td>ICC</td>
                <td>Initial Continuous Current</td>
            </tr>
        </table>


        <h2>References</h2>
        <p>[1] Madsen, S. F. (2006), Interaction between electrical discharges and materials for wind turbine
            blades particularly related to lightning protection, PhD thesis, Ørsted-DTU, The Technical Univ. of Denmark, Kongens Lyngby, Denmark.
        </p>
        <p>[2] Bertelsen, K., H. V. Erichsen, M. S. Jensen, and S. F. Madsen (2007), Application of numerical models to
            determine lightning attachment points on wind turbines, in Proceedings of the International
            Conference on Lightning and Static Electricity, IET, pp. 28–31, Paris, France.
        </p>
        <p>[3] Garolera, A. C., S. F. Madsen, M. Nissim, J. Myers, and J. Holboell (2016), Lightning damage to
            wind turbine blades from wind farms in US, IEEE Trans. Power Delivery, 31(3), 1043–1049, doi:10.1109/TPWRD.2014.2370682.
        </p>
    </div>)
}
