import React, {useEffect, useMemo, useState} from 'react';
import LassieRegionProcessComponent from "../lassie-region-process-component/LassieRegionProcessComponent";
import {GeoJson, Map} from "pigeon-maps";
import {MyMarker} from "../../../components/my-marker/MyMarker";
import SimpleTable from "../../../components/simple-table/SimpleTable";
import './region-data-overview.css';
import WplTabs from "../../../components/wpl-tabs/WplTabs";
import {lassieTaskmasterLogAPI} from "../../../api";

export default function RegionDataOverview({selectedRegion, setSelectedRegion}) {
    const [selectedTab, setSelectedTab] = useState('worker log');
    const [hoveringProcess, setHoveringProcess] = useState(null);
    const [hoveringPeriod, setHoveringPeriod] = useState(null);

    const [testCoverFrom, setTestCoverFrom] = useState(null);
    const [testCoverTo, setTestCoverTo] = useState(null);

    useEffect(() => {
        if (!selectedRegion || selectedRegion.logs) return;
        lassieTaskmasterLogAPI.list_logs(selectedRegion.id).then(logs => {
            setSelectedRegion(prev => ({...prev, logs}))
        });
    }, [selectedRegion]);

    console.log(selectedRegion)
    const isCovered = useMemo(() => {
        if (!testCoverFrom || !testCoverTo) return false;

        const from = new Date(testCoverFrom);
        const to = new Date(testCoverTo);
        const has_data = selectedRegion.regional_query_coverage.some(c => {
            const c_from = new Date(c.from_timestamp);
            const c_to = new Date(c.to_timestamp);
            return c_from <= from && c_to >= to && c.covered;
        });
        const is_processed = selectedRegion.turbine_lightning_coverage.some(c => {
            const c_from = new Date(c.from_timestamp);
            const c_to = new Date(c.to_timestamp);
            return c_from <= from && c_to >= to && c.covered;
        });
        if (has_data && is_processed) return 'Yes';
        if (has_data) return 'Missing processed data';
        if (is_processed) return 'Missing raw data';
        return 'Missing raw and processed data';
    }, [selectedRegion, testCoverFrom, testCoverTo]);

    return (<div className='region-data-overview'>
            <div className='process-wrapper'>
                <h2>{selectedRegion.name}</h2>
                <h3>Data coverage:</h3>
                <LassieRegionProcessComponent
                    query_coverage={selectedRegion.regional_query_coverage}
                    process_coverage={selectedRegion.turbine_lightning_coverage}
                    region_id={selectedRegion.id}
                    setClickedCoverage={setHoveringPeriod}
                />
                <div className='test-if-dates-are-covered'>
                    <h3>Test if dates are covered:</h3>
                    <p>from:</p>
                    <input type='date' onChange={e => setTestCoverFrom(e.target.value)}/>
                    <p>to:</p>
                    <input type='date' onChange={e => setTestCoverTo(e.target.value)}/>
                    <p>Is covered: {isCovered}</p>
                </div>
            </div>
            <div className='map-regional-query-wrapper'>
            <Map height={'500px'}
                     metaWheelZoom
                     defaultCenter={selectedRegion.center}
                     defaultZoom={10}>
                    <GeoJson
                        data={{
                            type: "FeatureCollection",
                            features: [
                                {
                                    type: "Feature",
                                    geometry: {
                                        type: "Polygon",
                                        coordinates: [selectedRegion.polygon_coordinates]
                                    },
                                    properties: {prop0: "value0"},
                                },
                            ],
                        }}
                        styleCallback={(feature, hover) => {
                            if (feature.geometry.type === "LineString") {
                                return {strokeWidth: "2", stroke: "black"};
                            }
                            return {strokeWidth: "2", stroke: "black", fill: '#ffaaff66'};
                        }}
                    />
                    {selectedRegion && selectedRegion.turbines && selectedRegion.turbines.map(t =>
                        <MyMarker key={t.id}
                                  color={'blue'}
                                  width={40} anchor={[t.lat, t.lon]}/>)}
                </Map>
                <WplTabs selected={selectedTab}
                         setSelected={setSelectedTab}
                         tabTitles={['worker log', 'data queries', 'process queries']}
                />

                {selectedTab === 'worker log' && selectedRegion.logs && <><h3>Worker logs:</h3>
                    <div className='table-wrapper'>
                        <SimpleTable
                            className='regional-query-table'
                            columns={[{
                                field: 'message',
                                title: 'Message',
                            }, {
                                field: 'process_id',
                                title: 'Process ID',
                            }, {
                                field: 'region_id',
                                title: 'Region id',
                            }, {
                                field: 'timestamp_created',
                                title: 'timestamp',
                                field_format: 'prettyDate',
                            }, {
                                field: 'id',
                                title: 'ID',
                                hide: true,
                            }]}
                            data={selectedRegion.logs}
                            renderRow={row => <tr onMouseEnter={_ => setHoveringProcess(row[1])}
                                                  key={row.id}
                                                  className={row[1] && row[1] !== 'TASKMASTER' && hoveringProcess === row[1] ? 'hovering-process' : ''}>
                                {row.map(r => <td>{r}
                                </td>)}
                            </tr>}
                        />
                    </div>
                </>}

                {selectedTab === 'process queries' && <>
                    <h3>All turbine lightning queries:</h3>
                    <div className='table-wrapper'>
                        <SimpleTable
                            className={`regional-query-table`}
                            columns={[{
                                field: 'lightning_strikes_processed',
                                title: 'Lightning Processed',
                            }, {
                                field: 'turbine_lightning_inserted',
                                title: 'Turbine Lightning Inserted',
                            }, {
                                field: 'from_timestamp',
                                title: 'From',
                                field_format: 'prettyDate',
                            }, {
                                field: 'to_timestamp',
                                title: 'To',
                                field_format: 'prettyDate',
                            }, {
                                field: 'timestamp_created',
                                title: 'Created',
                                field_format: 'prettyDate',
                                hide: true
                            }, {
                                field: 'id',
                                title: 'id',
                                hide: true
                            }]}
                            data={selectedRegion.process_queries}
                            renderRow={row => {
                                // if lightning found or lightning inserted - make row green
                                let highlight = (row[0] + row[1]) > 0 ? 'highlight' : ''
                                // if this row covers the hovered period - make row blue
                                const from_timestamp = new Date(row[2])
                                const to_timestamp = new Date(row[3])
                                if (hoveringPeriod && (from_timestamp > hoveringPeriod.from_timestamp && to_timestamp < hoveringPeriod.to_timestamp)) {
                                    highlight += ' hovering-period'
                                }
                                return <tr className={highlight} key={row.id}>
                                    {row.map(r => <td>{r}</td>)}
                                </tr>
                            }}
                        />
                    </div>
                </>}
                {selectedTab === 'data queries' && <><h3>All regional queries:</h3>
                    <div className='table-wrapper'>
                        <SimpleTable className='regional-query-table'
                                     columns={[
                                         {
                                             field: 'lightning_found',
                                             title: 'Lightning Found',
                                         }, {
                                             field: 'lightning_inserted',
                                             title: 'Lightning Inserted',
                                         }, {
                                             field: 'from_timestamp',
                                             title: 'From',
                                             field_format: 'prettyDate',
                                         }, {
                                             field: 'to_timestamp',
                                             title: 'To',
                                             field_format: 'prettyDate',
                                         }, {
                                             field: 'timestamp_created',
                                             title: 'Created',
                                             hide: true
                                         }, {
                                             field: 'id',
                                             title: 'id',
                                             hide: true
                                         },
                                     ]}
                                     data={selectedRegion.data_queries}
                                     renderRow={row => <tr className={(row[0] + row[1]) > 0 ? 'highlight' : ''}
                                                           key={row.id}>{row.map(r => <td>{r}</td>)}</tr>}
                        />
                    </div>
                </>}
            </div>
        </div>
    )
}