import React, {useState, useEffect, useCallback} from 'react'
import {datamanagementAPI} from "../../../api";
import Folder from "../../../components/folder/Folder";
import './dm-cornis.css'
import WplButton from "../../../components/wpl-button/WplButton";
import WplTabs from "../../../components/wpl-tabs/WplTabs";
import ParsedObject from "../ParsedObject";

export default function DmCornisFileSelection({bladeLength, report_id}) {
    const [folderStructure, setFolderStructure] = useState([])

    const [currentTab, setCurrentTab] = useState('Files')

    const [parsedJson, setParsedJson] = useState({});
    const [overview, setOverview] = useState({})

    const [loading, setLoading] = useState(false);

    const [loadingFiles, setLoadingFiles] = useState([])

    const [parsedObject, setParsedObject] = useState({});
    const [existingPicturesForPo, setExistingPicturesForPo] = useState({});

    const getFolderStructure = useCallback(() => {
        setLoading(true)
        datamanagementAPI
            .getFolderStructure('', ['.json'])
            .then(folders => {
                setLoading(false)
                setFolderStructure(folders)
            })
    }, [])

    useEffect(() => {
        getFolderStructure()
        datamanagementAPI.listOverview().then(o => {
            setOverview(o)
        })
    }, [getFolderStructure]);

    const fileClicked = useCallback((pathClicked) => {
        if (pathClicked in parsedJson) {
            setParsedJson(prev => {
                let res = {...prev}
                delete res[pathClicked]
                return res
            })
            return;
        }
        setLoadingFiles(prev => [...prev, pathClicked])
        datamanagementAPI.parseCornis(pathClicked, pathClicked, bladeLength).then(res => {
            setParsedJson(prev => ({...prev, [pathClicked]: res}))
            setLoadingFiles(prev => prev.filter(p => p !== pathClicked))
        })
    }, [bladeLength, parsedJson]);

    console.log({
        parsedObject,
        parsedJson
    })

    if (loading) return <div className='dm-cornis'>Loading</div>

    return (<div className='dm-cornis'>
        <WplButton onClick={getFolderStructure} value={'Reload folders'} />
        <WplTabs selected={currentTab} setSelected={setCurrentTab} tabTitles={['Files', 'Edit & Import']}/>

        <div style={{display: currentTab === 'Files' ? 'block' : 'none'}}>
            <Folder folder={folderStructure} prefix='' onFileClicked={fileClicked} renderFileCallback={fileName => {
                if (loadingFiles.indexOf(fileName) !== -1) return 'Loading...'
                if (!(fileName in parsedJson)) return;
                const parsed = parsedJson[fileName].turbine

                let dbData
                if (!(parsed.windfarm in overview)) {
                    dbData = <div className='blades'>No existing windfarm with this name.</div>
                } else if (!(parsed.turbine_name in overview[parsed.windfarm])) {
                    dbData = <div className='blades'>Found a windfarm - but no turbine for this name.</div>
                } else {
                    const blades = overview[parsed.windfarm][parsed.turbine_name].blades
                    dbData = <div className='blades'>
                        <h4>Data found on this windfarm and turbine in the database</h4>
                        {blades.sort((a, b) => a.name.localeCompare(b.name)).map(blade => <div key={blade.name}>
                                <p>{blade.name}: {blade.surfaces.map(surface =>
                                    <span>{`${surface.surface} (${surface.images.length})`}</span>)}</p>
                            </div>
                        )}
                    </div>
                }

                return <div className='turbine-data'>
                    <div className='blades'>
                        <h4>Data found in this JSON file</h4>
                        {parsed.blades.sort((a, b) => a.name.localeCompare(b.name)).map(blade => <div key={blade.name}>
                                <p>{blade.name}: {blade.surfaces.map(surface =>
                                    <span>{`${surface.surface} (${surface.images.length})`}</span>)}</p>
                            </div>
                        )}
                        <WplButton value='Parse' onClick={_ => {
                            setParsedObject({...parsed, report_id})
                            setExistingPicturesForPo(parsedJson[fileName].existing_pictures)
                            setCurrentTab('Edit & Import')
                        }}/>
                    </div>
                    {overview && dbData}
                </div>
            }}/>
        </div>

        {currentTab === 'Edit & Import' &&
            <ParsedObject parsedObject={parsedObject} setParsedObject={setParsedObject} existingPictures={existingPicturesForPo} />}
    </div>)
}
