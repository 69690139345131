import React from 'react'
import './wpl-tabs.css'

export default function WplTabs({tabTitles, selected, setSelected}) {
    return (<div className='tab-container'>
        {tabTitles.map(t => {
            return <div key={t} onClick={() => setSelected(t)} className={selected === t ? 'selected' : ''}>
                <span>{t}</span>
            </div>
        })}
    </div>)
}
