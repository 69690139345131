import React, {useCallback, useMemo} from "react";
import {toPrettyDateStr, toPrettyTimeSince} from "../../prettyDate";
import './lightning-strike-list.css'
import {probabilityToString} from "./probabilityToString";

export default function LightningStrikeList({lightningStrikes, hovering}) {
    const dateGroups = useMemo(() => {
        if (!lightningStrikes) return {}
        const groups = {}
        const k = [...lightningStrikes]
        k.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).forEach(strike => {
            const s = toPrettyDateStr(new Date(strike.timestamp), true)
            let [d, h] = s.split(' ')
            let timeSince = toPrettyTimeSince(new Date(strike.timestamp))

            if (d !== timeSince) {
                d = `${d} (${timeSince})`
            } else {
                d = timeSince
            }

            if (!groups[d]) {
                groups[d] = []
            }
            groups[d].push({...strike, ts: h})
        })
        return groups
    }, [lightningStrikes]);

    return (<table className='lightning-strike-list'>
        <tbody>
        {Object.keys(dateGroups).map((dateTitle, idx) => {
            return [
                <tr key='header'>
                    <th key={dateTitle} colSpan={5} style={{
                        paddingTop: idx === 0 ? '0' : '22px',
                    }}>{dateTitle}</th>
                </tr>,
                ...dateGroups[dateTitle].map(ls => <tr key={ls.id} className={ls.id === hovering ? 'hovering' : ''}>
                    <td>{ls.ts}.{Math.round(ls.nanosecond / 1e6).toString().padStart(3, '0')}</td>
                    <td className={`severity-level-${probabilityToString(ls.probability_of_hit).split(' ').join('-')}`}>{probabilityToString(ls.probability_of_hit)} attachment risk</td>
                    <td>{(Math.round(ls.distance_km * 100)*10)} m</td>

                    {ls.outside_certification &&
                        <td
                            title='Most LPS do not cover strikes outside the EIC range'
                            style={{fontWeight: 'bold'}}>
                            ({Math.round(ls.peak_current*100)/100} kA) Outside IEC cert!
                        </td>
                    }
                    {!ls.outside_certification &&
                        <td
                            title='Most LPS do not cover strikes outside the EIC range'>
                            ({Math.round(ls.peak_current*100)/100} kA) Inside IEC cert
                        </td>
                    }
                </tr>)
            ]
        })}
        </tbody>
    </table>)
}