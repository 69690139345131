import {http} from "../api";

const easyUnpack = ({data}) => data;
export const WdImageApi = {
    get_defect_img: {
        f: function get_defect_img(wd_defect_id) {
            let url = '/wd/images/$wd_defect_id.jpg';
            let parameterNames = ["$wd_defect_id"];
            [wd_defect_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    get_blade_img: {
        f: function get_blade_img(wd_defect_id) {
            let url = '/wd/images/blade/$wd_defect_id.jpg';
            let parameterNames = ["$wd_defect_id"];
            [wd_defect_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
};
