import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {exportAPI} from "../../api";
import WplButton from "../../components/wpl-button/WplButton";
import WplFullpageForm from "../../components/fullpage-form/WplFullpageForm";

export default function DownloadPage(props) {
    const [didDownload, setDidDownload] = useState(false)
    const {export_id} = useParams()

    const downloadFile = useCallback(() => {
        exportAPI.getDownloadLink(export_id).then(url => {
            window.open(url, '_blank');
            setDidDownload(true)
        });
    }, [export_id]);

    useEffect(() => {
        if (!didDownload) return;
        setTimeout(() => {
            window.location = '/'
        }, 5000);

    }, [didDownload])

    return (<WplFullpageForm subtitle='' className='login-page'>
        <h1>Download your export</h1>
        <WplButton value='Download' onClick={downloadFile} />
    </WplFullpageForm>)
}
