import React, {useEffect, useMemo, useState} from 'react';
import './severity-rules-page.css'
import {lightningSeverityAPI} from "../../../../api";
import ClickToShow from "../../../../components/click-to-show/ClickToShow";
import WplInput from "../../../../components/wpl-input/WplInput";
import WplButton from "../../../../components/wpl-button/WplButton";
import SeverityRule from "./severity-rule/SeverityRule";

export default function LightningSeverityRulesPage() {
    const [rulesets, setRulesets] = useState([]);
    const [selectedRuleset, setSelectedRuleset] = useState(null);
    const [selectedRules, setSelectedRules] = useState([]);

    // New ruleset
    const [newRulesetName, setNewRulesetName] = useState('');
    const [newRulesetDescription, setNewRulesetDescription] = useState('');

    // new rule

    const [peakCurrentFrom, setPeakCurrentFrom] = useState('');
    const [peakCurrentTo, setPeakCurrentTo] = useState('');
    const [coversPulse, setCoversPulse] = useState('');

    useEffect(() => {
        lightningSeverityAPI.list_all_rulesets().then(setRulesets)
    }, []);

    // Update groupedRules when selectedRuleset changes
    useEffect(() => {
        if (!selectedRuleset) return;
        lightningSeverityAPI.list_rules_by_ruleset_id(selectedRuleset.id).then(setSelectedRules)
    }, [selectedRuleset]);

    const groupedRules = useMemo(() => {
        const final = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
        }
        selectedRules.forEach(rule => {
            final[rule.severity].push(rule)
        })
        return final
    }, [selectedRules]);

    return (<div className='lightning-severity-rule-page'>
        {!selectedRuleset && <>
            <h1>Severity Rulesets</h1>
            <p>
                A ruleset defines how severe a lightning strike is, if it hits a turbine.
            </p>
            <p>
                This severity is used to know how critical a potential hit is.
            </p>
            <p>
                You can create several severity rulesets if you have different LPS' on your turbines.
            </p>
            <div className='ruleset-list'>
                {rulesets.map(ruleset => <div key={ruleset.id} className='ruleset'
                                              onClick={_ => setSelectedRuleset(ruleset)}>
                    <h2>{ruleset.name}</h2>
                    <p>{ruleset.description}</p>
                </div>)}
            </div>
            <ClickToShow buttonText='New ruleset'>
                <h2>New ruleset</h2>
                <WplInput title='name' value={newRulesetName} onChanged={setNewRulesetName}/>
                <WplInput title='description' value={newRulesetDescription} onChanged={setNewRulesetDescription}/>

                <WplButton value='CREATE' onClick={_ => {
                    lightningSeverityAPI.insert_ruleset(newRulesetName, newRulesetDescription).then(ruleset => {
                        setRulesets(prev => [...prev, ruleset])
                        setSelectedRuleset(ruleset)

                        setNewRulesetName('')
                        setNewRulesetDescription('')
                    })
                }}/>
            </ClickToShow>
        </>}

        {selectedRuleset && <>
            <WplButton value='Rulesets' onClick={_ => setSelectedRuleset(null)} />
            <h1>{selectedRuleset.name}</h1>
            <p>{selectedRuleset.description}</p>

            <div className='severity-rule-table'>
                {Object.keys(groupedRules).map(severity => <div className='column' key={severity}>
                    <h3>LS{severity}</h3>
                    {groupedRules[severity].map(rule => <SeverityRule key={rule.id} rule={rule}/>)}
                    <div className='grower'/>
                    <ClickToShow value='New rule' key={severity} buttonText='New rule'>
                        <h2>New rule for {severity}</h2>

                        <WplInput title='peakCurrentFrom' value={peakCurrentFrom} onChanged={setPeakCurrentFrom}/>
                        <WplInput title='peakCurrentTo' value={peakCurrentTo} onChanged={setPeakCurrentTo}/>
                        <WplInput title='coversPulse'
                                  options={['any', 'first', 'subsequent']}
                                  onlySelectableOptions
                                  value={coversPulse}
                                  onChanged={setCoversPulse}
                        />

                        <WplButton value='CREATE' onClick={_ => {
                            let pulseInt;
                            switch (coversPulse) {
                                case 'first':
                                    pulseInt = 1;
                                    break
                                case 'subsequent':
                                    pulseInt = 2;
                                    break
                                default:
                                    pulseInt = null;
                            }
                            lightningSeverityAPI
                                .insert_rule(selectedRuleset.id, peakCurrentFrom, peakCurrentTo, pulseInt, severity)
                                .then(newRule => {
                                    setSelectedRules(prev => [...prev, newRule])
                                })
                        }}/>
                    </ClickToShow>
                </div>)}
            </div>
        </>}
    </div>)
}
