import {http} from "../api";

const easyUnpack = ({data}) => data;
export const WdWindfarmApi = {
    get_user_windfarms: {
        f: function get_user_windfarms(with_details) {
            let url = '/wd/windfarms/user/list';
            return http.get(url, {params: {with_details}}).then(easyUnpack)
        },
    },
    list_all_windfarms: {
        f: function list_all_windfarms() {
            let url = '/wd/windfarms/list';
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    get_list_of_turbines_for_windfarm: {
        f: function get_list_of_turbines_for_windfarm(windfarm_id) {
            let url = '/wd/windfarms/$windfarm_id/turbines';
            let parameterNames = ["$windfarm_id"];
            [windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        },
    },
    get_by_search: {
        f: function get_by_search({windfarm_id = null, windfarm_name = null}) {
            let url = '/wd/windfarms/search';
            return http.get(url, {params: {windfarm_id, windfarm_name}}).then(easyUnpack)
        },
    },
    create_windfarm: {
        f: function create_windfarm(name, customer, {report_id = null, country = null}) {
            let url = '/wd/windfarms';
            return http.post(url, {name, customer, report_id, country}).then(easyUnpack)
        },
    },
    create_windfarm_details: {
        f: function create_windfarm_details(name, customer, oem, model, production, hub_height, blade_length, blade_type, commisioning_year, country) {
            let url = '/wd/windfarms/v2';
            return http.post(url, {
                name,
                customer,
                oem,
                model,
                production,
                hub_height,
                blade_length,
                blade_type,
                commisioning_year,
                country
            }).then(easyUnpack)
        },
    },
    create_turbine_for_windfarm: {
        f: function create_turbine_for_windfarm(windfarm_id, turbine_name, inspection_date) {
            let url = '/wd/windfarms/$windfarm_id/turbines';
            let parameterNames = ["$windfarm_id"];
            [windfarm_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {turbine_name, inspection_date}).then(easyUnpack)
        },
    },
};
