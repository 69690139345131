import React from 'react'
import {Navigate} from "react-router-dom";
import {logout} from "./user-service";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import './private-route.css'

export default function RequireAuth({children, useMaxWidth=true, noHeader=false, noFooter=false, extraClass=null}) {
    const expires = localStorage.getItem('tokenExpires')

    if (new Date(expires) < new Date()) {
        logout()
        return <Navigate to='/login' />
    }

    return <div className={`private-route ${useMaxWidth ? 'max-width' : ''} ${extraClass ? extraClass : ''}`}>
    {!noHeader && <Header/>}
        {children}
        <div className='spacer' />
        {!noFooter && <Footer/>}
    </div>
}
