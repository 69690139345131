import React, {useState} from 'react'
import './data-management.css'
import WplMultiSelect from "../../components/wpl-multi-select/wpl-multi-select";
import '../../components/wpl-table/wpl-table.css'
import DmGoose from "./goose/DmGoose";
import DmGooseToWD from "./DmGooseToWD";
import DmSpecialDefect from "./DmSpecialDefect";
import DmSegmentation from "./segmentation/DmSegmentation";
import AdminHeader from "../admin/admin-header/AdminHeader";
import InternalVideoDm from "./internal-video-dm/InternalVideoDm";

export default function DataManagement() {
    const [selectedDataMethod, setSelectedDataMethod] = useState('Parse Internal')

    const cases = {
        'Parse Internal': <InternalVideoDm />,
        'Load into Goose': <DmGoose />,
        'Move from goose to wind diagnostics': <DmGooseToWD />,
        'Special Defect': <DmSpecialDefect />,
        'Segmentation': <DmSegmentation />,
    }

    return (<div className='data-management'>
        <AdminHeader />
        <WplMultiSelect
            options={Object.keys(cases)}
            closeOnSelect={true}
            singleSelect={true}
            onSelected={setSelectedDataMethod}
            title={'Select your action'}
            selected={selectedDataMethod}
        />
        <div className='sections'>
            {cases[selectedDataMethod]}
        </div>
    </div>)
}
