import React, {useCallback, useEffect, useState} from 'react';
import {lightningAPI} from "../../../api";
import './lightning-windfarm-settings.css'
import WplInput from "../../../components/wpl-input/WplInput";
import WplButton from "../../../components/wpl-button/WplButton";
import Card from "../../../components/card/Card";
import {useDispatch, useSelector} from "react-redux";
import {
    createUserAction,
    selectAllLightningTurbinesByWindfarm
} from "../../../flow/lightningFlow";
import {toPrettyTimeSince} from "../../../prettyDate";

export default function LightningWindfarmSettings() {
    const [windfarms, setWindfarms] = useState([]);
    const [selectedWindfarm, setSelectedWindfarm] = useState('');

    const [selectedDate, setSelectedDate] = useState('');

    const dispatch = useDispatch();

    const turbines = useSelector(selectAllLightningTurbinesByWindfarm(selectedWindfarm ? selectedWindfarm.id : null))

    useEffect(() => {
        lightningAPI.listLightningWindfarms().then(setWindfarms)
    }, []);

    useEffect(() => {
        if (!selectedWindfarm) return;
        setSelectedDate('');
    }, [selectedWindfarm]);

    return (<div className='lightning-windfarm-settings'>
        <WplInput
            title='Windfarm'
            value={selectedWindfarm ? selectedWindfarm.name : ''}
            onChanged={n => setSelectedWindfarm(windfarms.find(wf => wf.name === n))}
            options={windfarms.map(wf => wf.name)}
            onlySelectableOptions
        />
        {selectedWindfarm && turbines.length && <Card>
            <h2>Create global 'accept risk' user action</h2>
            <WplInput type='date' title='Date' value={selectedDate} onChanged={setSelectedDate} />
            <WplButton big value='SUBMIT' disabled={!selectedDate} onClick={_ => {
                if (window.confirm(`Create ${turbines.length} new user actions?`)) {
                    dispatch(createUserAction({
                        turbine_ids: turbines.map(t => t.id),
                        action: 'acceptable_risk',
                        user_comment: '',
                        target_date: selectedDate
                    }))
                }
            }} />

            <div className='setting-turbine-wrapper'>
                {turbines.map(t => <div className='turbine' key={t.id}>
                    <label>{t.name}</label>
                    <label>{toPrettyTimeSince(new Date(t.last_trigger_date))}</label>
                </div>)}
            </div>

        </Card>}
    </div>)
}
