import React, { useCallback, useState } from 'react'
import { datamanagementAPI } from "../../../api";
import WplButton from "../../../components/wpl-button/WplButton";
import './dm-sulzer.css'
import Folder from "../../../components/folder/Folder";
import WplTabs from "../../../components/wpl-tabs/WplTabs";
import ParsedObject from "../ParsedObject";

export default function DmSulzerFileSelection({ report_id }) {
    const [overview, setOverview] = useState({})
    const [folderStructure, setFolderStructure] = useState([])
    const [currentTab, setCurrentTab] = useState('Files')
    const [parsedXml, setParsedXml] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState([])
    const [parsedObject, setParsedObject] = useState({});

    const fetchFolderStructure = useCallback(() => {
        setLoading(true)
        datamanagementAPI
            .getFolderStructure('', ['.xml'])
            .then(folders => {
                console.log('folder', folders)
                setLoading(false)
                setFolderStructure(folders)
            })
    }, [])

    function fileClicked(pathClicked) {
        if (pathClicked in parsedXml) {
            setParsedXml(prev => {
                let res = { ...prev }
                delete res[pathClicked]
                return res
            })
            return;
        }
        setLoadingFiles(prev => [...prev, pathClicked])
       
        datamanagementAPI.parseSulzer(pathClicked, pathClicked, report_id).then(res => {
                setParsedXml(prev => ({ ...prev, [pathClicked]: res }))
                setLoadingFiles(prev => prev.filter(p => p !== pathClicked))
            })
    }

    if (loading) return <div className='dm-sulzer'>Loading Sulzer data</div>

    return (<div className='dm-sulzer'>
        <WplButton onClick={fetchFolderStructure} value='parse' />
        <WplTabs selected={currentTab} setSelected={setCurrentTab} tabTitles={['Files', 'Edit & Import']} />

        <div style={{ display: currentTab === 'Files' ? 'block' : 'none' }}>
            <Folder folder={folderStructure} prefix='' onFileClicked={fileClicked} renderFileCallback={fileName => {
                if (loadingFiles.indexOf(fileName) !== -1) return 'Loading...'
                if (!(fileName in parsedXml)) return;
                const parsed = parsedXml[fileName]
                
                let dbData = ''
                if (!(parsed.windfarm in overview)) {
                    dbData = <div className='blades'>No existing windfarm with this name.</div>
                } else if (!(parsed.turbine_name in overview[parsed.windfarm])) {
                    dbData = <div className='blades'>Found a windfarm - but no turbine for this name.</div>
                } else {
                    const blades = overview[parsed.windfarm][parsed.turbine_name].blades
                    dbData = <div className='blades'>
                        <h4>Data found on this windfarm and turbine in the database</h4>
                        {blades.sort((a, b) => a.name.localeCompare(b.name)).map(blade => <div key={blade.name}>
                            <p>{blade.name}: {blade.surfaces.map(surface =>
                                <span>{`${surface.surface} (${surface.images.length})`}</span>)}</p>
                        </div>
                        )}
                    </div>
                }

                return <div className='turbine-data'>
                    <div className='blades'>
                        <h4>Data found in this XML file</h4>
                        {parsed.blades.sort((a, b) => a.name.localeCompare(b.name)).map(blade => <div key={blade.name}>
                            <p>{blade.name}: {blade.surfaces.map(surface =>
                                <span>{`${surface.surface} (${surface.images.length})`}</span>)}</p>
                        </div>
                        )}
                        <WplButton value='Parse' onClick={_ => {
                            setParsedObject(parsed)
                            setCurrentTab('Edit & Import')
                        }} />
                    </div>
                    {overview && dbData}
                </div>
            }} />
        </div>

        {currentTab === 'Edit & Import' &&
            <ParsedObject parsedObject={parsedObject} setParsedObject={setParsedObject} />}
    </div>)

}
