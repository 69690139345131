import React, {useCallback, useState, useEffect} from 'react'
import WplTabs from "../../../components/wpl-tabs/WplTabs";
import {pictureAPI} from "../../../api";
import SimpleTable from "../../../components/simple-table/SimpleTable";
import WplInput from "../../../components/wpl-input/WplInput";
import WplButton from "../../../components/wpl-button/WplButton";
import './dm-segmentation.css'
import DmSegmentationRfModels from "./DmSegmentationRfModels";
import DmSegmentationMask from "./DmSegmentationMask";
import {heavySegmentationAPI} from "../../../heavy_duty_api";

export default function DmSegmentation(props) {
    const initialFilters = {windfarm: 'Greater Gabbard', turbine: '', blade: '', surface: ''};

    const [tab, setTab] = useState('Unet backgrounds');
    const [allWindfarms, setAllWindfarms] = useState({});

    const [pictures, setPictures] = useState([]);

    const [predictingBackgrounds, setPredictingBackgrounds] = useState({});
    const [predictBatchSize, setPredictBatchSize] = useState(3);

    const [unetFilters, setUnetFilters] = useState(initialFilters);

    function uniqueIdentifierFromFilter(filter) {
        return ['windfarm', 'turbine', 'blade', 'surface'].map(f => filter[f]).join('-')
    }

    useEffect(() => {
        pictureAPI.listUniqueFilters().then(awf => {
            let d = {}
            awf.forEach(wf => d[wf.windfarm] = wf)
            setAllWindfarms(d)
        })
    }, []);

    const fetchPictures = useCallback(() => {
        console.log(unetFilters)
        pictureAPI.countPictures(unetFilters).then(ps => {
            const ids = ps.map(uniqueIdentifierFromFilter)
            setPredictingBackgrounds(ids.reduce((p, c) => ({...p, [c]: []}), {}))
            setPictures(ps)
        });
    }, [unetFilters, setPictures, setPredictingBackgrounds]);

    const startBackground = useCallback(row => {
        const row_id = uniqueIdentifierFromFilter(row)

        let {picture_ids, background_picture_ids} = row;
        if (picture_ids === undefined) picture_ids = [];
        if (background_picture_ids === undefined) background_picture_ids = [];

        const loading = predictingBackgrounds[row_id];
        console.log({picture_ids, background_picture_ids, loading})

        const ps = picture_ids
            .filter(p => background_picture_ids.indexOf(p) === -1)
            .filter(p => loading.indexOf(p) === -1)

        const chunks = Array(Math.ceil(ps.length/predictBatchSize)).fill().map(_ => ps.splice(0, predictBatchSize))

        const confirmed = window.confirm(`Start ${chunks.length} requests with ${predictBatchSize} pictures in each request?`);

        if (!confirmed) return;

        chunks.forEach((ch, idx) => {
            setPredictingBackgrounds(prev => ({...prev, [row_id]: [...prev[row_id], ...ch]}))
            heavySegmentationAPI.unetPredictMultipleBackground(ch).then(_ => {
                setPictures(prev => prev.map(p => p.id === row.id ? {...p, background_picture_ids: [...p.background_picture_ids, ...ch]} : p))
                setPredictingBackgrounds(prev => ({...prev, [row_id]: prev[row_id].filter(pr => ch.indexOf(pr) === -1)}))
            });
        });
    }, [predictBatchSize, predictingBackgrounds, setPredictingBackgrounds]);

    function renderUnetBackgrounds() {
        if (tab !== 'Unet backgrounds') return;
        if (Object.keys(allWindfarms).length === 0) return;
        return <div>
            <div className='unet-inp-wrapper'>
                <WplInput title='windfarm' value={unetFilters.windfarm}
                          options={Object.keys(allWindfarms)} onlySelectableOptions={true}
                          onChanged={v => setUnetFilters(p => ({...p, 'windfarm': v}))} clearable={true}/>

                {unetFilters.windfarm && <WplInput title='turbine' value={unetFilters.turbine}
                                                   options={allWindfarms[unetFilters.windfarm].turbines}
                                                   onlySelectableOptions={true}
                                                   onChanged={v => setUnetFilters(p => ({...p, 'turbine': v}))}
                                                   clearable={true}/>}

                {unetFilters.turbine && <WplInput title='blade' value={unetFilters.blade}
                                                  options={allWindfarms[unetFilters.windfarm].blades}
                                                  onlySelectableOptions={true}
                                                  onChanged={v => setUnetFilters(p => ({...p, 'blade': v}))}
                                                  clearable={true}/>}

                {unetFilters.blade && <WplInput title='surface' value={unetFilters.surface}
                                                options={allWindfarms[unetFilters.windfarm].surfaces}
                                                onlySelectableOptions={true}
                                                onChanged={v => setUnetFilters(p => ({...p, 'surface': v}))}
                                                clearable={true}/>}
                <div style={{flexGrow: 1}}>&nbsp;</div>
                <WplButton value='Fetch pictures' onClick={fetchPictures}/>
            </div>
            <div className='unet-inp-wrapper'>
                <WplInput type='number' value={predictBatchSize} onChanged={setPredictBatchSize} title='batch'/>
            </div>

            <SimpleTable rows={pictures}
                         headers={['Windfarm', 'Turbine', 'Blade', 'Surface', 'Missing', 'Loading', 'Done']}
                         renderRow={r => {
                             const row_id = uniqueIdentifierFromFilter(r)
                             const missing = r.picture_ids.filter(p_id => r.background_picture_ids.indexOf(p_id) === -1)
                             return <tr key={row_id}>
                                 <td>{r.windfarm}</td>
                                 <td>{r.turbine}</td>
                                 <td>{r.blade}</td>
                                 <td>{r.surface}</td>
                                 <td>{missing.length}</td>
                                 <td>{predictingBackgrounds[row_id] ? predictingBackgrounds[row_id].length : 0}</td>
                                 <td>{r.background_picture_ids ? r.background_picture_ids.length : 0}</td>
                                 <td><WplButton value='Start predict' onClick={_ => startBackground(r)} disabled={missing.length === 0}/></td>
                             </tr>
                         }}/>
        </div>
    }

    return (<div className='dm-segmentation'>
        <WplTabs tabTitles={['Unet backgrounds', 'RF models', 'Generate Masks']} selected={tab} setSelected={setTab}/>
        {renderUnetBackgrounds()}
        {tab === 'RF models' && <DmSegmentationRfModels/>}
        {tab === 'Generate Masks' && <DmSegmentationMask/>}
    </div>)
}
