import React, {useState, useEffect} from 'react'
import './blade-defect-table.css'
import WplTable from "../wpl-table/WplTable";
import WplTabs from "../wpl-tabs/WplTabs";
import Card from "../card/Card";

export default function BladeDefectTable({defects}) {
    const [selectedBlade, setSelectedBlade] = useState('')
    const [selectedDefect, setSelectedDefect] = useState('');
    const [blades, setBlades] = useState([])

    useEffect(() => {
        if (defects.length === 0) return;

        let uniqueBlades = defects.map(d => d.blade);
        uniqueBlades = uniqueBlades
            .filter((b, idx) => uniqueBlades.indexOf(b) === idx)
            .sort((a, b) => a.localeCompare(b));

        setBlades(uniqueBlades);
        setSelectedBlade(uniqueBlades[0]);
    }, [defects, setSelectedBlade]);

    if (defects.length === 0) return null;

    return (<Card className='blade-defect-table'>
        <h1>Grouped by blade</h1>
        <WplTabs tabTitles={blades} selected={selectedBlade} setSelected={setSelectedBlade} />
        <WplTable className='blade-table'
                  defects={defects.filter(d => d.blade === selectedBlade)}
                  setSelectedDefect={setSelectedDefect}
                  selectedDefect={selectedDefect}
                  inlineSelectable={true}
        />
    </Card>)
}
