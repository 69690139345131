import React, {useState, useCallback} from 'react';
import './login.css'
import {userService} from "../../auth/user-service";
import WplFullpageForm from "../../components/fullpage-form/WplFullpageForm";
import WplInput from "../../components/wpl-input/WplInput";
import WplButton from "../../components/wpl-button/WplButton";

import loadingSvg from '../../assets/loading.svg'
import {useKeyboardShortcut} from "../../components/UseKeyboardShortcut";
import {useNavigate} from "react-router-dom";

export default function Login(props) {
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const doLogin = useCallback(() => {
        setLoading(true);
        userService.login(user, password)
            .then(r => {
                setLoading(false);

                const redir_path = localStorage.getItem('redirAfterLogin')
                if (redir_path && redir_path !== '/login') {
                    console.log('Redirecting back to where we came from:', redir_path)
                    navigate(redir_path)
                } else {
                    navigate('/')
                }
            }).catch(e => {
            setLoading(false);
            setErrorMsg('Wrong password or username')
        })
    }, [user, password, navigate]);

    useKeyboardShortcut(['Enter'], doLogin)

    return (<WplFullpageForm subtitle='Sign in to Wind Diagnostics' className='login-page'>
        <WplInput title={'Username or email'} type={'text'} value={user} onChanged={setUser} />
        <WplInput title={'Password'} type={'password'} value={password} onChanged={setPassword} />
        <p className='error'>{errorMsg}</p>
        <WplButton value={'Sign in'} loading={loading} onClick={doLogin}/>
        <div className={`overlay ${loading ? 'loading' : ''}`}><img src={loadingSvg} alt='loading'/></div>
    </WplFullpageForm>)
}
