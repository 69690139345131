import React, {useMemo} from 'react';
import './data-coverage-timeline.css'
import {parseStandardWplDate, toPrettyDateStr, toPrettyTimeSince} from "../../../prettyDate";

export default function DataCoverageTimeline({coverage, onClickCoverage}) {
    const allDates = useMemo(() => {
        return coverage.map(c => ([
            parseStandardWplDate(c.from_timestamp),
            parseStandardWplDate(c.to_timestamp),
        ])).flat().sort((a, b) => a - b)
    }, [coverage]);

    const {timeline_from_date, msPrDay, dayWidth} = useMemo(() => {
        const msPrDay = 24 * 3600 * 1000

        const timeline_from_date = allDates[0]
        const timeline_to_date = allDates[allDates.length - 1]

        const totalDays = (timeline_to_date - timeline_from_date) / msPrDay;
        const dayWidth = 100/totalDays;

        return {timeline_from_date, msPrDay, dayWidth}
    }, [allDates]);

    return (
        <div className='coverage'>
            {coverage.map(c => {
                const from_date = new Date(c.from_timestamp)
                const to_date = new Date(c.to_timestamp)

                const daysFromOuter = (from_date - timeline_from_date)/msPrDay
                const coverDays = (to_date-from_date)/msPrDay

                return <div className={`time-span ${c.covered ? 'covered' : 'not-covered'}`}
                            key={from_date.toString()}
                            onClick={_ => {
                                if (!onClickCoverage) return;
                                onClickCoverage(c)
                            }}
                            style={{
                                left: `${Math.floor(daysFromOuter*dayWidth*15)/15}%`,
                                width: `${Math.floor(coverDays*dayWidth*15)/15}%`,
                            }}>
                    <div className='info-box'>
                        <label><b>From:</b></label>
                        <p>{toPrettyDateStr(from_date, true)} ({toPrettyTimeSince(from_date)})</p>
                        <label><b>To:</b></label>
                        <p>{toPrettyDateStr(to_date, true)} ({toPrettyTimeSince(to_date)})</p>
                        {c.covered ? <p>Processed</p> : <p><b>Not</b> processed</p>}
                    </div>
                </div>
            })}
        </div>)
}
