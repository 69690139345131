import React, {useMemo, useState} from 'react'
import './turbine-scenario-list.css'
import {toPrettyTimeSince} from "../../../prettyDate";
import LightningStrikeList from "../LightningStrikeList";
import ShowLessMore from "../../../components/show-lessmore/ShowLessMore";

export default function TurbineScenarioList({turbine, children}) {
    const [showLightning, setShowLightning] = useState(false);

    console.log(turbine)
    const triggeredScenarios = useMemo(() => {
        if (!turbine) return {};
        const triggeredScenarios = {
            'no-triggers': {
                title: 'Other',
                description: 'These strikes had a probability >0% of attachment, but were not covered by any of your scenarios.',
                strikes: [],
                timestamp: null,
            }
        }
        turbine.turbine_lightning.forEach(strike => {
            if (strike.scenarios === null) {
                if (triggeredScenarios['no-triggers'].timestamp === null || triggeredScenarios['no-triggers'].timestamp < new Date(strike.timestamp)) {
                    triggeredScenarios['no-triggers'].timestamp = new Date(strike.timestamp)
                }
                triggeredScenarios['no-triggers'].strikes.push(strike)
            }
            else {
                strike.scenarios.map(scenario => {
                    const s = new Date(strike.timestamp)
                    if (!triggeredScenarios[scenario.scenario_id])
                        triggeredScenarios[scenario.scenario_id] = {
                            ...scenario,
                            strikes: [],
                            timestamp: s
                        }
                    if (s > triggeredScenarios[scenario.scenario_id].timestamp) {
                        triggeredScenarios[scenario.scenario_id].timestamp = s
                    }
                    triggeredScenarios[scenario.scenario_id].strikes.push(strike)
                })
            }
        })
        return triggeredScenarios
    }, [turbine]);

    return (
        <div className='scenario-list'>
            {/*<h2>Triggered scenarios</h2>*/}
            {Object.keys(triggeredScenarios).map(scenario_id => {
                const scen = triggeredScenarios[scenario_id]
                if (scen.strikes.length === 0) return;
                return <div key={scenario_id} className='scenario'>
                    <div className='horizontal title-wrapper'>
                        <h3>{scen.strikes.length} x {scen.title}</h3>
                        <h4>{toPrettyTimeSince(scen.timestamp)}</h4>
                    </div>
                    <b>{scen.immediate_action}</b>
                    <p>{scen.description}</p>
                    <ShowLessMore showMore={showLightning === scenario_id} setShowMore={v => setShowLightning(v ? scenario_id : null)} />
                    {showLightning === scenario_id && <LightningStrikeList lightningStrikes={scen.strikes}/>}
                </div>
            })}
            {children}
        </div>)
}
