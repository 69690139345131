import React, {useState} from 'react'
import './folder.css'

export default function Folder({
                                   folder,
                                   onFileClicked,
                                   prefix = '',
                                   renderFileCallback,
                                   renderFolderTitle = null,
                                   hideFileTitle = false,
                               }) {
    const [isOpen, setIsOpen] = useState('');
    function folderClicked(name) {
        setIsOpen(prev => (
            prev.indexOf(name) === -1) ?
            [...prev, name] :
            prev.filter(p => p !== name)
        )
    }

    return (<>
        {Object.keys(folder).map((name) => {
            const subFolder = folder[name]

            const isFile = subFolder === 'f'
            if (isFile) {
                return <div key={name}>
                    {!hideFileTitle && <p onClick={_ => onFileClicked(prefix + name)}>{name}</p>}
                    {renderFileCallback && renderFileCallback(prefix + name)}
                </div>
            }

            return <div className='folder' key={name}>
                {renderFolderTitle ?
                    renderFolderTitle(name, prefix + name, folderClicked) :
                    <h4 onClick={_ => folderClicked(name)}>{name}/</h4>}
                {isOpen.indexOf(name) !== -1 && <Folder
                    onFileClicked={onFileClicked}
                    renderFolderTitle={renderFolderTitle}
                    folder={subFolder}
                    prefix={prefix + name + '/'}
                    renderFileCallback={renderFileCallback}
                    hideFileTitle={hideFileTitle}
                />}
            </div>
        })}
    </>)
}
