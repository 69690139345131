import React, {useState, useEffect, useCallback, useMemo} from 'react'
import {datamanagementAPI, reportAPI} from "../../api";
import DmWindDiagnostics from "./DmWindDiagnostics";
import './dm-goose-to-wd.css'
import WplInput from "../../components/wpl-input/WplInput";
import {heavyDatamanagementAPI} from "../../heavy_duty_api";

export default function DmGooseToWD() {
    const [wdData, setWdData] = useState({})
    const [selectedReportName, setSelectedReport] = useState('')
    const [reports, setReports] = useState([])

    useEffect(() => {
        reportAPI.listReports().then(setReports)
    }, [])

    const parseReport = useCallback((reportName) => {
        if (reportName === '') return;
        setSelectedReport(reportName)
        setWdData({})

        const report = reports.find(r => r.title === reportName)
        heavyDatamanagementAPI.parseReport(report.id).then(setWdData)
        console.log('hello?')
    }, [reports]);

    const report_id = useMemo(() => {
        const report = reports.find(r => r.title === selectedReportName)
        if (report) return report.id
        return null
    }, [selectedReportName, reports])

    return (
        <div className='system-section'>
            <h1>Move report into Wind Diagnostics</h1>
            <div className='input-wrap'>
                <WplInput
                    title='Select a report'
                    value={selectedReportName}
                    onChanged={parseReport}
                    options={reports.map(r => r.title)}
                    onlySelectableOptions
                />
            </div>
            {Object.keys(wdData).length > 0 && <DmWindDiagnostics initialWdData={wdData} report_id={report_id} />}
        </div>)
}
