import React, {useEffect, useRef, useState} from 'react'
import './burger-menu.css'
import icon from '../../assets/burger-menu-icon.png'
import {useNavigate} from "react-router-dom";

export default function BurgerMenu({menuItems, menuLinks, images}) {
    const [open, setOpen] = useState(false);
    const [hovering, setHovering] = useState(false)

    const navigate = useNavigate()
    const inputRef = useRef();

    useEffect(() => {
        if (!open) return;

        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [open, inputRef]);

    return (<div
        onMouseEnter={_ => setHovering(true)}
        onMouseLeave={_ => setHovering(false)}
        className={'burger-menu'} onClick={_ => setOpen(p => !p)}>
        {open && <div className='open-menu'>
            <input ref={inputRef} onBlur={e => {
                setOpen(hovering)
            }}/>
            {menuItems.map((mi, idx) =>
                <div className='menu-item'
                     key={mi} onClick={_ => navigate(menuLinks[idx])}>
                    <img src={images[idx]} alt={mi}/>
                    <p>{mi}</p>
                </div>
            )}
        </div>}
        <img src={icon} alt='closed-menu' className='closed-menu'/>
    </div>)
}
