import './self-service.css'
import WplFileUpload from "../../components/wpl-file-upload/WplFileUpload";
import WplButton from "../../components/wpl-button/WplButton";
import {datamanagementAPI, defectAPI, windfarmAPI} from "../../api";
import React, {useState, useEffect, useCallback} from 'react'
import WplInput from "../../components/wpl-input/WplInput";
import WplPopup from "../../components/wpl-popup-window/WplPopup";
import WplForm from "../../components/wpl-form/WplForm";
import SimpleTable from "../../components/simple-table/SimpleTable";
import check from "../../assets/img/done-24px.svg";
import exclamation_mark from "../../assets/img/exclamation-mark.svg";

export default function SelfService() {
    const [existingDefects, setExistingDefects] = useState([]);
    const [parsedDefects, setParsedDefects] = useState([]);
    const [createdDefectIds, setCreatedDefectIds] = useState([]);

    const [inspectionDate, setInspectionDate] = useState('');
    const [windfarm, setWindfarm] = useState('');
    const [turbine, setTurbine] = useState('');

    const [allWindfarms, setAllWindfarms] = useState({});

    const [createTurbineOpen, setCreateTurbineOpen] = useState(false);
    const [createTurbineLoading, setCreateTurbineLoading] = useState(false);
    const [turbineInfo, setTurbineInfo] = useState({name: ''});

    const [creationLoading, setCreationLoading] = useState(false);

    const [createWindfarmOpen, setCreateWindfarmOpen] = useState(false);
    const [createWindfarmLoading, setCreateWindfarmLoading] = useState(false);
    const [windfarmInfo, setWindfarmInfo] = useState({
        name: '',
        customer: '',
        oem: '',
        model: '',
        production: '',
        hub_height: '',
        blade_length: '',
        blade_type: '',
        commisioning_year: '',
        country: '',
    });
    const [overrideErrors, setOverrideErrors] = useState(false);
    const [windfarmErrors, setWindfarmErrors] = useState({});
    const [uploadedImages, setUploadedImages] = useState({});

    const uploadedExcelFile = useCallback((filename, data) => {
        console.log('Uploaded EXCEL file to WD with name', filename)

        datamanagementAPI.parseSelfServiceDefectExcel(filename, data).then((defects) => {
            setParsedDefects(defects)
        })
    }, [setParsedDefects]);

    const uploadedImageFile = useCallback((filename, data) => {
        console.log('Uploaded IMAGE file to WD with name', filename)
        setUploadedImages(p => ({...p, [filename]: data}))
    }, [setUploadedImages]);

    const fetchUniqueFilters = useCallback(() => {
        return windfarmAPI.getWindfarms(true).then(wfs => {
            let d = {};
            wfs.forEach(wf => d[wf.name] = wf);
            setAllWindfarms(d)
            console.log('ALL wind farms', d)
        })
    }, []);

    const createDefects = useCallback(() => {
        setCreationLoading(true);
        const selectedTurbine = allWindfarms[windfarm].turbines.find(t => t.name === turbine && t.inspection_date === inspectionDate)
        Promise.all(parsedDefects.map(defect => {
            if (!uploadedImages[defect.small_path] || !uploadedImages[defect.large_path]) return null;
            return defectAPI.createDefect({
                turbine_id: selectedTurbine.id,
                defect: {
                    ...defect,
                    defect_type: defect.type,
                },
                image_source: 'local',
                defect_image_data: uploadedImages[defect.small_path],
                blade_image_data: uploadedImages[defect.large_path],
            }).then(res => {
                if (res.error) {
                    console.log(res.fields)
                    setWindfarmErrors(res.fields)
                } else {
                    setCreatedDefectIds(p => ([...p, defect.report_defect_id]))
                }
            })
        })).then(_ => {
            setCreationLoading(false);
        })
    }, [inspectionDate, turbine, windfarm, uploadedImages, allWindfarms, setCreatedDefectIds, parsedDefects, setCreationLoading]);

    useEffect(() => {
        fetchUniqueFilters()
    }, [fetchUniqueFilters]);

    useEffect(() => {
        if (!inspectionDate || !turbine) return;

        let selectedTurbine = allWindfarms[windfarm].turbines.find(t => t.inspection_date === inspectionDate && t.name === turbine)
        console.log('SELECTED:', {selectedTurbine})
        if (!selectedTurbine) return;

        defectAPI.getTurbineDefects(selectedTurbine.id).then(defects => {
            console.log('Got defects', {defects, parsedDefects})
            setExistingDefects(defects)
        })
    }, [allWindfarms, setExistingDefects, windfarm, inspectionDate, turbine, parsedDefects])

    function renderInspectionAvaiableDates() {
        if (!windfarm) return;

        let inspection_dates = allWindfarms[windfarm].turbines.map(t => t.inspection_date)
        inspection_dates = inspection_dates.filter((date, idx) => inspection_dates.indexOf(date) === idx)

        const createNewInput = <WplInput title='Set inspection date' disabled={!windfarm} value={inspectionDate}
                                         onChanged={v => {
                                             setInspectionDate(v)
                                             setTurbine('')
                                         }} type='date'/>
        if (inspection_dates.length <= 0) {
            return createNewInput
        }

        return [<h4 key='title'>Select an existing inspection date or create a new one</h4>,
            <div key='not-title' className='select-or-new'>
                <div className='available-inspection-dates'>
                    <b>Existing</b>
                    {inspection_dates.map((date, idx) =>
                        <div key={date} onClick={_ => {
                            setInspectionDate(date);
                            setTurbine('');
                            setParsedDefects([]);
                            setUploadedImages({});
                        }} className={`available-date ${date === inspectionDate ? 'selected' : ''}`}>{idx + 1} - {date}
                        </div>)}
                    {createNewInput}
                </div>
            </div>
        ]
    }

    const hasErrors = parsedDefects.some(pd =>
        Object.keys(pd.parse_errors).length > 0
        || uploadedImages[pd.small_path] === undefined
        || createdDefectIds.indexOf(pd.report_defect_id) !== -1
    )

    return (<div className='self-service'>
        <WplPopup showPopup={createWindfarmOpen} closePopup={setCreateWindfarmOpen} className='create-popup'>
            <WplForm>
                <h2>Creating a windfarm</h2>
                <WplInput value={windfarmInfo.name}
                          error={windfarmErrors.name} title='Name'
                          onChanged={v => setWindfarmInfo(p => ({...p, name: v}))}/>

                <WplInput value={windfarmInfo.customer}
                          error={windfarmErrors.customer} title='Customer'
                          onChanged={v => setWindfarmInfo(p => ({...p, customer: v}))}/>

                <h3>Turbine Information</h3>
                <p>Information about the turbines that are in this windfarm</p>

                <WplInput value={windfarmInfo.oem}
                          error={windfarmErrors.oem}
                          title='OEM' onChanged={v => setWindfarmInfo(p => ({...p, oem: v}))}/>


                <WplInput value={windfarmInfo.model}
                          error={windfarmErrors.model}
                          title='Model'
                          onChanged={v => setWindfarmInfo(p => ({...p, model: v}))}
                />

                <WplInput value={windfarmInfo.production}
                          error={windfarmErrors.production}
                          title='Production (in mega watt)' type='number'
                          onChanged={v => setWindfarmInfo(p => ({...p, production: v}))}
                />

                <WplInput value={windfarmInfo.hub_height}
                          error={windfarmErrors.hub_height}
                          title='Hub Height' type='number'
                          onChanged={v => setWindfarmInfo(p => ({...p, hub_height: v}))}
                />

                <WplInput value={windfarmInfo.blade_length}
                          error={windfarmErrors.blade_length}
                          title='Blade Length' type='number'
                          onChanged={v => setWindfarmInfo(p => ({...p, blade_length: v}))}
                />

                <WplInput value={windfarmInfo.blade_type}
                          error={windfarmErrors.blade_type}
                          title='Blade Type'
                          onChanged={v => setWindfarmInfo(p => ({...p, blade_type: v}))}
                />

                <WplInput value={windfarmInfo.commisioning_year}
                          error={windfarmErrors.commisioning_year}
                          title='Commisioning year'
                          onChanged={v => setWindfarmInfo(p => ({...p, commisioning_year: v}))}
                />

                <WplInput value={windfarmInfo.country}
                          error={windfarmErrors.country}
                          title='Country'
                          onChanged={v => setWindfarmInfo(p => ({...p, country: v}))}
                />

                <WplButton value='Submit' loading={createWindfarmLoading} onClick={_ => {
                    setCreateWindfarmLoading(true)
                    windfarmAPI.createWindfarmWithDetails(windfarmInfo).then(res => {
                        setCreateWindfarmLoading(false)
                        if (res.error) {
                            console.log(res.fields)
                            setWindfarmErrors(res.fields)
                        } else {
                            fetchUniqueFilters().then(_ => {
                                setCreateWindfarmOpen(false);
                                setWindfarm(windfarmInfo.name);
                                setInspectionDate('');
                                setParsedDefects([]);
                                setUploadedImages({});
                            })
                        }
                    })
                }}/>
            </WplForm>
        </WplPopup>
        <WplPopup showPopup={createTurbineOpen} closePopup={setCreateTurbineOpen} className='create-popup'>
            <WplForm>
                <h2>Creating a turbine</h2>
                <WplInput value={turbineInfo.name} title='Name'
                          onChanged={v => setTurbineInfo(p => ({...p, name: v}))}/>

                <WplButton value='Submit' loading={createTurbineLoading} onClick={_ => {
                    setTurbine(turbineInfo.name)

                    const wf = allWindfarms[windfarm]

                    setCreateTurbineLoading(true)
                    windfarmAPI.createTurbineForWindfarm(wf.id, turbineInfo.name, inspectionDate).then(new_id => {
                        setCreateTurbineOpen(false)
                        setCreateTurbineLoading(false)
                        setAllWindfarms(p => ({
                            ...p,
                            [wf.name]: {
                                ...wf,
                                turbines: [...wf.turbines, {
                                    name: turbineInfo.name,
                                    id: new_id,
                                    inspection_date: inspectionDate
                                }]
                            }
                        }))
                    })
                }}/>
            </WplForm>
        </WplPopup>
        <h1>Self Service</h1>
        <div className='split-wrap'>
            <div className='split' style={{maxWidth: '550px'}}>
                <h2>Download and fill out a template</h2>
                <div>
                    <h4>Excel</h4>
                    <p>
                        You will download a sample excel sheet to fill with information about defects on your turbines.
                    </p>
                    <h4>Images</h4>
                    <p>
                        There will also be a folder named 'images' - put the images in this folder that are related to
                        the defects in the excel sheet.
                        Make sure the names of the images and the names of the defects match up.
                    </p>
                </div>
                <a className='download-btn wpl-button' href='DEFECT_example-importable-excel.xlsx' download>Download template</a>
            </div>
        </div>

        <h2>Upload Defects</h2>
        <p>Add defects to your wind-diagnostics</p>
        <div className='select-or-new'>
            <WplInput title='Windfarm' value={windfarm}
                      options={Object.keys(allWindfarms)} onlySelectableOptions={true}
                      onChanged={v => {
                          setWindfarm(v);
                          setInspectionDate('');
                          setTurbine('');
                          setParsedDefects([]);
                          setUploadedImages({});
                      }} clearable={true}/>
            <WplButton value='Create new windfarm' onClick={_ => {
                setCreateWindfarmOpen(true)
            }}/>
        </div>

        {renderInspectionAvaiableDates()}
        <div className='select-or-new'>
            <WplInput title='Turbine' value={turbine}
                      options={windfarm ? allWindfarms[windfarm].turbines.filter(t => t.inspection_date === inspectionDate).map(t => t.name) : []}
                      onlySelectableOptions={true} disabled={!windfarm || !inspectionDate}
                      onChanged={t => {
                          setTurbine(t);
                          setParsedDefects([]);
                          setUploadedImages({});
                      }}
                      clearable={true}
            />
            <div>
                <WplButton value='Create new turbine' onClick={_ => {
                    setCreateTurbineOpen(true)
                }} disabled={!windfarm || !inspectionDate}/>
            </div>
        </div>
        {turbine && <div className='split-wrap'>
            <div className='split'>
                <h1>Defects</h1>
                <WplFileUpload
                    asPopup={false}
                    displayFiles={false}
                    didSelectFile={uploadedExcelFile}
                />

            </div>
            <div className='split'>
                <h1>Images</h1>
                <WplFileUpload asPopup={false} displayFiles={false} didSelectFile={uploadedImageFile}/>
            </div>
        </div>}

        {turbine && Object.keys(parsedDefects).length > 0 && <div className='display-result'>
            <SimpleTable rows={parsedDefects}
                         headers={['Report Defect ID', 'Blade', 'Surface', 'Type', 'Layer', 'Distance', 'Severity', 'Filename']}
                         className='parsed-defect-table'
                         renderRow={r => {
                             const error = r.parse_errors
                             return (<tr key={`${r.blade}-${r.report_defect_id}`}>
                                 {['report_defect_id', 'blade', 'surface', 'type', 'layer', 'distance', 'severity'].map(name => {
                                     return (<td>
                                         <div className='td-wrapper'>
                                             <span>{r[name]}</span>
                                             {error && error[name] &&
                                             <div className='error-popup'>
                                                 <img alt='error' src={exclamation_mark}/>
                                                 <span>{error[name]}</span></div>}
                                         </div>
                                     </td>)
                                 })}
                                 <td>
                                     <div className='td-wrapper'>
                                         <span>{r.small_path}</span>
                                         {uploadedImages[r.small_path] ?
                                             <img className='status-bg' src={check} alt='status'/> :
                                             <div className='error-popup'>
                                                 <img alt='error' src={exclamation_mark}/>
                                                 <span>Missing image</span>
                                             </div>}
                                     </div>
                                 </td>
                                 {createdDefectIds.indexOf(r.report_defect_id) === -1 ? <td></td> : <td>LIVE</td>}
                                 {existingDefects.some(ed => ed.reportDefectId === r.report_defect_id.toString() && ed.blade === r.blade.toString()) ? <td>LIVE</td> : <td></td>}
                             </tr>)
                         }}/>
            <div style={{height: '45px'}}/>
            {hasErrors && <WplInput
                title="Override errors (I know what I'm doing)"
                type='checkbox'
                value={overrideErrors}
                onChanged={setOverrideErrors}
            />}
            <WplButton
                disabled={hasErrors && !overrideErrors}
                value='Create Defects'
                className='ss-button'
                loading={creationLoading}
                onClick={createDefects}/>
        </div>}
    </div>)
}
